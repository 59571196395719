import { TextField } from '@mui/material';

export const AEResumeNameField: React.FC<{
  name: string;
  value: string | null;
  disabled: boolean;
  touched: boolean;
  error: string | null | undefined;
  onChange: (value: string | null) => void;
  onBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}> = ({ name, value, touched, error, disabled, onChange, onBlur }) => {
  return (
    <TextField
      name={name}
      label="Name"
      variant="outlined"
      fullWidth
      value={value || ''}
      required
      onChange={(e) => onChange(e.target.value || null)}
      onBlur={onBlur}
      helperText={touched ? error : undefined}
      error={touched && !!error}
      disabled={disabled}
    />
  );
};
