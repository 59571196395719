import { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { CustomButton } from '@components';
import React from 'react';

export const SectionLayout: React.FC<{
  title: string;
  items: ReactNode[];
  addLabel: string;
  onAddClick: () => void;
}> = ({ title, items, addLabel, onAddClick }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="h4">{title}</Typography>
      {items.length ? (
        <Box display="flex" flexDirection="column" gap={1}>
          {items.map((item, idx) => (
            <Box
              sx={(theme) => ({
                padding: '24px',
                background: theme.palette.highlight.neutral,
                borderRadius: '8px',
              })}
              key={idx}
            >
              <React.Fragment>{item}</React.Fragment>
            </Box>
          ))}
        </Box>
      ) : null}
      <CustomButton
        color="secondary"
        label={addLabel}
        onClick={onAddClick}
        size="small"
        startIcon={<PlaylistAddIcon />}
      />
    </Box>
  );
};
