import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { SyntheticEvent } from 'react';
import { isTruthy } from '@utils';

export const CandidateSkillsFilter: React.FC<{
  isLoading: boolean;
  value: string[] | null;
  onChange: (value: string[] | null) => void;
}> = ({ isLoading, value, onChange }) => {
  return (
    <Autocomplete
      disablePortal
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = createFilterOptions<string>()(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          filtered.push(`Add "${inputValue}"`);
        }

        return filtered;
      }}
      onChange={(e: SyntheticEvent, newValue: string[] | null) => {
        const newSkillNameValue = newValue
          ?.map((value) =>
            value?.includes('Add "') ? /Add "(.*)"/gi.exec(value)?.[1] : value,
          )
          .filter(isTruthy);

        onChange(newSkillNameValue?.length ? newSkillNameValue : null);
      }}
      multiple
      options={[]}
      freeSolo
      sx={{ mb: '1rem', width: '300px' }}
      renderInput={(params) => <TextField {...params} label="Skills" />}
      disabled={isLoading}
      ChipProps={{
        size: 'small',
      }}
    />
  );
};
