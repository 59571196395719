import { Box, Chip, Tooltip } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import DomainIcon from '@mui/icons-material/Domain';
import BuildIcon from '@mui/icons-material/Build';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import {
  JobOpeningCrucialRequirementCategory,
  JobOpeningCrucialRequirementPriority,
  JobOpeningCrucialRequirementPriorityLabel,
} from '@constants';

const IconByCategory: Record<
  JobOpeningCrucialRequirementCategory,
  JSX.Element
> = {
  [JobOpeningCrucialRequirementCategory.Education]: <SchoolIcon />,
  [JobOpeningCrucialRequirementCategory.DomainKnowledge]: <DomainIcon />,
  [JobOpeningCrucialRequirementCategory.Technology]: <BuildIcon />,
  [JobOpeningCrucialRequirementCategory.Visa]: <CardTravelIcon />,
};

export const CrucialRequirementChip: React.FC<{
  variant?: 'categoryIcon' | 'stateIcon';
  name: string;
  category: JobOpeningCrucialRequirementCategory;
  priority?: JobOpeningCrucialRequirementPriority;
  selected?: boolean;
  onDelete?: () => void;
}> = ({
  variant = 'categoryIcon',
  name,
  category,
  priority,
  selected,
  onDelete,
}) => {
  const isMustHave = priority === JobOpeningCrucialRequirementPriority.MustHave;

  return (
    <Tooltip
      title={
        priority ? JobOpeningCrucialRequirementPriorityLabel[priority] : name
      }
    >
      <Chip
        label={
          <>
            {name}{' '}
            {isMustHave && (
              <Box
                component="span"
                sx={(theme) => ({
                  color: theme.palette.highlight.purple,
                })}
              >
                *
              </Box>
            )}
          </>
        }
        icon={
          variant === 'categoryIcon' ? (
            IconByCategory[category]
          ) : selected ? (
            <CheckCircleIcon
              sx={(theme) => ({
                fill: theme.palette.highlight.purple,
              })}
            />
          ) : (
            <RemoveCircleOutlineIcon />
          )
        }
        onDelete={onDelete}
        size="small"
        sx={(theme) => ({
          background: isMustHave
            ? theme.palette.highlight.lightPurple
            : theme.palette.highlight.lowSeverity,
        })}
      />
    </Tooltip>
  );
};
