import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import {
  NotFoundPage,
  SignInPage,
  JobOpeningsDashboardPage,
  JobOpeningDetailsPage,
  CandidateDetailsPage,
  CreateJobOpeningPage,
  EditJobOpeningPage,
  CandidatesDashboardPage,
  SubmissionFormPage,
  ActionReportDashboardPage,
  EntityReportsDashboardPage,
  EntityReportFormPage,
  EntityReportDataPage,
} from '@pages';
import { SideDrawerLayout } from '@components';
import { LayoutWrapper } from './LayoutWrapper';

import {
  AppRoutes,
  CandidateStage,
  CandidateTypeOfOutboundChannel,
  JobOpeningStatusFilter,
} from '@constants';

export const AppRouter: FC = () => (
  <Routes>
    <Route path={`/`}>
      <Route element={<LayoutWrapper layout={SideDrawerLayout} />}>
        <Route index element={<Navigate to={AppRoutes.JOB_OPENINGS} />} />

        <Route
          path={AppRoutes.JOB_OPENINGS}
          element={
            <JobOpeningsDashboardPage
              status={JobOpeningStatusFilter.Open}
              key={AppRoutes.JOB_OPENINGS}
            />
          }
        />
        <Route
          path={AppRoutes.ON_HOLD_JOB_OPENINGS}
          element={
            <JobOpeningsDashboardPage
              status={JobOpeningStatusFilter.OnHold}
              key={AppRoutes.ON_HOLD_JOB_OPENINGS}
            />
          }
        />

        <Route
          path={`${AppRoutes.ACTION_REPORT}`}
          element={<ActionReportDashboardPage />}
        />

        <Route
          path={`${AppRoutes.NEW_HOME_CANDIDATES}`}
          element={
            <CandidatesDashboardPage
              stage={CandidateStage.NEW_HOME}
              key={AppRoutes.NEW_HOME_CANDIDATES}
            />
          }
        />
        <Route
          path={`${AppRoutes.SELFGEN_CANDIDATES}`}
          element={
            <CandidatesDashboardPage
              typeOfOutboundChannel={CandidateTypeOfOutboundChannel.Selfgen}
              key={AppRoutes.SELFGEN_CANDIDATES}
            />
          }
        />
        <Route
          path={`${AppRoutes.APPLIED_CANDIDATES}`}
          element={
            <CandidatesDashboardPage
              stage={CandidateStage.APPLIED}
              key={AppRoutes.APPLIED_CANDIDATES}
            />
          }
        />
        <Route
          path={`${AppRoutes.TT_PASSED_CANDIDATES}`}
          element={
            <CandidatesDashboardPage
              stage={CandidateStage.TT_PASSED}
              key={AppRoutes.TT_PASSED_CANDIDATES}
            />
          }
        />
        <Route
          path={`${AppRoutes.VI_DONE_CANDIDATES}`}
          element={
            <CandidatesDashboardPage
              stage={CandidateStage.VI_DONE}
              key={AppRoutes.VI_DONE_CANDIDATES}
            />
          }
        />
        <Route
          path={`${AppRoutes.VI_PASSED_CANDIDATES}`}
          element={
            <CandidatesDashboardPage
              stage={CandidateStage.VI_PASSED}
              key={AppRoutes.VI_PASSED_CANDIDATES}
            />
          }
        />
        <Route
          path={`${AppRoutes.TI_PASSED_CANDIDATES}`}
          element={
            <CandidatesDashboardPage
              stage={CandidateStage.TI_PASSED}
              key={AppRoutes.TI_PASSED_CANDIDATES}
            />
          }
        />
        <Route
          path={`${AppRoutes.SUBMITTED_CANDIDATES}`}
          element={
            <CandidatesDashboardPage
              stage={CandidateStage.SUBMITTED}
              key={AppRoutes.SUBMITTED_CANDIDATES}
            />
          }
        />
        <Route
          path={`${AppRoutes.CANDIDATES}`}
          element={<CandidatesDashboardPage key="allCandidate" />}
        />

        <Route
          path={AppRoutes.ENTITY_REPORTS}
          element={
            <EntityReportsDashboardPage key={AppRoutes.ENTITY_REPORTS} />
          }
        />
        <Route
          path={AppRoutes.CREATE_ENTITY_REPORT}
          element={<EntityReportFormPage />}
        />
        <Route
          path={`${AppRoutes.ENTITY_REPORTS}/:entityReportId`}
          element={<EntityReportDataPage />}
        />
        <Route
          path={`${AppRoutes.ENTITY_REPORTS}/:entityReportId/edit`}
          element={<EntityReportFormPage />}
        />

        <Route
          path={AppRoutes.CREATE_JOB_OPENING}
          element={<CreateJobOpeningPage />}
        />
        <Route
          path={`${AppRoutes.JOB_OPENINGS}/:jobOpeningId/edit`}
          element={<EditJobOpeningPage />}
        />

        <Route
          path={`${AppRoutes.JOB_OPENINGS}/:jobOpeningId`}
          element={<JobOpeningDetailsPage />}
        />

        <Route
          path={`${AppRoutes.CANDIDATES}/:candidateId`}
          element={<CandidateDetailsPage />}
        />
        <Route
          path={`${AppRoutes.CANDIDATES}/:candidateId/:selectedTabName`}
          element={<CandidateDetailsPage />}
        />

        <Route path={AppRoutes.SUBMISSIONS} element={<SubmissionFormPage />} />
        <Route
          path={`${AppRoutes.SUBMISSIONS}/:submissionId`}
          element={<SubmissionFormPage />}
        />
      </Route>

      <Route path={AppRoutes.SIGN_IN} element={<SignInPage />} />
    </Route>
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
