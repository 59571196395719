import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { Button, List, ListItem, Typography } from '@mui/material';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';

import { AutocompleteTextfield, Flex } from '@components';
import { VideoInterviewSendConfirmationModal } from './VideoInterviewSendConfirmationModal';

import {
  candidateDetailsSelectors,
  postCandidateSendVideoInterview,
} from '@redux/candidateDetails';

import { isStatusLoading } from '@utils';
import {
  CandidateVideoInterview,
  IJobOpeningVideoInterview,
  VideoInterviewQuestion,
} from '@types';

export const VideoInterviewSendForm: React.FC<{
  availableVideoInterview: IJobOpeningVideoInterview[];
  currentVideoInterview: CandidateVideoInterview | null;
}> = ({ availableVideoInterview, currentVideoInterview }) => {
  const dispatch = useAppDispatch();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const sendVideoInterviewAPIStatus = useAppSelector(
    candidateDetailsSelectors.getSendVideoInterviewAPIStatus,
  );

  const [videoInterview, setVideoInterview] = useState(null);
  const [showVIQuestions, setShowVIQuestions] = useState(true);
  const [videoInterviewSendModal, setVideoInterviewSendModal] = useState(false);
  const [videoInterviewEmailInviteSend, setVideoInterviewEmailInviteSend] =
    useState(true);

  const selectedVideoInterview = useMemo(() => {
    return availableVideoInterview.find((vi) => vi.id === videoInterview);
  }, [availableVideoInterview, videoInterview]);

  const handleOpenVideoInterviewSendModal = useCallback(
    () => setVideoInterviewSendModal(true),
    [],
  );

  const handleCloseVideoInterviewSendModal = useCallback(
    () => setVideoInterviewSendModal(false),
    [],
  );

  const handleVideoInterviewEmailInvite = useCallback(
    () => setVideoInterviewEmailInviteSend(!videoInterviewEmailInviteSend),
    [videoInterviewEmailInviteSend],
  );

  const sendVideoInterview = useCallback(() => {
    if (candidateId && selectedVideoInterview) {
      dispatch(
        postCandidateSendVideoInterview({
          candidateId,
          videoInterviewId: selectedVideoInterview?.id,
          videoInterviewEmailInviteSend,
        }),
      );
    }
  }, [candidateId, selectedVideoInterview, videoInterviewEmailInviteSend]);

  const viOptions = useMemo(
    () => availableVideoInterview.map((e) => e.id),
    [availableVideoInterview],
  );

  return (
    <>
      <Flex flexDirection="column" gap={1}>
        <AutocompleteTextfield
          label="VI questions"
          options={viOptions}
          getOptionLabel={(option) => {
            const vi = availableVideoInterview.find((vi) => vi.id === option);

            return currentVideoInterview?.videoInterview
              ? `${currentVideoInterview?.videoInterview?.specialization}${
                  currentVideoInterview?.videoInterview?.locations?.length
                    ? ` (${currentVideoInterview?.videoInterview.locations.join(
                        ', ',
                      )})`
                    : ''
                }${
                  currentVideoInterview?.videoInterview?.isCustom
                    ? '(Custom)'
                    : ''
                }`
              : `${vi?.positionId}: ${vi?.positionName} - ${
                  vi?.specialization
                }${
                  vi?.locations?.length ? ` (${vi.locations.join(', ')})` : ''
                }${vi?.isCustom ? '(Custom)' : ''}`;
          }}
          value={
            currentVideoInterview
              ? currentVideoInterview.videoInterview?.id
              : videoInterview
          }
          onChange={(_, val) => setVideoInterview(val || null)}
          fullWidth
          disabled={!!currentVideoInterview}
        />
        {!currentVideoInterview && (
          <Flex justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              startIcon={<VideoCallIcon />}
              onClick={handleOpenVideoInterviewSendModal}
              disabled={!videoInterview}
            >
              Send invitation
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={
                showVIQuestions ? (
                  <PhotoSizeSelectSmallOutlinedIcon fontSize="small" />
                ) : (
                  <FormatListBulletedIcon />
                )
              }
              onClick={() => setShowVIQuestions(!showVIQuestions)}
              disabled={!videoInterview}
            >
              {showVIQuestions ? 'Hide' : 'View'} questions
            </Button>
          </Flex>
        )}
        {!currentVideoInterview && showVIQuestions && (
          <List
            sx={{
              padding: 0,
              listStyle: 'none',
              width: '100%',
            }}
          >
            {selectedVideoInterview?.questions.map(
              (question: VideoInterviewQuestion) => {
                return (
                  <ListItem
                    key={question.questionNumber}
                    sx={(theme) => ({
                      padding: '0.5rem 1rem 1rem 1rem !important',
                      marginBottom: '0.25rem !important',
                      borderRadius: '0.25rem',
                      background: theme.palette.highlight.actionable,
                    })}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Question {question.questionNumber}
                    </Typography>
                    <Typography variant="body1">{question.title}</Typography>
                  </ListItem>
                );
              },
            )}
          </List>
        )}
      </Flex>
      <VideoInterviewSendConfirmationModal
        isLoading={isStatusLoading(sendVideoInterviewAPIStatus)}
        isOpen={videoInterviewSendModal}
        isVideoInterviewEmailInvite={videoInterviewEmailInviteSend}
        handleClose={handleCloseVideoInterviewSendModal}
        handleSendVI={sendVideoInterview}
        handleVideoInterviewEmailInvite={handleVideoInterviewEmailInvite}
      />
    </>
  );
};
