import {
  format,
  differenceInDays,
  parse,
  addDays,
  differenceInCalendarDays,
} from 'date-fns';
import dayjs from 'dayjs';
import { convertOffsetToHours } from './timezone';

export const formatDate = (string?: string | null) => {
  if (!string) return;

  return format(new Date(string), 'yyyy-MM-dd');
};

export const formatDateWithTimezone = (string?: string | null) => {
  if (!string) return '';

  return format(new Date(string), 'MMM d, kk:mm xxx');
};

export const getDifferenceInDays = (date: string | null): number => {
  if (!date) return 0;

  return differenceInDays(new Date(), new Date(date));
};

export const formatDateWithTime = (str: string) =>
  format(new Date(str), 'MM/dd/yyyy hh:mm aaa');

export const displayDateTimeInTimezone = (
  date: string | null,
  timezone: string,
) => {
  return dayjs
    .utc(date)
    .add(Number(convertOffsetToHours(timezone ?? '+00:00')), 'hour');
};

export const displayDateTimeInUTC = (date: string | null, timezone: string) => {
  return dayjs
    .utc(date)
    .add(-Number(convertOffsetToHours(timezone ?? '+00:00')), 'hour');
};

/**
 * Checks if date1 is after or equal to date2.
 *
 * @param date1 - A string representing the first date. It can be undefined.
 * @param date2 - A Date object representing the second date.
 * @returns true if date1 is after or equal to date2, false otherwise.
 */
export const checkDateIsAfter = (
  date1: string | undefined,
  date2: Date,
): boolean => {
  if (!date1) return false;

  return new Date(date1) >= new Date(date2);
};

/**
 * Formats a date string and optionally adds a specified number of days.
 *
 * @param {string} dateString - Input date in 'yyyy-MM-dd' format.
 * @param {number} [daysToAdd] - Optional number of days to add to the parsed date.
 * @returns {string} - The formatted date in 'dd MMM, yyyy' format.
 */
export const formatAndAddDaysToDate = (
  dateString: string,
  daysToAdd?: number,
): string => {
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
  const formattedDate = format(parsedDate, 'dd MMM, yyyy');

  if (daysToAdd) {
    return format(addDays(parsedDate, daysToAdd), 'dd MMM, yyyy');
  }

  return formattedDate;
};

/**
 * Calculates the number of days between a start date and an optional end date, with an option to adjust the start date.
 *
 * @param {string} startDate - The start date in 'yyyy-MM-dd' format.
 * @param {number} [addDaysCount=30] - The number of days to add to the start date (default is 30 days).
 * @param {string | Date} [endDate=new Date()] - The end date, either as a string in 'yyyy-MM-dd' format or a Date object (default is the current date).
 * @returns {number} - The number of days between the adjusted start date and the end date.
 */
export const getDaysBetweenDates = (
  startDate: string,
  addDaysCount = 30,
  endDate: string | Date = new Date(),
): number => {
  const parsedStartDate = parse(startDate, 'yyyy-MM-dd', new Date());
  const adjustedStartDate = addDays(parsedStartDate, addDaysCount);
  const parsedEndDate =
    typeof endDate === 'string'
      ? parse(endDate, 'yyyy-MM-dd', new Date())
      : endDate;

  const daysLeft = differenceInCalendarDays(adjustedStartDate, parsedEndDate);
  return daysLeft;
};
