import { isNull } from 'lodash';
import { ScoreTypes, ICandidateScore } from '@types';

const getPositionMaxScoreByType = (): number => {
  return 10;
};

const getAEMaxScoreByType = (scoreType: ScoreTypes): number => {
  if (
    scoreType === ScoreTypes.Location ||
    scoreType === ScoreTypes.Seniority ||
    scoreType === ScoreTypes.English
  ) {
    return 3;
  }

  return 10;
};

export const getScoreColor = (
  score: number | null,
  scoreType: ScoreTypes,
  positionVariant: boolean,
): string => {
  if (isNull(score)) {
    return 'background.disabled';
  }

  const maxScore = positionVariant
    ? getPositionMaxScoreByType()
    : getAEMaxScoreByType(scoreType);
  const percentage = (score * 100) / maxScore;

  if (
    scoreType === ScoreTypes.Location ||
    scoreType === ScoreTypes.Seniority ||
    scoreType === ScoreTypes.English
  ) {
    return percentage > 66 // eslint-disable-line prettier/prettier
      ? 'highlight.scoreGreen'
      : percentage > 33
      ? 'highlight.scoreYellow'
      : 'highlight.scoreBlack';
  }

  if (scoreType === ScoreTypes.Skills) {
    return percentage >= 80 // eslint-disable-line prettier/prettier
      ? 'highlight.scoreGreen'
      : percentage >= 65
      ? 'highlight.scoreYellow'
      : 'highlight.scoreBlack';
  }

  if (scoreType === ScoreTypes.VideoInterview) {
    return percentage >= 80 // eslint-disable-line prettier/prettier
      ? 'highlight.scoreGreen'
      : percentage >= 50
      ? 'highlight.scoreYellow'
      : percentage > 0
      ? 'text.danger'
      : 'highlight.scoreBlack';
  }

  if (scoreType === ScoreTypes.CrucialRequirements) {
    return percentage >= 50 // eslint-disable-line prettier/prettier
      ? 'highlight.scoreGreen'
      : percentage > 0
      ? 'highlight.scoreYellow'
      : 'highlight.scoreBlack';
  }

  return 'highlight.scoreBlack';
};

export const getScoreTextColor = (score: number | null): string => {
  if (isNull(score)) {
    return 'text.disabled';
  }

  return 'text.invertedPrimary';
};

export const getTotalScoreColor = (score: ICandidateScore): string => {
  if (isScoreIncomplete(score)) return 'text.disabled';

  return 'text.invertedPrimary';
};

export const getTotalScoreBgColor = (
  score: ICandidateScore,
  maxScore: number,
): string => {
  if (isScoreIncomplete(score) || isNull(score.total))
    return 'background.disabled';
  if (isCandidateDisqualified(score)) return 'highlight.scoreBlack';

  const percentage = (score.total * 100) / maxScore;

  return percentage >= 60
    ? 'highlight.scoreGreen'
    : percentage >= 20
    ? 'highlight.scoreYellow'
    : 'text.danger';
};

export const getScoreHeaderText = (score: ICandidateScore): string | null => {
  if (isScoreIncomplete(score)) return 'Incomplete score';
  if (isCandidateDisqualified(score)) {
    return `Disqualified for ${getCandidateDisqualifyingReason(
      score,
    )} despite the results`;
  }
  if (!score.list.length) return 'No categories for assessment';

  return null;
};

const getCandidateDisqualifyingReason = (score: ICandidateScore): string => {
  const isDisqualifiedByEnglish = score.list.some((score) => {
    return score.type === ScoreTypes.English && score.points === 0;
  });
  if (isDisqualifiedByEnglish) return 'English';

  const isDisqualifiedBySkills = score.list.some(
    (score) =>
      score.type === ScoreTypes.Skills &&
      !isNull(score.points) &&
      score.points < 6.5,
  );
  if (isDisqualifiedBySkills) {
    return 'Skills';
  }

  const isDisqualifiedByCrucialRequirements = score.list.some((score) => {
    return score.type === ScoreTypes.CrucialRequirements && score.points === 0;
  });
  if (isDisqualifiedByCrucialRequirements) return 'Crucial requirements';

  const isDisqualifiedByVI = score.list.some((score) => {
    return score.type === ScoreTypes.VideoInterview && score.points === 0;
  });
  if (isDisqualifiedByVI) return 'Video interview';

  return '';
};

export const isCandidateDisqualified = (score: ICandidateScore): boolean => {
  return Boolean(getCandidateDisqualifyingReason(score));
};

export const isScoreIncomplete = (score: ICandidateScore): boolean => {
  return score.list.some((score) => isNull(score.points));
};
