import { useCallback } from 'react';
import dayjs from 'dayjs';

import { SectionLayout } from '../SectionLayout';
import { Box, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { AEResumeFormFields, IAEResumeEducation } from '@types';

export const AEResumeEducationSection: React.FC<{
  value: AEResumeFormFields['educations'];
  disabled: boolean;
  touched: any | undefined;
  errors: any | undefined;
  isSubmitted: boolean;
  onChange: (value: AEResumeFormFields['educations']) => void;
  onTouchField: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean,
  ) => void;
}> = ({
  value,
  disabled,
  touched,
  errors,
  isSubmitted,
  onChange,
  onTouchField,
}) => {
  const onAddClick = useCallback(
    () =>
      onChange([
        ...(value || []),
        { from: null, to: null, degree: null, institution: null },
      ]),
    [value],
  );

  const onDeleteClick = useCallback(
    (removeIdx: number) =>
      onChange(value?.filter((_, idx) => idx !== removeIdx) || null),
    [value],
  );

  const onEditField = useCallback(
    (field: keyof IAEResumeEducation, editIdx: number, editedValue: string) =>
      onChange(
        value?.map((e, idx) =>
          idx === editIdx ? { ...e, [field]: editedValue || null } : e,
        ) || null,
      ),
    [value],
  );

  return (
    <SectionLayout
      title="Education"
      items={
        value?.map((item, idx) => {
          const institutionError =
            (touched?.[idx]?.institution || isSubmitted) &&
            errors?.[idx]?.institution
              ? errors[idx]?.institution
              : null;
          const fromDateError =
            (touched?.[idx]?.from || isSubmitted) && errors?.[idx]?.from
              ? errors[idx]?.from
              : null;
          const toDateError =
            (touched?.[idx]?.to || isSubmitted) && errors?.[idx]?.to
              ? errors[idx]?.to
              : null;
          const degreeError =
            (touched?.[idx]?.degree || isSubmitted) && errors?.[idx]?.degree
              ? errors[idx]?.degree
              : null;

          return (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" gap={1} alignItems="start">
                <TextField
                  label="Institution"
                  variant="outlined"
                  fullWidth
                  value={item.institution || ''}
                  required
                  onChange={(e) =>
                    onEditField('institution', idx, e.target.value)
                  }
                  onBlur={() => onTouchField(`educations[${idx}].institution`)}
                  disabled={disabled}
                  helperText={institutionError || undefined}
                  error={!!institutionError}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#FFF',
                    },
                  }}
                />
                <DatePicker
                  label="From"
                  openTo="year"
                  views={['year']}
                  value={item.from ? dayjs(item.from) : null}
                  onChange={(newValue) => {
                    onEditField('from', idx, newValue?.format('YYYY') || '');
                  }}
                  disabled={disabled}
                  slotProps={{
                    textField: {
                      required: true,
                      helperText: fromDateError,
                      error: !!fromDateError,
                      sx: {
                        '& .MuiInputBase-root': {
                          backgroundColor: '#FFF',
                        },
                      },
                    },
                  }}
                />
                <DatePicker
                  label="To"
                  openTo="year"
                  views={['year']}
                  value={item.to ? dayjs(item.to) : null}
                  onChange={(newValue) => {
                    onEditField('to', idx, newValue?.format('YYYY') || '');
                  }}
                  disabled={disabled}
                  slotProps={{
                    textField: {
                      helperText: toDateError,
                      error: !!toDateError,
                      sx: {
                        '& .MuiInputBase-root': {
                          backgroundColor: '#FFF',
                        },
                      },
                    },
                  }}
                />
                <IconButton
                  onClick={() => onDeleteClick(idx)}
                  sx={{ marginTop: '8px' }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <TextField
                  label="Name of program / career / degree"
                  variant="outlined"
                  fullWidth
                  value={item.degree || ''}
                  required
                  onChange={(e) => onEditField('degree', idx, e.target.value)}
                  onBlur={() => onTouchField(`educations[${idx}].degree`)}
                  disabled={disabled}
                  helperText={degreeError || undefined}
                  error={!!degreeError}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#FFF',
                    },
                  }}
                />
              </Box>
            </Box>
          );
        }) || []
      }
      addLabel="Add education"
      onAddClick={onAddClick}
    />
  );
};
