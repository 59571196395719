import { ScheduleEngineeringTIButton } from '@components/candidateDetails/ScheduleEngineeringTIButton/ScheduleEngineeringTIButton';
import { Box, TextField } from '@mui/material';

export const TILinkField: React.FC<{
  name?: string;
  label: string;
  value: string | null;
  required?: boolean;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
  candidateId?: string | null;
  onChange: (value: string | null) => void;
  onBlur?: () => void;
}> = ({
  name,
  label,
  value,
  required,
  touched,
  error,
  disabled,
  candidateId,
  onChange,
  onBlur,
}) => {
  return (
    <Box width="100%" display="flex" gap={1} alignItems="start">
      <TextField
        name={name || 'tiLink'}
        label={label}
        variant="outlined"
        fullWidth
        value={value || ''}
        required={required}
        onChange={(e) => onChange(e.target.value || null)}
        onBlur={onBlur}
        helperText={touched ? error : undefined}
        error={touched && !!error}
        disabled={disabled}
      />
      <Box mt="12px">
        <ScheduleEngineeringTIButton zohoId={candidateId} />
      </Box>
    </Box>
  );
};
