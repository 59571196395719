import { notesStoreKey } from './notes.const';
import { INotesSliceState } from './notes.types';

interface IState {
  [notesStoreKey]: INotesSliceState;
}

const selectNotesState = (state: IState): INotesSliceState => {
  return state[notesStoreKey];
};

const getNoteIsPerformingAction = (state: IState) => {
  return selectNotesState(state).isPerformingAction;
};

export const notesSelectors = {
  getNoteIsPerformingAction,
};
