import React, { useEffect, useMemo } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { IconButton } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { AutocompleteTextfield, CustomButton, Flex } from '@components';

import { fetchAvailableTestTasks, optionsSelectors } from '@redux/options';

import { isStatusIdle } from '@utils';
import { JOTestTaskForm } from '@types';

export const PositionTestTaskField: React.FC<{
  value: JOTestTaskForm[];
  name?: string;
  required?: boolean;
  touched?: FormikTouched<JOTestTaskForm[]>;
  error?: FormikErrors<JOTestTaskForm[]>;
  onChange: (newValue: JOTestTaskForm[]) => void;
  onBlur?: (name?: string) => void;
}> = ({ value, name, required, touched, error, onChange, onBlur }) => {
  const dispatch = useAppDispatch();

  const { apiStatus, data } = useAppSelector(
    optionsSelectors.getAvailableTTsAPIData,
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchAvailableTestTasks());
    }
  }, [apiStatus]);

  const handleAddTestTask = () => {
    onChange([
      ...value,
      {
        technicalFlow: null,
        type: null,
      },
    ]);
  };

  const handleRemoveTestTask = (removeIndex: number) => {
    onChange(value.filter((_, idx) => idx !== removeIndex));
  };

  const handleTechnicalFlowChange = (
    changeIndex: number,
    testTask: JOTestTaskForm,
  ) => {
    onChange(value.map((e, idx) => (idx === changeIndex ? testTask : e)));
  };

  const options = useMemo(
    () =>
      data
        .map((tt) => ({
          technicalFlow: tt.technicalFlow,
          type: tt.type,
        }))
        .filter(
          (tt) =>
            !value.some(
              (selectedTT) =>
                selectedTT.technicalFlow === tt.technicalFlow &&
                selectedTT.type === tt.type,
            ),
        ),
    [data, value],
  );

  return (
    <Flex flexDirection="column" gap={1} alignItems="start">
      {value.map((testTask, idx) => {
        const isTouched = touched?.[idx];
        const fieldError = error?.[idx]?.technicalFlow;

        return (
          <Flex gap={1} key={`${testTask.technicalFlow}-${testTask.type}`}>
            <AutocompleteTextfield
              name={name}
              label="Test task technical flow"
              options={options}
              value={testTask.technicalFlow ? testTask : null}
              onChange={(_, val: JOTestTaskForm) => {
                handleTechnicalFlowChange(idx, val);
              }}
              disableClearable
              onBlur={() => onBlur?.(`${name}[${idx}].technicalFlow`)}
              getOptionLabel={(option: JOTestTaskForm) => option.technicalFlow!}
              groupBy={(option: JOTestTaskForm) => option.type!}
              required={required}
              error={isTouched && !!fieldError}
              helperText={isTouched ? fieldError : undefined}
              fullWidth
            />
            {value.length > 1 && (
              <IconButton onClick={() => handleRemoveTestTask(idx)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            )}
          </Flex>
        );
      })}
      <CustomButton
        size="small"
        color="secondary"
        label="Add another TT"
        startIcon={<AddTaskIcon fontSize="small" />}
        disabled={value.some((e) => !e.technicalFlow)}
        onClick={() => handleAddTestTask()}
      />
    </Flex>
  );
};
