import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { Dialog, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';
import { CandidateStatusForm } from './CandidateStatusForm/CandidateStatusForm';

import {
  candidateDetailsSelectors,
  fetchCandidateJobOpenings,
  setCandidateStatusChangeModalProps,
} from '@redux/candidateDetails';
import { isStatusIdle } from '@utils';

export const CandidateStatusChangeModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const props = useAppSelector(
    candidateDetailsSelectors.getCandidateStatusChangeModalProps,
  );
  const candidate = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const suitableJOAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateSuitablePositionsApiStatus,
  );
  const suitableJOs = useAppSelector(
    candidateDetailsSelectors.getCandidateSuitablePositionsData,
  );

  useEffect(() => {
    if (candidate?.id && isStatusIdle(suitableJOAPIStatus)) {
      dispatch(fetchCandidateJobOpenings(candidate.id));
    }
  }, [candidate?.id, suitableJOAPIStatus]);

  const associatedJopOpenings = useMemo(
    () => suitableJOs.filter((e) => e.Is_Associated),
    [suitableJOs],
  );

  const { selectedJobOpeningId, isOpen, preselectedStage, preselectedStatus } =
    props || {
      isOpen: false,
      selectedJobOpeningId: null,
      preselectedStage: null,
      preselectedStatus: null,
    };

  const onModalClose = useCallback(() => {
    dispatch(setCandidateStatusChangeModalProps(null));
  }, []);

  if (!candidate) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onModalClose}
      scroll="body"
      fullWidth
      sx={{ '.MuiDialog-paper': { maxWidth: '1024px' } }}
    >
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h1">Change status</Typography>
          <CloseIcon
            onClick={onModalClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>

      <CandidateStatusForm
        candidate={candidate}
        selectedJobOpeningId={selectedJobOpeningId}
        associatedJopOpenings={associatedJopOpenings}
        preselectedStage={preselectedStage}
        preselectedStatus={preselectedStatus}
        onCancel={onModalClose}
      />
    </Dialog>
  );
};
