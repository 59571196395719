import React from 'react';

import { SnackbarType } from '@constants';
import { IconButton, styled, Tooltip } from '@mui/material';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

import { useAppDispatch } from '@redux/hooks';
import { setSnackbar } from '@redux/snackbar';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  ':hover': {
    color: theme.palette.text.link,
    background: 'none',
  },
}));

interface ICopyWithTooltipProps {
  tooltip: string;
  copyString: string;
}

export const CopyWithTooltip: React.FC<ICopyWithTooltipProps> = ({
  tooltip,
  copyString,
}: ICopyWithTooltipProps) => {
  const dispatch = useAppDispatch();

  const handleCopyLink = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(
      setSnackbar({
        type: SnackbarType.Info,
        message: 'Copied to clipboard!',
      }),
    );
  };

  return (
    <Tooltip title={tooltip}>
      <div>
        <StyledIconButton onClick={() => handleCopyLink(copyString)}>
          <MailOutlineRoundedIcon sx={{ fontSize: '12px' }} />
        </StyledIconButton>
      </div>
    </Tooltip>
  );
};
