import { Checkbox, Tooltip, Typography } from '@mui/material';
import { Flex } from '@components';

import { getPriorityIcon } from '@utils';
import { IDWHJobOpening } from '@types';
import { JobOpeningReasonOfClosing, JobOpeningStatus } from '@constants';

export const JobOpeningsTable: React.FC<{
  jobOpenings: IDWHJobOpening[];
  selectedJobOpenings: string[];
  onChangeSelectedJobOpenings: (jobOpeningIds: string[]) => void;
  isLoading: boolean;
}> = ({
  jobOpenings,
  selectedJobOpenings,
  onChangeSelectedJobOpenings,
  isLoading,
}) => {
  return (
    <Flex flexDirection="column">
      {jobOpenings.map((position) => {
        const isOnHold =
          position.Job_Opening_Status === JobOpeningStatus.Closed &&
          position.Reason_of_Closing === JobOpeningReasonOfClosing.OnHold;
        const isClosed =
          position.Job_Opening_Status === JobOpeningStatus.Closed &&
          position.Reason_of_Closing !== JobOpeningReasonOfClosing.OnHold;

        const Icon = getPriorityIcon(position.Priority, isOnHold, isClosed);
        const isSelected = selectedJobOpenings.includes(position.id);

        return (
          <Flex
            key={position.id}
            justifyContent="space-between"
            sx={() => ({
              padding: '16px 24px',
              background: isSelected
                ? 'rgba(33, 150, 243, 0.08)'
                : 'transparent',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            })}
          >
            <Flex gap={1}>
              {Icon && (
                <Tooltip
                  title={
                    isOnHold
                      ? 'On-Hold'
                      : isClosed
                      ? 'Closed'
                      : position.Priority
                  }
                  placement="top"
                >
                  <Icon width="18" sx={{ width: 18 }} />
                </Tooltip>
              )}
              <Typography
                variant="body2"
                color="text.secondary"
                marginLeft={'0.25rem'}
              >
                {position.Job_Opening_Id}
              </Typography>
              <Typography sx={{ textDecoration: 'underline' }}>
                {position.Job_Opening_Name}
              </Typography>
            </Flex>
            <Flex justifyContent="end">
              <Typography>{position.TechnicalFlow}</Typography>
              <Checkbox
                disabled={isLoading}
                checked={selectedJobOpenings.includes(position.id)}
                onChange={(_, val) =>
                  onChangeSelectedJobOpenings(
                    val
                      ? [...selectedJobOpenings, position.id]
                      : selectedJobOpenings.filter((e) => e !== position.id),
                  )
                }
              />
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
