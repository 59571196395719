import { useCallback } from 'react';

import { Box, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { CollapseSection, AttachmentButton } from '@components';

import {
  isTestTaskDone,
  formatDateWithTimezone,
  toUpperFirstWithSpace,
} from '@utils';
import { CandidateTestTask } from '@types';
import { apiEndpoints } from '@constants';

export const InternalTestTask: React.FC<{
  data: CandidateTestTask;
}> = ({ data }) => {
  const {
    internalTestTask,
    sentDate,
    resultDate,
    resolutionFileNames,
    status,
  } = data;

  const isDone = isTestTaskDone(status);

  const downloadAttachment = useCallback((filename: string) => {
    const url = apiEndpoints.internalTestTaskAttachmentFileUrl(filename);
    window.open(url, '_blank', 'noreferrer');
  }, []);

  const openResolutionFile = useCallback((filename: string) => {
    const url = apiEndpoints.testTaskFileUrl(filename);
    window.open(url, '_blank', 'noreferrer');
  }, []);

  if (!internalTestTask) return null;

  if (!isDone)
    return (
      <Box>
        <Typography variant="body1" fontWeight={600}>
          Test task is in progress
        </Typography>
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box display="flex" justifyContent="space-between" gap={2}>
        <Box>
          <Typography variant="h2">{internalTestTask.name}</Typography>
          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            {internalTestTask.specialization}
          </Typography>
        </Box>
        <Typography>Open on {formatDateWithTimezone(sentDate)}</Typography>
      </Box>

      {internalTestTask.description && (
        <Box
          className="content"
          dangerouslySetInnerHTML={{
            __html: internalTestTask.description,
          }}
        />
      )}

      {internalTestTask.instruction && (
        <CollapseSection textLength={internalTestTask.instruction.length}>
          <Box
            className="content"
            dangerouslySetInnerHTML={{
              __html: internalTestTask.instruction,
            }}
          />
        </CollapseSection>
      )}

      {internalTestTask.attachments?.length ? (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="body1" fontWeight="600">
            Check attachments
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {internalTestTask?.attachments?.map((attachment, index) => (
              <AttachmentButton
                key={index}
                variant="secondary"
                onClick={() => downloadAttachment(attachment)}
              >
                {toUpperFirstWithSpace(attachment)}
              </AttachmentButton>
            ))}
          </Box>
        </Box>
      ) : null}

      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" fontWeight="500">
            Test task results
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Uploaded on {formatDateWithTimezone(resultDate)}
          </Typography>
        </Box>
        {resolutionFileNames &&
          resolutionFileNames.map((filename, index) => (
            <Box
              key={index}
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: theme.palette.highlight.fileSelection,
                borderRadius: '8px',
                p: '1.5rem 1.5rem',
                cursor: 'pointer',
                transition: '0.25s all ease-in-out',
                '&:hover': {
                  background: theme.palette.highlight.fileSelectionHover,
                },
              })}
              onClick={() => openResolutionFile(filename)}
            >
              <Box display="flex" gap={2}>
                <AttachFileIcon htmlColor="white" />
                <Typography variant="body1" color="text.invertedPrimary">
                  {toUpperFirstWithSpace(filename)}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
