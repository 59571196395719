import {
  CandidateTestTask,
  CandidateVideoInterview,
  DateTimeRange,
  ICandidateSubmission,
  IProfile,
  IZohoCandidate,
  Person,
  SubmissionFormPayload,
  SubmissionFormValues,
  SubmissionVideoInterview,
} from '@types';
import {
  SubmissionStatus,
  SubmissionStep,
  SubmissionStepsOrdered,
} from 'constants/submission';
import { differenceInYears, isAfter } from 'date-fns';
import { get, isNumber, uniqBy } from 'lodash';
import { isTruthy } from './isTruthy';
import { getTimezoneData } from './timezone';
import { displayDateTimeInTimezone } from './date';
import { isCandidateNewHomeStage } from '@utils';

export const isStepEqualOrHigherThan = (
  stepA: SubmissionStep,
  stepB: SubmissionStep,
): boolean =>
  SubmissionStepsOrdered.findIndex((e) => e === stepA) >=
  SubmissionStepsOrdered.findIndex((e) => e === stepB);

const getFieldValue = (fieldName: string, values: SubmissionFormValues) => {
  if (values.hiddenFields.includes(fieldName)) return null;

  return get(values, fieldName);
};

const getHiddenFieldsBasedOnSubmission = (
  submission: ICandidateSubmission | null,
  videoInterview: SubmissionVideoInterview[],
  testTasks: CandidateTestTask[] | null,
): string[] => {
  const hiddenFields: string[] = [];

  if (!videoInterview.length) {
    hiddenFields.push('videoInterview');
  }

  if (!testTasks?.length) {
    hiddenFields.push('testTask');
  }

  if (!submission) return hiddenFields;

  const step =
    submission.status === SubmissionStatus.Draft
      ? submission.step!
      : SubmissionStepsOrdered.at(-1)!;

  if (isStepEqualOrHigherThan(step, SubmissionStep.Profile)) {
    const profile = submission.profile;

    !profile?.email && hiddenFields.push('profile.email');
    !profile?.firstName && hiddenFields.push('profile.firstName');
    !profile?.lastName && hiddenFields.push('profile.lastName');
    !profile?.country && hiddenFields.push('profile.country');
    !profile?.city && hiddenFields.push('profile.city');
    !profile?.englishLevel && hiddenFields.push('profile.englishLevel');
    !profile?.specialization && hiddenFields.push('profile.specialization');
    !profile?.technicalFlow && hiddenFields.push('profile.technicalFlow');
    !profile?.primarySkills && hiddenFields.push('profile.primarySkills');
    !profile?.secondarySkills && hiddenFields.push('profile.secondarySkills');
    !profile?.experience && hiddenFields.push('profile.experience');
    !profile?.seniority && hiddenFields.push('profile.seniority');
    !profile?.noticePeriod && hiddenFields.push('profile.noticePeriod');
  }

  if (isStepEqualOrHigherThan(step, SubmissionStep.Resume)) {
    const profile = submission.profile;

    !profile?.resumeName && hiddenFields.push('profile.resumeName');
  }

  if (isStepEqualOrHigherThan(step, SubmissionStep.VideoInterview)) {
    const submissionVI = submission.videoInterview;

    videoInterview
      .filter(
        (vi) =>
          !submissionVI?.some(
            (svi) => svi.recordFileName === vi.recordFileName,
          ),
      )
      .map((vi) => {
        hiddenFields.push(`videoInterview.${vi.recordFileName}`);
      });
  }

  if (isStepEqualOrHigherThan(step, SubmissionStep.TestTask)) {
    !submission?.testTasks?.length && hiddenFields.push('testTask');
  }

  if (isStepEqualOrHigherThan(step, SubmissionStep.Feedback)) {
    !submission?.feedback?.feedback && hiddenFields.push('feedback');
  }

  return hiddenFields;
};

export const getInitialSubmissionFormValues = ({
  candidateDetails,
  submissionDetails,
  videoInterview,
  testTasks,
  profile,
}: {
  candidateDetails: IZohoCandidate | null;
  submissionDetails: ICandidateSubmission | null;
  videoInterview: CandidateVideoInterview | null;
  testTasks: CandidateTestTask[] | null;
  profile: IProfile | null;
}): SubmissionFormValues => {
  const yearsOfExperience = candidateDetails?.Seniority_Start_Date
    ? differenceInYears(
        new Date(),
        new Date(candidateDetails.Seniority_Start_Date),
      )
    : null;
  const defaultVI = videoInterview?.records.map((record) => ({
    question:
      videoInterview.videoInterview?.questions.find(
        (question) => question.questionNumber === record.questionNumber,
      )?.title || '',
    recordFileName: record.fileName,
  }));
  const hiddenFields = getHiddenFieldsBasedOnSubmission(
    submissionDetails,
    defaultVI || [],
    testTasks,
  );

  return {
    profile: {
      email:
        submissionDetails?.profile?.email || candidateDetails?.Email || null,
      firstName:
        submissionDetails?.profile?.firstName ||
        candidateDetails?.First_Name ||
        null,
      lastName:
        submissionDetails?.profile?.lastName ||
        candidateDetails?.Last_Name ||
        null,
      country:
        submissionDetails?.profile?.country ||
        candidateDetails?.Location_Country ||
        null,
      city:
        submissionDetails?.profile?.city ||
        candidateDetails?.Location_City ||
        null,
      englishLevel:
        submissionDetails?.profile?.englishLevel ||
        candidateDetails?.English_level ||
        null,
      specialization:
        submissionDetails?.profile?.specialization ||
        candidateDetails?.['Dev_-_QA-AQA'] ||
        null,
      technicalFlow:
        submissionDetails?.profile?.technicalFlow ||
        candidateDetails?.Technical_Flow ||
        null,
      primarySkills:
        submissionDetails?.profile?.primarySkills ||
        candidateDetails?.Primary_Skill_Set ||
        null,
      secondarySkills:
        submissionDetails?.profile?.secondarySkills ||
        candidateDetails?.Secondary_Skill_Set ||
        null,
      seniority:
        submissionDetails?.profile?.seniority ||
        candidateDetails?.Seniority_Level ||
        null,
      experience: isNumber(submissionDetails?.profile?.experience)
        ? submissionDetails!.profile!.experience!
        : yearsOfExperience,
      noticePeriod:
        submissionDetails?.profile?.noticePeriod ||
        candidateDetails?.Notice_period_from_Offer ||
        null,
      linkedInLink: null,
      resumeName:
        submissionDetails?.profile?.resumeName ||
        candidateDetails?.CV_File_Name ||
        null,
    },
    videoInterview: uniqBy(
      [...(defaultVI || []), ...(submissionDetails?.videoInterview || [])],
      'recordFileName',
    ),
    testTask: submissionDetails?.testTasks?.[0] || {
      status: null,
      technology: null,
      score: null,
      summaryFileName: null,
      feedback: null,
      reviewer: null,
    },
    feedback: {
      feedback:
        submissionDetails?.feedback?.feedback ||
        candidateDetails?.Technical_Conslusion ||
        null,
      reviewer: submissionDetails?.feedback?.reviewer || null,
    },
    interview: {
      candidateSlots: submissionDetails?.interview?.candidateSlots || null,
      candidateTimezone:
        submissionDetails?.interview?.candidateTimezone ||
        getTimezoneData(candidateDetails) ||
        null,
      clientTimezone: submissionDetails?.interview?.clientTimezone || null,
      candidateReadyAnytime:
        submissionDetails?.interview?.candidateReadyAnytime || false,
    },
    clientName: submissionDetails?.positions?.[0]?.client || null,
    positions: submissionDetails?.positions || [],
    recipients: submissionDetails?.recipients || [],
    cc: submissionDetails?.cc || [profile?.email].filter(isTruthy),
    email: {
      subject: submissionDetails?.email?.subject || null,
      content: submissionDetails?.email?.content || null,
    },
    resumeFile: null,
    hiddenFields,
    step: submissionDetails?.step || SubmissionStep.Profile,
    status: submissionDetails?.status || SubmissionStatus.Draft,
    notifyOnce: submissionDetails?.notifyOnce || false,
  };
};

export const generateSubmissionEmailSubject = (
  formValues: SubmissionFormValues,
) =>
  `${formValues.profile.firstName} ${formValues.profile.lastName} — ${formValues.profile.seniority} ${formValues.profile.technicalFlow} Engineer for ${formValues.clientName}`;

export const generateSubmissionEmailIntro = (
  formValues: SubmissionFormValues,
) =>
  `Hi ${
    formValues.recipients.length === 1
      ? formValues.recipients[0].name?.split(' ')[0] || ''
      : ''
  }👋\n\nI’m excited to present a highly qualified candidate for your project! ${
    formValues.profile.firstName
  } ${formValues.profile.lastName} from ${
    formValues.profile.country
  } is an excellent match for your position requirements.\n\nTake a look at ${
    formValues.profile.firstName
  }’s profile, and let us know if you’d like us to schedule an interview.`;

export const generateHotPipelineCandidateEmailInto = (
  candidateName: string,
  calendlyLink: string | null,
) =>
  `${candidateName}, \n\nWe're excited to tell you that we’ve found \nsomething that matches your profile and skill set! 

Let’s hop on a call and I’ll be happy to answer all your questions: \n
${calendlyLink || 'https://calendly.com/'} `;

export const generateHotPipelineCandidateSubject = () =>
  `Let’s have a call to discuss your opportunities at AgileEngine`;

export const getSubmissionPayloadData = (
  candidateId: string,
  values: SubmissionFormValues,
  currentStep: SubmissionStep,
): SubmissionFormPayload => {
  const nextStep = isStepEqualOrHigherThan(values.step, currentStep)
    ? values.step
    : currentStep;

  const profile = isStepEqualOrHigherThan(nextStep, SubmissionStep.Profile)
    ? {
        email: getFieldValue('profile.email', values),
        firstName: getFieldValue('profile.firstName', values),
        lastName: getFieldValue('profile.lastName', values),
        country: getFieldValue('profile.country', values),
        city: getFieldValue('profile.city', values),
        englishLevel: getFieldValue('profile.englishLevel', values),
        specialization: getFieldValue('profile.specialization', values),
        technicalFlow: getFieldValue('profile.technicalFlow', values),
        primarySkills: getFieldValue('profile.primarySkills', values),
        secondarySkills: getFieldValue('profile.secondarySkills', values),
        experience: getFieldValue('profile.experience', values),
        seniority: getFieldValue('profile.seniority', values),
        noticePeriod: getFieldValue('profile.noticePeriod', values),
        linkedInLink: null,
        resumeName: isStepEqualOrHigherThan(nextStep, SubmissionStep.Resume)
          ? getFieldValue('profile.resumeName', values)
          : null,
      }
    : null;
  const videoInterview = isStepEqualOrHigherThan(
    nextStep,
    SubmissionStep.VideoInterview,
  )
    ? values.hiddenFields.includes('videoInterview')
      ? null
      : values.videoInterview.filter(
          (question) =>
            !values.hiddenFields.includes(
              `videoInterview.${question.recordFileName}`,
            ),
        )
    : null;
  const testTask = isStepEqualOrHigherThan(nextStep, SubmissionStep.TestTask)
    ? values.hiddenFields.includes('testTask') || !values.testTask.technology
      ? null
      : values.testTask
    : null;
  const feedback = isStepEqualOrHigherThan(nextStep, SubmissionStep.Feedback)
    ? values.hiddenFields.includes('feedback') || !values.feedback.feedback
      ? null
      : values.feedback
    : null;
  const interview = isStepEqualOrHigherThan(nextStep, SubmissionStep.Interview)
    ? {
        ...(values.interview || {}),
        candidateSlots: (values?.interview?.candidateSlots || []).filter(
          Boolean,
        ),
      }
    : null;
  const positionIds = isStepEqualOrHigherThan(
    nextStep,
    SubmissionStep.Recipients,
  )
    ? values.positions.map((e) => e.id).filter(isTruthy)
    : null;
  const recipients = isStepEqualOrHigherThan(
    nextStep,
    SubmissionStep.Recipients,
  )
    ? values.recipients
    : null;
  const cc = isStepEqualOrHigherThan(nextStep, SubmissionStep.Recipients)
    ? values.cc
    : null;
  const email = isStepEqualOrHigherThan(nextStep, SubmissionStep.Recipients)
    ? values.email
    : null;

  return {
    candidateId,
    profile,
    videoInterview,
    testTasks: testTask && [testTask],
    feedback,
    interview,
    positionIds,
    recipients,
    cc,
    email,
    step: nextStep,
    status: values.status,
    notifyOnce: values.notifyOnce,
  };
};

export const isClientInterviewDone = (
  slot: DateTimeRange | null,
  clientTimezone: string | null,
) => {
  return (
    slot?.end &&
    isAfter(
      new Date(),
      displayDateTimeInTimezone(
        slot?.end || null,
        clientTimezone ?? '',
      ).toDate(),
    )
  );
};

export function getStepOrder(step: SubmissionStep): number {
  const index = SubmissionStepsOrdered.indexOf(step);
  return index >= 0 ? index + 1 : -1;
}
export const isStandaloneRecepient = (person: Person) => {
  return person.isStandalone;
};

export const getDefaultSubmissionCCValues = (
  currentValues: string[],
  defaultCCOptionsRecipients: string[],
  newHomeTOMRecipient: string[],
  candidateDetails: IZohoCandidate | null,
) => {
  const updatedValues = Array.from(
    new Set([...defaultCCOptionsRecipients, ...currentValues]),
  );

  const isNewHome =
    candidateDetails && isCandidateNewHomeStage(candidateDetails);

  return Array.from(
    new Set([...(isNewHome ? newHomeTOMRecipient : []), ...updatedValues]),
  );
};
