export enum AEResumeSkillCategory {
  Language = 'Language',
  OS = 'OS',
  DBMS = 'DBMS',
  SCM = 'SCM',
  Other = 'Other',
}

export const AEResumeKeywordsByCategory: Record<
  AEResumeSkillCategory,
  string[]
> = {
  [AEResumeSkillCategory.Language]: ['language', 'framework'],
  [AEResumeSkillCategory.OS]: ['os', 'platform', 'operating system'],
  [AEResumeSkillCategory.DBMS]: ['db', 'database'],
  [AEResumeSkillCategory.SCM]: ['scm', 'build', 'ci', 'cd'],
  [AEResumeSkillCategory.Other]: ['other'],
};

export const AEResumeSkillCategoryLabel: Record<AEResumeSkillCategory, string> =
  {
    [AEResumeSkillCategory.Language]: 'Language and frameworks',
    [AEResumeSkillCategory.OS]: 'OS & platforms',
    [AEResumeSkillCategory.DBMS]: 'DBMS',
    [AEResumeSkillCategory.SCM]: 'SCM, build, CI/CD',
    [AEResumeSkillCategory.Other]: 'Other',
  };

export const AEResumeSkillCategoryOrder: AEResumeSkillCategory[] = [
  AEResumeSkillCategory.Language,
  AEResumeSkillCategory.OS,
  AEResumeSkillCategory.DBMS,
  AEResumeSkillCategory.SCM,
  AEResumeSkillCategory.Other,
];
