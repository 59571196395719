import React from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Typography,
  Stack,
  Checkbox,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VideoCallRoundedIcon from '@mui/icons-material/VideoCallRounded';

export const VideoInterviewSendConfirmationModal: React.FC<{
  isOpen: boolean;
  isLoading: boolean;
  isVideoInterviewEmailInvite: boolean;
  handleClose: () => void;
  handleSendVI: () => void;
  handleVideoInterviewEmailInvite: () => void;
}> = ({
  isOpen,
  isLoading,
  isVideoInterviewEmailInvite,
  handleClose,
  handleSendVI,
  handleVideoInterviewEmailInvite,
}) => {
  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle variant="h2" fontWeight={500} fontFamily="Inter var">
        Request video interview
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
        }}
      >
        <CloseRoundedIcon color="secondary" fontSize="small" />
      </IconButton>
      <DialogContent>
        <Stack
          direction="row"
          bgcolor="background.infoFill"
          padding="1rem"
          borderRadius="0.25rem"
          gap="0.75rem"
        >
          <VideoCallRoundedIcon sx={{ width: '1.375rem', color: '#0288D1' }} />
          <Typography variant="body2" color="text.infoText">
            {isVideoInterviewEmailInvite
              ? 'Candidate will receive an email with a link to a recording screen and 3 followup reminders if needed.'
              : "An invite link for the candidate's video interview will be generated. You can share it manually via any convenient channel."}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          padding="1rem"
          borderRadius="0.25rem"
          alignItems={'center'}
          justifyContent={'start'}
        >
          <Checkbox
            checked={isVideoInterviewEmailInvite}
            onChange={handleVideoInterviewEmailInvite}
          />
          <Typography variant="body2">
            {' '}
            Send Video interview email invite
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem 1.5rem' }}>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          size="medium"
          onClick={handleSendVI}
          sx={{
            gap: '0.25rem',
          }}
        >
          {isLoading && <CircularProgress size={16} color="secondary" />}
          {!isVideoInterviewEmailInvite ? 'Generate link' : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
