import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useFormik } from 'formik';
import { uniq } from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import { Flex } from '@components';
import { AEResumeNameField } from './components/fields/NameField';
import { AEResumeRoleField } from './components/fields/RoleField';
import { AEResumeSummaryField } from './components/fields/SummaryField';
import { AEResumeSkillFields } from './components/fields/SkillFields';
import { AEResumeEducationSection } from './components/fields/EducationSection';
import { AEResumeCertificationSection } from './components/fields/CertificationSection';
import { AEResumeEmploymentHistorySection } from './components/fields/EmploymentHistorySection';

import {
  candidateDetailsSelectors,
  generateCandidateAEResume,
} from '@redux/candidateDetails';

import {
  generateAEResumePayload,
  getInitialAEResumeFormValues,
  isStatusLoading,
  isTruthy,
} from '@utils';
import { AEResumeFormFields } from '@types';

import { aeResumeGenerationValidationSchema } from './validationSchema';

export const AEResumeForm: React.FC<{
  isOpen: boolean;
  onModalClose: () => void;
}> = ({ isOpen, onModalClose }) => {
  const dispatch = useAppDispatch();
  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const candidate = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const aeResume = useAppSelector(
    candidateDetailsSelectors.getCandidateAEResume,
  );

  const initialValues = useMemo(
    () => getInitialAEResumeFormValues(aeResume.data, candidate),
    [aeResume.data, candidate],
  );

  const formik = useFormik<AEResumeFormFields>({
    initialValues,
    validationSchema: aeResumeGenerationValidationSchema,
    onSubmit: async (values) => {
      const payload = generateAEResumePayload(candidateId!, values);

      await dispatch(generateCandidateAEResume(payload));
    },
    validateOnMount: false,
    enableReinitialize: true,
  });

  const isLoading =
    isStatusLoading(aeResume.apiStatus) || aeResume.isPerformingAction;
  const allUniqSkills = uniq(
    ([] as (string | null)[])
      .concat(
        aeResume.data?.parsedCV?.skills?.flatMap((skills) => skills.skills) ||
          [],
      )
      .concat(candidate?.Primary_Skill_Set || null)
      .concat(candidate?.Secondary_Skill_Set || null)
      .concat(
        (candidate?.Skill_Set || '')
          .split(/[,;]+/)
          .map((e: string) => e.trim())
          .filter(Boolean) || null,
      )
      .concat(formik.values.skills?.map((e) => e.skill) || null)
      .filter(isTruthy),
  );

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">Formatting</Typography>
          <CloseIcon
            onClick={onModalClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box
            sx={(theme) => ({
              background: theme.palette.highlight.neutral,
              padding: '16px',
              borderRadius: '4px',
              display: 'flex',
              gap: '16px',
            })}
          >
            <SpellcheckIcon />
            <Typography variant="body2">
              Please check if the data has been structured correctly
            </Typography>
          </Box>
          <AEResumeNameField
            name="name"
            value={formik.values.name}
            disabled={isLoading}
            touched={!!formik.touched.name}
            error={formik.errors.name}
            onChange={(value) => formik.setFieldValue('name', value)}
            onBlur={formik.handleBlur}
          />
          <AEResumeRoleField
            name="position"
            value={formik.values.position}
            disabled={isLoading}
            touched={!!formik.touched.position}
            error={formik.errors.position}
            onChange={(value) => formik.setFieldValue('position', value)}
            onBlur={formik.handleBlur}
          />
          <AEResumeSummaryField
            name="summary"
            value={formik.values.summary}
            disabled={isLoading}
            touched={!!formik.touched.summary}
            error={formik.errors.summary}
            onChange={(value) => formik.setFieldValue('summary', value)}
            onBlur={formik.handleBlur}
          />
          <AEResumeSkillFields
            value={formik.values.skills}
            cvSkills={allUniqSkills}
            disabled={isLoading}
            onChange={(value) => formik.setFieldValue('skills', value)}
          />
          <AEResumeEducationSection
            value={formik.values.educations}
            disabled={isLoading}
            touched={formik.touched.educations}
            errors={formik.errors.educations}
            isSubmitted={!!formik.submitCount}
            onChange={(value) => formik.setFieldValue('educations', value)}
            onTouchField={formik.setFieldTouched}
          />
          <AEResumeCertificationSection
            value={formik.values.certifications}
            disabled={isLoading}
            touched={formik.touched.certifications}
            errors={formik.errors.certifications}
            isSubmitted={!!formik.submitCount}
            onChange={(value) => formik.setFieldValue('certifications', value)}
            onTouchField={formik.setFieldTouched}
          />
          <AEResumeEmploymentHistorySection
            value={formik.values.employmentHistory}
            disabled={isLoading}
            touched={formik.touched.employmentHistory}
            errors={formik.errors.employmentHistory}
            isSubmitted={!!formik.submitCount}
            allSkills={allUniqSkills}
            onChange={(value) =>
              formik.setFieldValue('employmentHistory', value)
            }
            onTouchField={formik.setFieldTouched}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
          >
            Generate resume
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
