import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { isEqual, mapValues, omitBy, pickBy } from 'lodash';

import { Box, Stack } from '@mui/material';
import { EntityMainTable } from '@components';
import { Header } from './components/Header';

import { ApiStatus } from '@constants';
import { IEntityMainTableHeaderCell, OrderDirection } from '@types';
import {
  isJobOpeningsPath,
  isOnHoldJobOpeningsPath,
  isStatusLoading,
  isTruthy,
} from '@utils';

interface IEntityListLayoutProps<
  T extends {
    order: OrderDirection | null;
    sortBy: string | null;
  },
  D extends { id: string },
> {
  title: string;
  apiStatus: ApiStatus;

  tableProps: {
    headers: IEntityMainTableHeaderCell[];
    data: D[] | null;
    renderEmptyState: (params: {
      hasFilters?: boolean;
      isLoading?: boolean;
      resetFilters?: () => void;
    }) => ReactNode;
    renderRowComponent: (value: D) => ReactNode;
    onRowClick: (value: string) => void;
    enablePagination?: boolean;
    totalCount?: number;
  };

  dataOptions: {
    value: T;
    default: Partial<T>;
    blocked?: Array<keyof T>;
    filter?: Array<keyof T>;
    onChange?: (value: Partial<Record<keyof T, string[]>>) => void;
  };

  searchProps?: {
    searchDataOptionKey: keyof T;
    placeholder: string;
  };
  groupProps?: {
    groupDataOptionKey: keyof T;
    groupIcon?: ReactNode;
    options: { label: string; value: string }[];
  };
  filterProps?: {
    renderFilters: (params: {
      optionList: Partial<T>;
      isLoading: boolean;
      onChange: (value: Partial<T>) => void;
    }) => ReactNode;
  };
  createEntityProps?: {
    label: string;
    onClick: () => void;
    disabled: boolean;
  };

  children?: ReactNode;
}

export const EntityListLayout = <
  T extends {
    order: OrderDirection | null;
    sortBy: string | null;
  },
  D extends { id: string },
>({
  title,
  apiStatus,
  tableProps,
  dataOptions,
  searchProps,
  groupProps,
  filterProps,
  createEntityProps,
  children,
}: IEntityListLayoutProps<T, D>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isJobOpeningPath =
    isJobOpeningsPath(location.pathname) ||
    isOnHoldJobOpeningsPath(location.pathname);

  const headerRef = useRef<HTMLElement | null>();
  const [isPageInitiazliaed, setIsPageInitialized] = useState(false);

  useEffect(() => {
    const queryParamFilter: Record<string, string[]> = {};

    searchParams.forEach((value, key) => {
      queryParamFilter[key] = ([] as string[])
        .concat(queryParamFilter[key] as string[])
        .concat(value as string)
        .filter(isTruthy);
    });

    const nextQueryParams = {
      ...dataOptions.default,
      ...queryParamFilter,
    };

    setSearchParams(
      omitBy(nextQueryParams, (value, key) =>
        dataOptions.blocked?.includes(key as keyof T),
      ) as Record<string, string[]>,
    );
    setIsPageInitialized(true);
  }, []);

  useEffect(() => {
    if (isPageInitiazliaed) {
      const blockedOptions: Record<string, string[]> = mapValues(
        pickBy(dataOptions.default, (_, key) =>
          dataOptions.blocked?.includes(key as keyof T),
        ),
        (val) => ([] as string[]).concat(val as string),
      );
      const optionKeys = Object.keys(dataOptions.value);
      const queryParamFilter: Record<string, string[]> = {};

      searchParams.forEach((value, key) => {
        if (optionKeys.includes(key))
          queryParamFilter[key] = ([] as string[])
            .concat(queryParamFilter[key] as string[])
            .concat(value as string)
            .filter(isTruthy);
      });

      dataOptions.onChange?.({
        ...blockedOptions,
        ...queryParamFilter,
      } as Partial<Record<keyof T, string[]>>);

      setIsPageInitialized(true);
    }
  }, [searchParams, isPageInitiazliaed]);

  // Search

  const searchOptions = useMemo(
    () =>
      searchProps
        ? {
            isLoading: isStatusLoading(apiStatus),
            placeholder: searchProps.placeholder,
            value: dataOptions.value[searchProps.searchDataOptionKey] as
              | string
              | null,
            onChange: (value: string | null) => {
              if (!searchProps?.searchDataOptionKey) return;

              setSearchParams((currFilters) => {
                currFilters.delete(searchProps.searchDataOptionKey as string);
                value &&
                  currFilters.append(
                    searchProps.searchDataOptionKey as string,
                    value,
                  );

                return currFilters;
              });
            },
          }
        : undefined,
    [apiStatus, searchProps, dataOptions.value, searchParams],
  );

  // Groupping
  const groupOptions = useMemo(
    () =>
      groupProps
        ? {
            value: dataOptions.value[groupProps.groupDataOptionKey] as
              | string
              | null,
            options: groupProps.options,
            onChange: (value: string | null) => {
              if (!groupProps?.groupDataOptionKey) return;

              setSearchParams((currFilters) => {
                currFilters.delete(groupProps.groupDataOptionKey as string);
                value &&
                  currFilters.append(
                    groupProps.groupDataOptionKey as string,
                    value,
                  );

                return currFilters;
              });
            },
          }
        : undefined,
    [groupProps, dataOptions.value, searchParams],
  );

  // Filters
  const [localDataOptions, setLocalDataOptions] = useState<Partial<T>>({});

  const filterOptions = useMemo(() => {
    if (!filterProps) return;

    const filtersCount = Object.entries(dataOptions.value).filter(
      ([key, values]) =>
        dataOptions.filter?.includes(key as keyof T) &&
        !dataOptions.blocked?.includes(key as keyof T) &&
        !!values?.length,
    )?.length;
    const isApplyFiltersDisabled = Object.entries(localDataOptions).every(
      ([key, value]) => isEqual(dataOptions.value[key as keyof T], value),
    );

    const onOpenFilterPanel = () => {
      const filterDataOptions = omitBy(
        dataOptions.value,
        (_, key) => !dataOptions.filter?.includes(key as keyof T),
      ) as Partial<T>;

      setLocalDataOptions(filterDataOptions);
    };

    const handleApplyFiltersClick = () =>
      setSearchParams((currFilters) => {
        const queryParamFilter: Record<string, any> = {};

        currFilters.forEach((value, key) => {
          if (!dataOptions.filter?.includes(key as keyof T))
            queryParamFilter[key] = value;
        });
        for (const [key, value] of Object.entries(localDataOptions)) {
          value && (queryParamFilter[key] = value);
        }

        return queryParamFilter;
      });

    const handleResetFiltersClick = () =>
      setSearchParams((currFilters) => {
        const nextQueryParams: Record<string, any> = {};

        currFilters.forEach((value, key) => {
          if (!dataOptions.filter?.includes(key as keyof T))
            nextQueryParams[key] = value;
        });

        return nextQueryParams;
      });

    const handleLocalOptionsChange = (payload: Partial<T>) => {
      setLocalDataOptions((currVal) => ({
        ...currVal,
        ...payload,
      }));
    };

    return {
      filtersAmount: filtersCount,
      isLoading: isStatusLoading(apiStatus),
      isApplyDisabled: isApplyFiltersDisabled,
      onOpenPanel: onOpenFilterPanel,
      onResetClick: handleResetFiltersClick,
      onApplyClick: handleApplyFiltersClick,
      FiltersComponent: filterProps.renderFilters({
        optionList: localDataOptions,
        isLoading: isStatusLoading(apiStatus),
        onChange: handleLocalOptionsChange,
      }),
    };
  }, [filterProps, apiStatus, dataOptions, localDataOptions, searchParams]);

  // Sorting
  const sortOptions = useMemo(
    () => ({
      direction: dataOptions.value.order,
      sortBy: dataOptions.value.sortBy,
      onSort: ({
        field,
        direction,
      }: {
        field: string;
        direction: OrderDirection;
      }) =>
        setSearchParams((currFilters) => {
          const nextQueryParams: Record<string, any> = {};

          currFilters.forEach((value, key) => {
            nextQueryParams[key] = ([] as string[])
              .concat(nextQueryParams[key] as string[])
              .concat(value as string)
              .filter(isTruthy);
          });

          return {
            ...nextQueryParams,
            sortBy: field,
            order: direction,
          };
        }),
    }),
    [dataOptions.value],
  );

  // Pagination
  const paginationOptions = useMemo(
    () =>
      tableProps.enablePagination
        ? {
            totalCount: tableProps.totalCount!,
            onFetchNextPage: () =>
              setSearchParams((currFilters) => {
                const nextQueryParams: Record<string, any> = {};

                currFilters.forEach((value, key) => {
                  nextQueryParams[key] = ([] as string[])
                    .concat(nextQueryParams[key] as string[])
                    .concat(value as string)
                    .filter(isTruthy);
                });

                const offset = parseInt(currFilters.get('offset') || '0');
                const limit = parseInt(currFilters.get('limit') || '30');

                return {
                  ...nextQueryParams,
                  offset: `${offset + limit}`,
                };
              }),
          }
        : undefined,
    [tableProps],
  );

  const EmptyStateComponent = useMemo(
    () =>
      tableProps.renderEmptyState({
        hasFilters: !!filterOptions?.filtersAmount,
        isLoading: isStatusLoading(apiStatus),
        resetFilters: filterOptions?.onResetClick,
      }),
    [filterOptions?.filtersAmount, filterOptions?.onResetClick],
  );

  const hearedHeight = headerRef.current?.clientHeight || 68;

  return (
    <Stack
      sx={(theme) => ({
        background: theme.palette.background.backgroundPrimary,
      })}
    >
      <Header
        title={title}
        searchOptions={searchOptions}
        groupOptions={groupOptions}
        filterOptions={filterOptions}
        createOptions={createEntityProps}
        ref={headerRef}
      />
      <Box
        sx={{
          height: `calc(100vh - ${hearedHeight + 2}px)`,
        }}
      >
        {isPageInitiazliaed ? (
          <>
            <EntityMainTable<D>
              tableHeaders={tableProps.headers}
              isPositionPage={isJobOpeningPath}
              apiStatus={apiStatus}
              data={tableProps.data}
              groupBy={
                groupProps
                  ? dataOptions.value[groupProps.groupDataOptionKey] === 'None'
                    ? undefined
                    : (dataOptions.value[groupProps.groupDataOptionKey] as
                        | string
                        | null)
                  : undefined
              }
              groupIcon={groupProps?.groupIcon}
              sortOptions={sortOptions}
              paginationOptions={paginationOptions}
              EmptyStateComponent={EmptyStateComponent}
              renderRowComponent={tableProps.renderRowComponent}
              onRowClick={tableProps.onRowClick}
            />
            {children}
          </>
        ) : null}
      </Box>
    </Stack>
  );
};
