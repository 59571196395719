import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { intersection } from 'lodash';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useIsMobileView } from '@hooks/useIsMobileView';

import { Hidden, Stack, Typography } from '@mui/material';

import {
  LoaderSkeleton,
  EngineeringTTButton,
  ForbiddenView,
  CandidateTestTaskFormModal,
} from '@components';
import { TestTaskList } from './components/TestTaskList';

import {
  candidateDetailsSelectors,
  fetchCandidateTestTasks,
  postTestTaskReview,
  sendTestTaskToCandidate,
} from '@redux/candidateDetails';
import { fetchAvailableTestTasks, optionsSelectors } from '@redux/options';

import { isStatusForbidden, isStatusIdle, isStatusLoading } from '@utils';

import { TestTaskReviewPayload } from '@types';
import { TestTaskType } from '@constants';

export const CandidateTestTasks = ({}) => {
  const dispatch = useAppDispatch();
  const isMobileView = useIsMobileView();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const testTasksAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateTestTasksApiStatus,
  );
  const testTasksData = useAppSelector(
    candidateDetailsSelectors.getCandidateTestTasksData,
  );
  const availableTestTasksAPIData = useAppSelector(
    optionsSelectors.getAvailableTTsAPIData,
  );

  const [selectedTestTaskId, setSelectedTestTaskId] = useState<string | null>(
    null,
  );
  const [isTestTaskFormModalOpen, setIsTestTaskFormModalOpen] = useState(false);

  const candidateTestTasks = useMemo(
    () => testTasksData.filter((tt) => !tt.preventDisqualification),
    [testTasksData],
  );

  useEffect(() => {
    if (candidateId && isStatusIdle(testTasksAPIStatus)) {
      dispatch(fetchCandidateTestTasks(candidateId));
    }
  }, [candidateId, testTasksAPIStatus]);

  useEffect(() => {
    if (isStatusIdle(availableTestTasksAPIData.apiStatus)) {
      dispatch(fetchAvailableTestTasks());
    }
  }, [availableTestTasksAPIData.apiStatus]);

  const handleReviewTT = useCallback(
    (payload: { userTestTaskId: string } & TestTaskReviewPayload) => {
      if (!candidateId) return;
      dispatch(
        postTestTaskReview({
          candidateId,
          ...payload,
        }),
      );
    },
    [candidateId],
  );
  const availableTestTasksData = useMemo(() => {
    return availableTestTasksAPIData.data.filter(
      (tt) =>
        tt.type === TestTaskType.INTERNAL &&
        !candidateTestTasks.some(
          (utt) => utt.technicalFlow === tt.technicalFlow,
        ) &&
        !!intersection(
          [tt.specialization, tt.technicalFlow],
          [
            candidateDetails?.['Dev_-_QA-AQA'],
            candidateDetails?.Technical_Flow,
          ],
        ).length,
    );
  }, [availableTestTasksAPIData.data, candidateDetails, candidateTestTasks]);

  useEffect(() => {
    if (!isMobileView) {
      setSelectedTestTaskId(
        availableTestTasksData[0]?.id || candidateTestTasks[0]?.id || null,
      );
    }
  }, [candidateTestTasks, availableTestTasksData, isMobileView]);

  const selectedTTFromAvailableList = useMemo(() => {
    return availableTestTasksData.find((tt) => tt.id === selectedTestTaskId);
  }, [availableTestTasksData, selectedTestTaskId]);

  const selectedTTFromCandidateTT = useMemo(() => {
    return candidateTestTasks.find((tt) => tt.id === selectedTestTaskId);
  }, [candidateTestTasks, selectedTestTaskId]);

  const handleSendTestTask = () => {
    if (candidateId && selectedTTFromAvailableList) {
      dispatch(
        sendTestTaskToCandidate({
          candidateId,
          id: selectedTTFromAvailableList.id,
          technicalFlow: selectedTTFromAvailableList.technicalFlow,
          type: TestTaskType.INTERNAL,
        }),
      );
    }
  };

  const handleTTChange = useCallback((newValue: string) => {
    setSelectedTestTaskId(newValue);
  }, []);
  const handleEditTestTask = useCallback(() => {
    setIsTestTaskFormModalOpen(true);
  }, []);
  const handleCloseTestTaskFormModal = useCallback(() => {
    setIsTestTaskFormModalOpen(false);
  }, []);

  if (
    isStatusLoading(testTasksAPIStatus) ||
    isStatusLoading(availableTestTasksAPIData.apiStatus)
  ) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(testTasksAPIStatus)) {
    return <ForbiddenView />;
  }

  if (!availableTestTasksData.length && !candidateTestTasks.length) {
    return (
      <Typography variant="h3" textAlign="center">
        No test tasks
      </Typography>
    );
  }

  const ButtonComponent = () => {
    return (
      <Stack
        sx={{
          flexDirection: {
            xs: 'row',
            sm: 'column',
          },
          marginLeft: '1rem',
        }}
      >
        <EngineeringTTButton zohoId={candidateId!} />
      </Stack>
    );
  };

  return (
    <React.Fragment>
      <Stack my={'0.5rem'} gap={'1rem'}>
        <Stack
          gap={'1.5rem'}
          flexDirection={'row'}
          sx={{
            mx: {
              xs: '1rem',
              sm: '0.25rem',
            },
          }}
        >
          <Typography variant="h3" flex={1} my={'auto'}>
            {candidateTestTasks.length} test tasks
          </Typography>
          <Hidden smDown>
            <ButtonComponent />
          </Hidden>
        </Stack>
        <Stack
          sx={{
            backgroundColor: {
              xs: 'none',
              sm: 'background.backgroundPrimary',
            },
            borderRadius: '0.5rem',
            padding: {
              xs: '0',
              sm: '1rem',
            },
            mx: {
              xs: '0',
              sm: '-1rem',
            },
          }}
        >
          <TestTaskList
            availableTestTasksData={availableTestTasksData}
            candidateTestTasks={candidateTestTasks}
            selectedTestTaskId={selectedTestTaskId}
            handleChange={handleTTChange}
            handleEditTestTask={handleEditTestTask}
            handleSendTestTask={handleSendTestTask}
            handleReviewTT={handleReviewTT}
          />
        </Stack>
        <Hidden smUp>
          <ButtonComponent />
        </Hidden>
      </Stack>
      <CandidateTestTaskFormModal
        candidateId={candidateId!}
        candidateTestTask={selectedTTFromCandidateTT!}
        isOpen={isTestTaskFormModalOpen}
        onClose={handleCloseTestTaskFormModal}
      />
    </React.Fragment>
  );
};
