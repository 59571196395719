import React from 'react';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import {
  InternalTestTaskResolutionReviewOptions,
  LevelsReviewOptions,
} from '@constants';
import { Flex } from '@components';

const REVIEW_OPTIONS_TYPES: Array<
  Array<{ label: string; secondaryLabel?: string; value: string }>
> = [LevelsReviewOptions, InternalTestTaskResolutionReviewOptions];

export const FlatReviewOption: React.FC<{
  title: string;
  name: string;
  type: number;
  selected: string;
  onChange: (name: string, selectedValue: string) => void;
}> = ({ title, type, selected, onChange, name }) => {
  const currentOptions = REVIEW_OPTIONS_TYPES[type];

  if (!currentOptions) return null;

  return (
    <Flex
      sx={(theme) => ({
        background: theme.palette.highlight.neutral,
        borderRadius: '6px',
        padding: '12px 16px',
      })}
      gap={2}
      justifyContent="space-between"
    >
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
      <RadioGroup
        row
        value={selected}
        onChange={(e) => onChange(name, e.target.value)}
      >
        {currentOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={
              <Radio
                sx={(theme) => ({
                  '&.Mui-checked': {
                    color:
                      option.value === 'poor'
                        ? theme.palette.text.danger
                        : theme.palette.background.link,
                  },
                })}
              />
            }
            label={option.label}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </Flex>
  );
};
