import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import classNames from 'classnames';
import { isNil } from 'lodash';

import { Box, Stack, Typography } from '@mui/material';
import AllInboxRoundedIcon from '@mui/icons-material/AllInboxRounded';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {
  ScheduleEngineeringTIButton,
  EngineeringTTButton,
  RowLayout,
  CustomButton,
  CandidateLPInviteModal,
  Flex,
  JobOpeningLink,
} from '@components';

import {
  candidateDetailsSelectors,
  fetchCandidateJobOpenings,
  setCandidateStatusChangeModalProps,
} from '@redux/candidateDetails';
import { authSelectors } from '@redux/auth';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  isStatusIdle,
  checkUserCanUpdateCandidateStatus,
  transormCandidateStatus,
  pluralize,
  getUserTestTaskInfo,
  formatAndAddDaysToDate,
  checkUserCanSendLPInvite,
} from '@utils';
import {
  AppRoutes,
  CandidateDetailEmptyValues,
  CandidateDetailsTabs,
  CandidateDetailsTabsOrder,
  SearchParams,
  CandidateStatus as CandidateStatusEnum,
} from '@constants';

export const CandidateStatus: React.FC<{
  onTabSelect: (e: any, selectedTabIdx: number) => void;
}> = ({ onTabSelect }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const profile = useAppSelector(authSelectors.getProfileData);
  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const suitableJOAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateSuitablePositionsApiStatus,
  );
  const suitableJOs = useAppSelector(
    candidateDetailsSelectors.getCandidateSuitablePositionsData,
  );

  const [isSendLPInviteModalOpen, setIsSendLPInviteModalOpen] = useState(false);

  const isAbleToUpdateStatus = checkUserCanUpdateCandidateStatus(profile);
  const isAbleToSendLPInvite = checkUserCanSendLPInvite(profile);

  const associatedPositions = useMemo(
    () => suitableJOs.filter((e) => e.Is_Associated),
    [suitableJOs],
  );

  const openStatusChangeModal = () => {
    dispatch(
      setCandidateStatusChangeModalProps({
        isOpen: true,
        selectedJobOpeningId: null,
        preselectedStage: location.state?.nextCandidateStage || null,
        preselectedStatus: null,
      }),
    );
  };

  useEffect(() => {
    if (candidateDetails?.id && isStatusIdle(suitableJOAPIStatus)) {
      dispatch(fetchCandidateJobOpenings(candidateDetails.id));
    }
  }, [candidateDetails?.id, suitableJOAPIStatus]);

  useEffect(() => {
    if (location.state?.nextCandidateStage) {
      openStatusChangeModal();
      window.history.replaceState({}, '');
    }
  }, []);

  const onEditAssociatedPositionsClick = useCallback(
    () =>
      onTabSelect(
        null,
        CandidateDetailsTabsOrder.indexOf(CandidateDetailsTabs.POSITIONS),
      ),
    [],
  );

  const onSubmitCandidateClick = useCallback(() => {
    navigate(
      `/${AppRoutes.SUBMISSIONS}?${SearchParams.SELECTED_CANDIDATE}=${candidateDetails?.id}`,
    );
  }, []);

  const testTaskData = useMemo(
    () => getUserTestTaskInfo(candidateDetails),
    [candidateDetails],
  );

  if (!candidateDetails) {
    return <></>;
  }

  const isNewHome = candidateDetails?.Type_of_Inbound_channel === 'New Home';

  const {
    id,
    Candidate_Status_Updated_At,
    New_Home_Date_Start,
    New_Home_Date_End,
    Candidate_Status,
    Hot_Pipeline,
    Hot_Pipeline_Start_Date,
    Hot_Pipeline_End_Date,
    Leads_owner_2018,
    LP_Invitation_Sent,
  } = candidateDetails;

  return (
    <React.Fragment>
      <Stack
        sx={{
          backgroundColor: classNames({
            'highlight.brandSecondaryLight': isNewHome,
            'highlight.actionable': !isNewHome,
          }),
          padding: '0.5rem',
          borderRadius: '10px',
        }}
      >
        {!isNewHome && (
          <RowLayout
            label={pluralize('Position', associatedPositions.length)}
            isEmpty={!associatedPositions.length}
            Component={
              <Typography variant="body1">
                {associatedPositions.map((jo) => (
                  <span key={jo.id} style={{ display: 'block' }}>
                    <JobOpeningLink
                      jobOpeningId={jo.Job_Opening_Id}
                      jobId={jo.id}
                    />{' '}
                    {jo.Client_Name}: {jo.Job_Opening_Name}
                  </span>
                ))}
              </Typography>
            }
            EndComponent={
              <Flex gap={1} width="100%">
                <CustomButton
                  size="small"
                  color="secondary"
                  label={'Associate'}
                  startIcon={<PersonAddOutlinedIcon fontSize="small" />}
                  onClick={onEditAssociatedPositionsClick}
                  fullWidth
                />
                {associatedPositions.length === 1 && (
                  <CustomButton
                    size="small"
                    color="secondary"
                    label={'Submit'}
                    startIcon={<MailOutlineRoundedIcon fontSize="small" />}
                    onClick={onSubmitCandidateClick}
                    fullWidth
                  />
                )}
              </Flex>
            }
          />
        )}
        <RowLayout
          label={'Status'}
          Component={
            <Typography variant="body1">
              {transormCandidateStatus(Candidate_Status)}{' '}
              <Box component="span" color="text.secondary">
                {Candidate_Status_Updated_At
                  ? format(new Date(Candidate_Status_Updated_At), 'dd MMM yyyy')
                  : '—'}
              </Box>
            </Typography>
          }
          EndComponent={
            <Flex gap={1} width="100%">
              <CustomButton
                isDisabled={!isAbleToUpdateStatus}
                size="small"
                color="secondary"
                label={'Change status'}
                startIcon={<AllInboxRoundedIcon fontSize="small" />}
                onClick={() => openStatusChangeModal()}
                fullWidth
              />
              {Candidate_Status === CandidateStatusEnum.Selfgen &&
                !LP_Invitation_Sent && (
                  <CustomButton
                    isDisabled={!isAbleToSendLPInvite}
                    size="small"
                    color="secondary"
                    label={'Invite to LP'}
                    startIcon={<ContactMailIcon fontSize="small" />}
                    onClick={() => setIsSendLPInviteModalOpen(true)}
                    fullWidth
                  />
                )}
            </Flex>
          }
        />

        {isNewHome && (
          <>
            <RowLayout
              label={'NH Starts'}
              isEmpty={!New_Home_Date_Start}
              Component={
                <Typography variant="body1">
                  {New_Home_Date_Start
                    ? format(new Date(New_Home_Date_Start), 'MMM dd')
                    : '—'}
                </Typography>
              }
            />
            <RowLayout
              label={'NH Ends'}
              isEmpty={!New_Home_Date_End}
              Component={
                <Typography variant="body1">
                  {New_Home_Date_End
                    ? format(new Date(New_Home_Date_End), 'MMM yyyy, dd')
                    : '—'}
                </Typography>
              }
            />
            <RowLayout
              label={'Available from'}
              Component={<Typography variant="body1">Not Specified</Typography>}
            />
          </>
        )}

        <RowLayout
          label={'Engineering TT'}
          isEmpty={!testTaskData}
          Component={
            <Typography variant="body1">
              {testTaskData ? (
                <React.Fragment>
                  Status:{' '}
                  <Box component={'span'} color={testTaskData.color}>
                    {testTaskData.testStatus || '—'}
                  </Box>
                  {' ∙ '}
                  Technology: {testTaskData.testTechnology || '—'}
                  {' ∙ '}
                  {!isNil(testTaskData.testScore) && (
                    <React.Fragment>
                      Score:{' '}
                      <Box component={'span'} color={testTaskData.color}>
                        {testTaskData.testScore}%
                      </Box>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                ''
              )}
            </Typography>
          }
          EndComponent={<EngineeringTTButton zohoId={id} />}
        />
        <RowLayout
          label={'Engineering TI'}
          Component={
            <Typography variant="body1" color="text.secondary">
              {'—'}
            </Typography>
          }
          EndComponent={<ScheduleEngineeringTIButton zohoId={id} />}
        />

        {!isNewHome && (
          <RowLayout
            label={'Respons. Recruiter'}
            isEmpty={
              !Leads_owner_2018 ||
              CandidateDetailEmptyValues.includes(Leads_owner_2018)
            }
            Component={
              <Typography variant="body1">{Leads_owner_2018}</Typography>
            }
          />
        )}
        {!Hot_Pipeline &&
          !!Hot_Pipeline_Start_Date &&
          !!Hot_Pipeline_End_Date && (
            <RowLayout
              label={'Hot Pipeline'}
              Component={
                <Typography variant="body1" color="text.secondary">
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={0.5}
                  >
                    <Typography>
                      {Hot_Pipeline_Start_Date &&
                        formatAndAddDaysToDate(Hot_Pipeline_Start_Date)}
                    </Typography>
                    <Typography> - </Typography>
                    <Typography>
                      {Hot_Pipeline_End_Date &&
                        formatAndAddDaysToDate(Hot_Pipeline_End_Date)}
                    </Typography>
                  </Box>
                </Typography>
              }
            />
          )}
      </Stack>
      <CandidateLPInviteModal
        isOpen={isSendLPInviteModalOpen}
        onCloseModalClick={() => setIsSendLPInviteModalOpen(false)}
      />
    </React.Fragment>
  );
};
