import { useCallback, useState } from 'react';
import { useAppDispatch } from '@redux/hooks';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Flex } from '@components/common';

import { sendSuitablePositionsToZohoProfile } from '@redux/candidateDetails';

import { IDWHJobOpening } from '@types';
import { getPriorityIcon, isTruthy } from '@utils';
import { JobOpeningReasonOfClosing, JobOpeningStatus } from '@constants';

export const SaveSuitablePositionModal: React.FC<{
  candidateId: string;
  suitablePositions: IDWHJobOpening[];
  isOpen: boolean;
  onClose: () => void;
}> = ({ candidateId, suitablePositions, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const [selectedPositionIds, setSelectedPositionIds] = useState<string[]>(
    suitablePositions.map((e) => e.Job_Opening_Id).filter(isTruthy),
  );
  const [isLoading, setIsLoading] = useState(false);

  const onSaveClick = useCallback(async () => {
    setIsLoading(true);
    const res = await dispatch(
      sendSuitablePositionsToZohoProfile({
        candidateId,
        positionIds: selectedPositionIds,
      }),
    );
    setIsLoading(false);
    if (sendSuitablePositionsToZohoProfile.fulfilled.match(res)) {
      onClose();
    }
  }, [selectedPositionIds]);

  return (
    <Dialog open={isOpen} onClose={onClose} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h1">Save suitable positions to Zoho</Typography>
          <CloseIcon
            onClick={onClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>

      <DialogContent>
        {!suitablePositions.length && (
          <Typography>No positions matched</Typography>
        )}
        {suitablePositions.map((position) => {
          const isOnHold =
            position.Job_Opening_Status === JobOpeningStatus.Closed &&
            position.Reason_of_Closing === JobOpeningReasonOfClosing.OnHold;
          const isClosed =
            position.Job_Opening_Status === JobOpeningStatus.Closed &&
            position.Reason_of_Closing !== JobOpeningReasonOfClosing.OnHold;
          const isPreopen =
            position.Job_Opening_Status === JobOpeningStatus.PreOpen;

          const Icon = getPriorityIcon(
            position.Priority,
            isOnHold,
            isClosed,
            isPreopen,
          );

          return (
            <FormControlLabel
              key={position.id}
              control={
                <Checkbox
                  checked={selectedPositionIds.includes(
                    position.Job_Opening_Id,
                  )}
                  onChange={(_, val) =>
                    setSelectedPositionIds(
                      val
                        ? selectedPositionIds.concat(position.Job_Opening_Id)
                        : selectedPositionIds.filter(
                            (e) => e !== position.Job_Opening_Id,
                          ),
                    )
                  }
                />
              }
              label={
                <Flex gap={1}>
                  {Icon && (
                    <Tooltip
                      title={
                        isOnHold
                          ? 'On-Hold'
                          : isClosed
                          ? 'Closed'
                          : position.Priority
                      }
                      placement="top"
                    >
                      <Icon />
                    </Tooltip>
                  )}
                  <Typography variant="body1" color="secondary">
                    {position.Job_Opening_Id}
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.text.primary,
                      textDecoration: 'underline',
                    })}
                  >
                    {position.Client_Name}: {position.Job_Opening_Name}
                  </Typography>
                </Flex>
              }
            />
          );
        })}
      </DialogContent>

      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSaveClick}
            disabled={isLoading}
          >
            Save
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
