import {
  JobOpeningInboundChannels,
  JobOpeningOutboundChannels,
} from '@constants';
import { getPriorityIcon } from '@utils';
import { compact, isEmpty } from 'lodash';

export interface IPriorityIconWithOutreachInfo {
  IconComponent: React.ComponentType<any> | null;
  hasOutreachStopped: boolean;
  stoppedOutreachText: string;
}

export const getPriorityIconWithOutreach = (
  icon: string,
  isOnHold = false,
  isClosed = false,
  stopOutreachChannels: string[],
): IPriorityIconWithOutreachInfo => {
  const IconComponent = getPriorityIcon(icon, isOnHold, isClosed);

  if (
    isEmpty(stopOutreachChannels) ||
    (Array.isArray(stopOutreachChannels) &&
      isEmpty(compact(stopOutreachChannels)))
  ) {
    return {
      IconComponent,
      hasOutreachStopped: false,
      stoppedOutreachText: '',
    };
  }

  const hasAllOutbound = Object.values(JobOpeningOutboundChannels).every(
    (channel) => stopOutreachChannels.includes(channel),
  );
  const hasAllInbound = Object.values(JobOpeningInboundChannels).every(
    (channel) => stopOutreachChannels.includes(channel),
  );

  let stoppedOutreachText = '';

  if (hasAllOutbound && hasAllInbound) {
    stoppedOutreachText = 'No outreach – all channels';
  } else if (hasAllOutbound) {
    stoppedOutreachText = 'No outreach – Outbound';
  } else if (hasAllInbound) {
    stoppedOutreachText = 'No outreach – Inbound';
  } else {
    stoppedOutreachText = `No outreach – ${stopOutreachChannels.join(', ')}`;
  }

  return {
    IconComponent,
    hasOutreachStopped: true,
    stoppedOutreachText,
  };
};
