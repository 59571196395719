import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { fetchJobOpeningClients, optionsSelectors } from '@redux/options';

import { AutocompleteTextfield } from '@components';
import { isStatusIdle } from '@utils';

import { createFilterOptions } from '@mui/material';

export const PositionClientField: React.FC<{
  value: string | null;
  name?: string;
  required?: boolean;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({
  value,
  name,
  error,
  touched,
  required,
  disabled,
  onChange,
  onBlur,
}) => {
  const dispatch = useAppDispatch();

  const { data: clients, apiStatus } = useAppSelector(
    optionsSelectors.getJobOpeningClients,
  );

  const clientOptions = useMemo(
    () => clients.map((client) => client.name),
    [clients],
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchJobOpeningClients());
    }
  }, [apiStatus]);

  return (
    <AutocompleteTextfield
      name={name || 'Client_Name_New'}
      label="Client Name"
      value={value}
      options={clientOptions}
      required={required}
      onBlur={onBlur}
      fullWidth
      freeSolo
      filterSelectedOptions
      clearOnBlur
      selectOnFocus
      error={touched && !!error}
      helperText={touched ? error : undefined}
      disabled={disabled}
      placeholder="Select or add client"
      filterOptions={(options, params) => {
        const filtered = createFilterOptions<string>()(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          filtered.push(`Add "${inputValue}"`);
        }

        return filtered;
      }}
      onChange={(_, newValue: string | null) => {
        const newClientNameValue = newValue?.includes('Add "')
          ? /Add "(.*)"/gi.exec(newValue)?.[1]
          : newValue;

        onChange(newClientNameValue);
      }}
    />
  );
};
