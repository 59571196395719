import React, { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import { useAppDispatch } from '@redux/hooks';

import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { CustomButton, Flex } from '@components';
import ShareIcon from '@mui/icons-material/Share';

import { setSnackbar } from '@redux/snackbar';

import { CandidateVideoInterview } from '@types';
import { SnackbarType, UserVideoInterviewStatus } from '@constants';

export const VideoInterviewShareWithClient: React.FC<{
  isLoading: boolean;
  currentVideoInterview: CandidateVideoInterview | null;
  handleGenerateClick: () => void;
}> = ({ isLoading, currentVideoInterview, handleGenerateClick }) => {
  const dispatch = useAppDispatch();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentVideoInterview?.clientVideoLink ?? '');
    dispatch(
      setSnackbar({
        type: SnackbarType.Info,
        message: 'Link copied to clipboard',
      }),
    );
  };

  const durationText = useMemo(() => {
    if (currentVideoInterview?.clientVideoLink) {
      const link = new URL(currentVideoInterview.clientVideoLink);
      const token = link.searchParams.get('token');
      const { exp: expiresAt } = JSON.parse(window.atob(token!.split('.')[1]));
      const duration = expiresAt
        ? differenceInDays(new Date(expiresAt * 1000), new Date())
        : -1;
      if (duration < 0) {
        return 'Link expired';
      } else {
        return `Will be expired in ${duration} days`;
      }
    } else {
      return '';
    }
  }, [currentVideoInterview?.clientVideoLink]);

  if (
    !currentVideoInterview ||
    currentVideoInterview?.status !== UserVideoInterviewStatus.FINISHED
  )
    return null;

  return (
    <Flex gap={1} flexDirection="column" alignItems="start">
      <Typography variant="body1">Share with client</Typography>
      {currentVideoInterview?.clientVideoLink ? (
        <>
          <Flex gap={1}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled
              fullWidth
              value={currentVideoInterview.clientVideoLink}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Copy to clipboard">
                      <div>
                        <IconButton
                          aria-label="copy link"
                          onClick={handleCopyLink}
                          edge="end"
                        >
                          <ContentCopyRoundedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip title="Regenerate link">
              <div>
                <CustomButton
                  isSquare
                  size="medium"
                  color="primary"
                  isLoading={isLoading}
                  startIcon={<AutorenewRoundedIcon />}
                  onClick={() => handleGenerateClick()}
                />
              </div>
            </Tooltip>
          </Flex>
          {durationText && (
            <Typography variant="subtitle2" color="text.secondary" ml="1rem">
              {durationText}
            </Typography>
          )}
        </>
      ) : (
        <CustomButton
          size="medium"
          color="secondary"
          startIcon={<ShareIcon fontSize="small" />}
          label={'Generate link'}
          onClick={() => handleGenerateClick()}
          isLoading={isLoading}
        />
      )}
    </Flex>
  );
};
