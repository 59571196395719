import { useCallback, useMemo } from 'react';
import { groupBy, mapValues } from 'lodash';

import { AutocompleteTextfield } from '@components';
import { Box, createFilterOptions, Typography } from '@mui/material';

import {
  AEResumeSkillCategory,
  AEResumeSkillCategoryLabel,
  AEResumeSkillCategoryOrder,
} from '@constants';
import { AEResumeFormFields, IAEResumeSkill, Nullable } from '@types';
import { isTruthy } from '@utils';

export const AEResumeSkillFields: React.FC<{
  value: AEResumeFormFields['skills'];
  cvSkills: string[];
  disabled: boolean;
  onChange: (value: AEResumeFormFields['skills'] | null) => void;
}> = ({ value, cvSkills, disabled, onChange }) => {
  const skillsByCategory = useMemo(
    () =>
      mapValues(groupBy(value, 'category'), (skills) =>
        skills.map((e) => e.skill),
      ),
    [value],
  );

  const onChangeSkills = useCallback(
    (category: AEResumeSkillCategory, skills: string[] | null) => {
      const nextValue = ([] as (Nullable<IAEResumeSkill> | null | undefined)[])
        .concat(value?.filter((e) => e.category !== category))
        .concat(skills?.map((skill) => ({ category, skill })) || [])
        .filter(isTruthy);

      onChange(nextValue?.length ? nextValue : null);
    },
    [value],
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="h4">Skills</Typography>
      {AEResumeSkillCategoryOrder.map((skillCategory) => (
        <AutocompleteTextfield
          key={skillCategory}
          label={AEResumeSkillCategoryLabel[skillCategory]}
          options={cvSkills}
          value={skillsByCategory[skillCategory] || []}
          fullWidth
          multiple
          freeSolo
          filterSelectedOptions
          clearOnBlur
          selectOnFocus
          disabled={disabled}
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<string>()(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(`Add "${inputValue}"`);
            }

            return filtered;
          }}
          onChange={(_, newValue: string[] | null) => {
            const newSkillNameValue =
              newValue
                ?.map((value) =>
                  value?.includes('Add "')
                    ? /Add "(.*)"/gi.exec(value)?.[1]
                    : value,
                )
                .filter(isTruthy) || null;

            onChangeSkills(
              skillCategory,
              newSkillNameValue?.length ? newSkillNameValue : null,
            );
          }}
        />
      ))}
    </Box>
  );
};
