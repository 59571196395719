import React, { ReactNode, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SearchInput, CustomButton, FilterPanel, Flex } from '@components';
import { GroupFilter } from './GroupFilter';
import { FilterButton } from './FilterButton';
import { ResetFiltersButton } from './ResetFiltersButton';

import { SearchInputMode } from '@constants';

export const Header: React.FC<{
  title: string;
  searchOptions?: {
    isLoading: boolean;
    placeholder: string;
    value: string | null;
    onChange: (value: string | null) => void;
  };
  groupOptions?: {
    value: string | null;
    options: { label: string; value: string }[];
    onChange: (value: string | null) => void;
  };
  filterOptions?: {
    filtersAmount: number;
    isLoading: boolean;
    isApplyDisabled: boolean;
    onOpenPanel: () => void;
    onResetClick: () => void;
    onApplyClick: () => void;
    FiltersComponent: ReactNode;
  };
  createOptions?: {
    label: string;
    onClick: () => void;
    disabled: boolean;
  };
  ref: any;
}> = React.forwardRef(
  (
    { title, searchOptions, groupOptions, filterOptions, createOptions },
    ref,
  ) => {
    // Filters
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

    const openFilterPanel = useCallback(() => {
      setIsFilterPanelOpen(true);
      if (filterOptions?.onOpenPanel) filterOptions.onOpenPanel();
    }, [filterOptions?.onOpenPanel]);
    const applyFilters = useCallback(() => {
      setIsFilterPanelOpen(false);
      if (filterOptions?.onApplyClick) filterOptions.onApplyClick();
    }, [filterOptions?.onApplyClick]);

    const { FiltersComponent } = filterOptions || {};

    return (
      <Box
        component={'header'}
        sx={(theme) => ({
          px: 3,
          py: 2,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 3,
          backgroundColor: theme.palette.background.backgroundPrimary,
        })}
        ref={ref}
      >
        <Flex flexDirection="column" alignItems="start" justifyContent="center">
          <Typography
            variant="caption"
            sx={(theme) => ({
              fontSize: theme.typography.caption,
              lineHeight: '100%',
              letterSpacing: '0.4px',
              color: 'text.secondary',
              whiteSpace: 'nowrap',
              ml: '2px',
            })}
          >
            LaunchPod Hiring Dashboard
          </Typography>
          <Typography
            variant="h2"
            whiteSpace="nowrap"
            sx={{ lineHeight: '100%' }}
          >
            {title}
          </Typography>
        </Flex>

        <Flex justifyContent={{ xs: 'start', md: 'center' }}>
          {!!searchOptions && (
            <SearchInput
              isLoading={searchOptions.isLoading}
              placeholder={searchOptions.placeholder}
              value={searchOptions.value}
              mode={SearchInputMode.FULL}
              onChange={searchOptions.onChange}
            />
          )}
        </Flex>
        <Flex
          flexWrap="nowrap"
          gap={1}
          justifyContent={{ xs: 'start', md: 'end' }}
        >
          {!!groupOptions && (
            <GroupFilter
              options={groupOptions.options}
              selectedValue={groupOptions.value}
              onSelect={groupOptions.onChange}
            />
          )}
          {!!filterOptions && (
            <>
              <FilterButton
                filtersAmount={filterOptions.filtersAmount}
                onClick={openFilterPanel}
              />
              {!!filterOptions.filtersAmount && (
                <ResetFiltersButton
                  isLoading={filterOptions.isLoading}
                  onClick={filterOptions.onResetClick}
                />
              )}
              <FilterPanel
                isOpen={isFilterPanelOpen}
                hasFilters={!!filterOptions.filtersAmount}
                isApplyDisabled={filterOptions.isApplyDisabled}
                isLoading={filterOptions.isLoading}
                onClosePanel={() => setIsFilterPanelOpen(false)}
                handleResetClick={filterOptions.onResetClick}
                handleApplyClick={applyFilters}
              >
                {FiltersComponent}
              </FilterPanel>
            </>
          )}
          {!!createOptions && (
            <CustomButton
              size="medium"
              color="primary"
              label={createOptions.label}
              onClick={createOptions.onClick}
              isDisabled={createOptions.disabled}
            />
          )}
        </Flex>
      </Box>
    );
  },
);
