import { useEffect, useMemo, useRef } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { DialogActions } from '@mui/material';
import {
  Flex,
  CandidateNotifyForm,
  CandidateSubmissionStatusChangeForm,
} from '@components';
import { FormLayout } from './common/FormLayout';
import { BaseCandidateStatusForm } from './common/BaseForm';
import { CandidateStatusFormActions } from './common/CandidateStatusFormActions';

import {
  candidateDetailsSelectors,
  changeCandidateStatus,
  fetchCandidateSubmissions,
} from '@redux/candidateDetails';

import {
  getInitialCandidateStatusFormValues,
  getTransitionForms,
  isStatusIdle,
  isTruthy,
  transformFormValuesToPayload,
} from '@utils';
import {
  CandidateStatusFormValues,
  IDWHJobOpening,
  IZohoCandidate,
} from '@types';
import {
  AllClosedStatuses,
  CandidateStatus,
  CandidateStatusStage,
  SubmissionStatus,
} from '@constants';

import { candidateStatusValidationSchema } from './validationSchema';

export const CandidateStatusForm: React.FC<{
  candidate: IZohoCandidate;
  selectedJobOpeningId: string | null;
  associatedJopOpenings: IDWHJobOpening[];
  preselectedStage: CandidateStatusStage | null;
  preselectedStatus: CandidateStatus | null;
  onCancel: () => void;
}> = ({
  candidate,
  associatedJopOpenings,
  selectedJobOpeningId,
  preselectedStage,
  preselectedStatus,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const notifyComponentRef = useRef<{ notifyCandidate: () => void }>();
  const submissionStatusFormComponentRef = useRef<{
    updateSubmissionStatus: () => Promise<void>;
  }>();

  const { submissionsAPIStatus, submissions } = useAppSelector(
    candidateDetailsSelectors.getCandidateSubmissions,
  );

  const formik = useFormik<CandidateStatusFormValues>({
    initialValues: getInitialCandidateStatusFormValues(
      candidate,
      selectedJobOpeningId,
      preselectedStage,
      preselectedStatus,
    ),
    validationSchema: candidateStatusValidationSchema,
    onSubmit: async (values) => {
      await notifyComponentRef.current?.notifyCandidate();
      await submissionStatusFormComponentRef.current?.updateSubmissionStatus();
      await dispatch(
        changeCandidateStatus(
          transformFormValuesToPayload(
            values,
            candidate.id,
            candidate,
            associatedJopOpenings,
          ),
        ),
      );
    },
    validateOnMount: true,
    enableReinitialize: false,
  });

  useEffect(() => {
    if (isStatusIdle(submissionsAPIStatus) && candidate.id) {
      dispatch(fetchCandidateSubmissions(candidate.id));
    }
  }, [submissionsAPIStatus, candidate.id]);

  useEffect(() => {
    const nextTransitionForms = getTransitionForms(
      formik.values.transitions,
      candidate,
      associatedJopOpenings,
    );

    const excludedTransitions = [...formik.values.excludedTransitions];

    // Exclude IC statuses
    [
      CandidateStatus.IC_Scheduled,
      CandidateStatus.IC_Done,
      CandidateStatus.IC_Passed,
    ].forEach((status) => {
      const existsInCurrentTransitionForms = formik.values.transitionForms.find(
        (tf) => tf.status === status && tf.stage === CandidateStatusStage.IC,
      );
      const existsInNextTransitionForms = nextTransitionForms.find(
        (tf) => tf.status === status && tf.stage === CandidateStatusStage.IC,
      );

      if (!existsInCurrentTransitionForms && existsInNextTransitionForms)
        excludedTransitions.push({
          stage: CandidateStatusStage.IC,
          status,
        });
    });

    formik.setValues({
      ...formik.values,
      transitionForms: nextTransitionForms,
      excludedTransitions,
    });
  }, [formik.values.transitions]);

  const mainProfileStatusTrasnsition = formik.values.transitions.find(
    (t) => !t.jobOpeningId && t.status,
  );

  const submissionStatusChanges = useMemo(
    () =>
      submissions
        .map((submission) => {
          const transitionStatus = formik.values.transitions.find(
            (transition) =>
              transition.jobOpeningId &&
              submission.positionIds?.includes(transition.jobOpeningId),
          );

          if (!transitionStatus || !transitionStatus.status) return null;

          const submissionStatus =
            transitionStatus.status === CandidateStatus.CI_Scheduled
              ? SubmissionStatus.CallScheduled
              : AllClosedStatuses.includes(transitionStatus.status)
              ? SubmissionStatus.Rejected
              : null;

          if (!submissionStatus) return null;

          return {
            submissionId: submission.id,
            positions: submission.positions,
            status: submissionStatus,
            closingReason: null,
            scheduledSlot: submission.interview?.requestedSlot?.[0] || null,
            clientTimezone: submission.interview?.clientTimezone || null,
          };
        })
        .filter(isTruthy),
    [submissions, formik.values.transitions],
  );

  return (
    <>
      <Flex flexDirection="column" gap={2} alignItems="start">
        <Flex flexDirection="column" gap={2} alignItems="start" px={3}>
          <BaseCandidateStatusForm
            formik={formik}
            candidate={candidate}
            associatedJopOpenings={associatedJopOpenings}
          />
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="start"
          sx={(theme) => ({
            background: theme.palette.highlight.sectionLight,
            px: 3,
          })}
        >
          {formik.values.transitionForms.map(({ stage, status }) => (
            <FormLayout
              key={status}
              stage={stage as CandidateStatusStage}
              status={status as CandidateStatus}
              formik={formik}
              candidateDetails={candidate}
            />
          ))}
          {mainProfileStatusTrasnsition && (
            <CandidateNotifyForm
              candidate={candidate}
              candidateStatus={
                mainProfileStatusTrasnsition.status as CandidateStatus
              }
              ref={notifyComponentRef}
            />
          )}
          {!!submissionStatusChanges.length && (
            <CandidateSubmissionStatusChangeForm
              submissions={submissionStatusChanges}
              ref={submissionStatusFormComponentRef}
            />
          )}
        </Flex>
      </Flex>

      <DialogActions>
        <CandidateStatusFormActions
          formik={formik}
          associatedJopOpenings={associatedJopOpenings}
          onCancel={onCancel}
        />
      </DialogActions>
    </>
  );
};
