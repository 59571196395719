import {
  SyntheticEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useAppDispatch } from '@redux/hooks';
import { useFormik } from 'formik';

import { Box, Stack, Typography, createFilterOptions } from '@mui/material';
import { AutocompleteTextfield, DateTimeRangePicker, Flex } from '@components';

import { DateTimeRange, SubmissionPosition } from '@types';
import { SubmissionClosingReasons, SubmissionStatus } from '@constants';
import {
  markSubmissionAsRejected,
  setSubmissionScheduledSlot,
} from '@redux/submission';

import { submissionStatusChangeValidationSchema } from './validationSchema';
import { displayDateTimeInTimezone } from '@utils';

export const CandidateSubmissionStatusChangeForm: React.FC<{
  submissions: {
    submissionId: string;
    positions: SubmissionPosition[] | null;
    status: SubmissionStatus | null;
    closingReason: string | null;
    scheduledSlot: DateTimeRange | null;
    clientTimezone: string | null;
  }[];
  ref: any;
}> = forwardRef(({ submissions }, ref) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<
    {
      submissionId: string;
      positions: SubmissionPosition[] | null;
      status: SubmissionStatus | null;
      closingReason: string | null;
      scheduledSlot: DateTimeRange | null;
      clientTimezone: string | null;
    }[]
  >({
    initialValues: submissions.map((submission) => ({
      submissionId: submission.submissionId,
      positions: submission.positions,
      status: submission.status,
      closingReason: submission.closingReason,
      scheduledSlot: submission.scheduledSlot,
      clientTimezone: submission.clientTimezone,
    })),
    validationSchema: submissionStatusChangeValidationSchema,
    onSubmit: async (values) => {
      await Promise.all(
        values.map((statusChange) => {
          if (statusChange.status === SubmissionStatus.Rejected) {
            dispatch(
              markSubmissionAsRejected({
                submissionId: statusChange.submissionId,
                closingReason: statusChange.closingReason || '',
              }),
            );
          }
          if (statusChange.status === SubmissionStatus.CallScheduled) {
            dispatch(
              setSubmissionScheduledSlot({
                submissionId: statusChange.submissionId,
                scheduledSlot: statusChange.scheduledSlot!,
              }),
            );
          }
        }),
      );
    },
    validateOnMount: true,
    enableReinitialize: false,
  });

  useImperativeHandle(ref, () => ({
    updateSubmissionStatus: async (): Promise<void> => {
      await formik.handleSubmit();
    },
  }));

  useEffect(() => {
    formik.setValues(submissions);
  }, [submissions]);

  return (
    <Box
      width="100%"
      sx={{ background: '#FFF', padding: '16px', marginBottom: 2 }}
      display="flex"
      flexDirection="column"
      gap={3}
    >
      {formik.values.map((statusChange, idx) => {
        const closingReasonError =
          (formik.touched[idx]?.closingReason || !!formik.submitCount) &&
          formik.errors[idx]?.closingReason
            ? formik.errors[idx]?.closingReason
            : null;

        const scheduledSlotError =
          (formik.touched[idx]?.scheduledSlot || !!formik.submitCount) &&
          formik.errors[idx]?.scheduledSlot
            ? formik.errors[idx]?.scheduledSlot
            : null;

        return (
          <Box
            key={statusChange.submissionId}
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Typography variant="h2">
              {SubmissionStatus.Rejected === statusChange.status
                ? 'Reject submission'
                : 'Set interview scheduled date'}
            </Typography>
            {statusChange.positions?.map((position) => (
              <Typography variant="body1" flex={'1 1 auto'} key={position.id}>
                <Box component={'span'} color={'text.secondary'}>
                  #{position?.jobId}
                </Box>{' '}
                {position?.client}: {position?.name}
              </Typography>
            ))}
            {SubmissionStatus.Rejected === statusChange.status && (
              <AutocompleteTextfield
                name="closingReason"
                label="Closing reason"
                value={statusChange.closingReason}
                options={SubmissionClosingReasons}
                required
                onBlur={() => formik.setFieldTouched(`[${idx}].closingReason`)}
                fullWidth
                freeSolo
                filterSelectedOptions
                clearOnBlur
                selectOnFocus
                error={!!closingReasonError}
                helperText={closingReasonError || undefined}
                filterOptions={(options, params) => {
                  const filtered = createFilterOptions<string>()(
                    options,
                    params,
                  );

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option,
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push(`Add "${inputValue}"`);
                  }

                  return filtered;
                }}
                onChange={(e: SyntheticEvent, newValue: string | null) => {
                  const nextClosingReason = newValue?.includes('Add "')
                    ? /Add "(.*)"/gi.exec(newValue)?.[1]
                    : newValue;

                  formik.setFieldValue(
                    `[${idx}].closingReason`,
                    nextClosingReason,
                  );
                }}
              />
            )}
            {SubmissionStatus.CallScheduled === statusChange.status && (
              <Flex flexDirection="column" alignItems="start" gap="0.5rem">
                <DateTimeRangePicker
                  date={statusChange.scheduledSlot || undefined}
                  handleChange={(date) =>
                    formik.setFieldValue(`[${idx}].scheduledSlot`, date)
                  }
                  flex={1}
                />
                <Stack flex={1} justifyContent="center">
                  <Typography fontSize="0.75rem" color="text.secondary">
                    Client time (UTC {statusChange.clientTimezone})
                  </Typography>
                  <Typography variant="body1">
                    {statusChange.scheduledSlot?.start
                      ? `${displayDateTimeInTimezone(
                          statusChange.scheduledSlot?.start,
                          statusChange.clientTimezone!,
                        ).format(
                          'ddd, MMM DD, hh:mm a',
                        )} - ${displayDateTimeInTimezone(
                          statusChange.scheduledSlot?.end,
                          statusChange.clientTimezone!,
                        ).format('hh:mm a')}`
                      : '—'}
                  </Typography>
                </Stack>
                {scheduledSlotError && (
                  <Typography variant="subtitle2" color="text.danger">
                    {scheduledSlotError}
                  </Typography>
                )}
              </Flex>
            )}
          </Box>
        );
      })}
    </Box>
  );
});
