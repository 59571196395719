import React, { ReactNode } from 'react';

import {
  Box,
  Skeleton,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StageButton } from '@components/common';

export const CandidateStages: React.FC<{
  loading: boolean;
  stageHeaderTitle?: string;
  stages: {
    label: string;
    value: string;
    amount: number;
    amountIcon?: ReactNode;
  }[];
  activeStage: string | null;
  onClick: (value: string | null) => void;
}> = ({ loading, activeStage, stages, onClick, stageHeaderTitle }) => {
  return (
    <Box>
      {stageHeaderTitle && (
        <Typography variant="h4" sx={{ marginBottom: 1, marginLeft: 1 }}>
          {stageHeaderTitle}
        </Typography>
      )}

      <TableHead>
        <TableRow>
          {stages.map((stage) => (
            <TableCell
              key={`head-${stage.value}`}
              sx={(theme) => ({
                borderBottom: 'none',
                textAlign: 'flex-start',
                padding: '8px',
                background: theme.palette.highlight.neutral,
                textWrap: 'nowrap',
                '&:first-of-type': {
                  borderRadius: '8px 0 0 8px',
                },
                '&:last-of-type': {
                  borderRadius: '0 8px 8px 0',
                },
              })}
            >
              {stage.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableRow>
        {stages.map((stage) => (
          <TableCell
            key={`body-${stage.value}`}
            sx={{ borderBottom: 'none', padding: 0 }}
          >
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={'37px'}
                width="100%"
                sx={{
                  borderRadius: '8px',
                  marginTop: 0.5,
                  border: '2px solid white',
                }}
              />
            ) : (
              <StageButton
                amount={stage.amount ? stage.amount : '─'}
                amountIcon={stage.amount ? stage.amountIcon : ''}
                active={activeStage === stage.value}
                onClick={() =>
                  onClick(activeStage === stage.value ? null : stage.value)
                }
                disabled={!stage.amount}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </Box>
  );
};
