import { useCallback, useEffect, useState } from 'react';

import { Flex } from '@components';
import { ReportIssueForm } from './components/ReportIssueForm';
import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const ReportIssue: React.FC<{
  isReportModalOpen?: boolean;
  setReportModalState?: (modalState: boolean) => void;
}> = ({ isReportModalOpen, setReportModalState }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onModalOpen = useCallback(() => setIsModalOpen(true), []);
  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
    if (setReportModalState) {
      setReportModalState(false);
    }
  }, []);

  useEffect(() => {
    if (isReportModalOpen) {
      onModalOpen();
    }
  }, [isReportModalOpen]);

  return (
    <>
      <Modal open={isModalOpen} onClose={onModalClose}>
        <Box
          sx={{ width: '100%', height: '100%' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            sx={{
              maxWidth: '511px',
              background: '#FFF',
              borderRadius: '4px',
              boxShadow:
                '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)',
            }}
            px={3}
            py={2}
            gap={3}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Typography variant="h2">Send feedback</Typography>
              <CloseIcon
                onClick={onModalClose}
                sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
              />
            </Flex>
            <ReportIssueForm onClose={onModalClose} />
          </Flex>
        </Box>
      </Modal>
    </>
  );
};
