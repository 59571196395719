import {
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';

import { STATIC_FILES_BASE_ENDPOINT } from 'constants/apiEndpoints';

export const AEResumeParsingInProgress: React.FC<{
  isOpen: boolean;
  onModalClose: () => void;
}> = ({ isOpen, onModalClose }) => {
  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">AgileEngine resume</Typography>
          <CloseIcon
            onClick={onModalClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3} alignItems="center">
          <img
            src={`${STATIC_FILES_BASE_ENDPOINT}/projects-images/file-text.png`}
            alt="file-text"
            width="250px"
            height="250px"
          />
          <Typography variant="h2">
            Parsing candidate’s original resume
          </Typography>
          <Typography variant="body1" color="text.secondary">
            It can take a couple of minutes
          </Typography>
          <CircularProgress
            sx={(theme) => ({ color: theme.palette.brand.accent })}
            size={24}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
