import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { isArray, isString, keyBy } from 'lodash';

import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AutocompleteTextfield, Flex, TextButton } from '@components';
import { EntityReportFormFilterColumnValue } from './components/FilterValue';

import {
  fetchEntityReportColumns,
  optionsSelectors,
  requestCandidateFieldsFetch,
  requestJobOpeningFieldsFetch,
} from '@redux/options';

import {
  CandidateFieldsOptions,
  EntityReportFilter,
  EntityReportFormik,
  JobOpeningsFieldsOptions,
} from '@types';
import { isStatusIdle } from '@utils';
import {
  EntityReportFilterOperator,
  EntityReportFilterOperatorByColumnType,
  EntityReportFilterOperatorLabel,
  EntityReportSource,
} from '@constants';

export const EntityReportFormFilterStep: React.FC<{
  formik: EntityReportFormik;
}> = ({ formik }) => {
  const dispatch = useAppDispatch();

  const { apiStatus: columnsAPIStatus, data: columns } = useAppSelector(
    optionsSelectors.getEntityReportColumnsAPIData,
  );
  const {
    apiStatus: candidateFieldValuesAPIStatus,
    data: candidateFieldValues,
  } = useAppSelector(optionsSelectors.getCandidateFieldsOptions);
  const { apiStatus: joFieldValuesAPIStatus, data: joFieldValues } =
    useAppSelector(optionsSelectors.getJobOpeningFieldsOptions);

  useEffect(() => {
    if (isStatusIdle(columnsAPIStatus)) {
      dispatch(fetchEntityReportColumns());
    }
    if (isStatusIdle(candidateFieldValuesAPIStatus)) {
      dispatch(requestCandidateFieldsFetch());
    }
    if (isStatusIdle(joFieldValuesAPIStatus)) {
      dispatch(requestJobOpeningFieldsFetch());
    }
  }, []);

  const filtersError =
    (formik.touched.filters || !!formik.submitCount) && formik.errors.filters
      ? formik.errors.filters
      : null;

  const columnOptions = useMemo(
    () =>
      columns
        .filter(
          (e) =>
            e.source === formik.values.source! &&
            formik.values.columns?.includes(e.dwhField),
        )
        .map((e) => e.dwhField),
    [formik.values.columns],
  );
  const columnsByDWHFiled = useMemo(
    () => keyBy(columns, 'dwhField'),
    [columns],
  );

  const onAddNewFilterClick = useCallback(
    () =>
      formik.setFieldValue('filters', [
        ...(formik.values.filters || []),
        { field: null, operator: null, value: null },
      ]),
    [formik.values.filters],
  );
  const onChange = useCallback(
    (changeIdx: number, change: Partial<EntityReportFilter>) => {
      formik.setFieldValue(
        'filters',
        formik.values.filters?.map((e, idx) =>
          idx === changeIdx ? { ...e, ...change } : e,
        ),
      );
    },
    [formik.values.filters],
  );
  const onRemoveFilterClick = useCallback(
    (filterIdx: number) => {
      const nextValue = [...(formik.values.filters || [])];

      nextValue.splice(filterIdx, 1);

      formik.setFieldValue('filters', nextValue);
    },
    [formik.values.filters],
  );

  const fieldValues =
    formik.values.source === EntityReportSource.Candidates
      ? candidateFieldValues
      : joFieldValues;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      pt={5}
      pb={2}
      width={700}
    >
      <Box>
        <Typography variant="h3">Apply filters</Typography>
        {isString(filtersError) && (
          <Typography variant="subtitle2" color="text.danger">
            {filtersError}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          background: '#F6F8FC',
          borderRadius: '8px',
          padding: '8px',
        }}
        flex={1}
        width="100%"
      >
        <Flex
          sx={{ background: '#FFF', borderRadius: '2px' }}
          p={3}
          height="100%"
          flexDirection="column"
          gap={3}
          alignItems="start"
        >
          {formik.values.filters?.map((filter, idx) => {
            const selectedColumn = columns.find(
              (column) => column.dwhField === filter.field,
            );
            const errors = isArray(filtersError)
              ? (
                  filtersError as Array<
                    | { field?: string; operator?: string; value?: string }
                    | undefined
                  >
                )[idx]
              : null;
            const currentError =
              errors?.field || errors?.operator || errors?.value;

            return (
              <Flex flexDirection="column" alignItems="start" key={idx}>
                <Flex gap={1}>
                  <Typography variant="caption" color="secondary" width="2%">
                    {idx + 1}.
                  </Typography>
                  <Box width="30%">
                    <AutocompleteTextfield
                      label="Column"
                      getOptionLabel={(option) =>
                        columnsByDWHFiled[option]?.label || option
                      }
                      options={columnOptions || []}
                      value={filter.field}
                      onChange={(_, val) =>
                        onChange(idx, {
                          field: val,
                          operator: null,
                          value: null,
                        })
                      }
                      fullWidth
                      required
                    />
                  </Box>
                  <Box width="20%">
                    {!!selectedColumn && (
                      <Select
                        value={filter.operator || ''}
                        label="Operator"
                        fullWidth
                        onChange={(e: SelectChangeEvent<string>) =>
                          onChange(idx, {
                            operator:
                              (e.target.value as EntityReportFilterOperator) ||
                              null,
                            value: null,
                          })
                        }
                      >
                        {selectedColumn?.type
                          ? EntityReportFilterOperatorByColumnType[
                              selectedColumn?.type
                            ].map((item) => (
                              <MenuItem value={item} key={item}>
                                {EntityReportFilterOperatorLabel[item]}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    )}
                  </Box>
                  <Box width="40%">
                    <EntityReportFormFilterColumnValue
                      columnType={selectedColumn?.type || null}
                      operator={filter.operator}
                      value={filter.value}
                      options={
                        selectedColumn?.zohoField && fieldValues
                          ? fieldValues[
                              selectedColumn.zohoField as keyof (
                                | JobOpeningsFieldsOptions
                                | CandidateFieldsOptions
                              )
                            ] || []
                          : []
                      }
                      onChange={(e) =>
                        onChange(idx, {
                          value: e || null,
                        })
                      }
                    />
                  </Box>
                  <Box width="3%">
                    <IconButton
                      onClick={() => onRemoveFilterClick(idx)}
                      size="small"
                    >
                      <DeleteOutlineIcon color="secondary" fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Flex>
                {!!currentError && (
                  <Typography variant="subtitle2" color="text.danger">
                    {currentError}
                  </Typography>
                )}
              </Flex>
            );
          })}
          <TextButton
            onClick={onAddNewFilterClick}
            variant="secondary"
            sx={{ fontSize: '13px', ml: '3%' }}
            startIcon={<AddBoxOutlinedIcon fontSize="small" />}
          >
            Add new
          </TextButton>
        </Flex>
      </Box>
    </Box>
  );
};
