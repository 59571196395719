import { useCallback } from 'react';
import dayjs from 'dayjs';

import { SectionLayout } from '../SectionLayout';
import { Box, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { AEResumeFormFields, IAEResumeCertification } from '@types';

export const AEResumeCertificationSection: React.FC<{
  value: AEResumeFormFields['certifications'];
  disabled: boolean;
  touched: any | undefined;
  errors: any | undefined;
  isSubmitted: boolean;
  onChange: (value: AEResumeFormFields['certifications']) => void;
  onTouchField: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean,
  ) => void;
}> = ({
  value,
  disabled,
  touched,
  errors,
  isSubmitted,
  onChange,
  onTouchField,
}) => {
  const onAddClick = useCallback(
    () =>
      onChange([
        ...(value || []),
        {
          date: null,
          name: null,
          description: null,
        },
      ]),
    [value],
  );

  const onDeleteClick = useCallback(
    (removeIdx: number) =>
      onChange(value?.filter((_, idx) => idx !== removeIdx) || null),
    [value],
  );

  const onEditField = useCallback(
    (
      field: keyof IAEResumeCertification,
      editIdx: number,
      editedValue: string,
    ) =>
      onChange(
        value?.map((e, idx) =>
          idx === editIdx ? { ...e, [field]: editedValue || null } : e,
        ) || null,
      ),
    [value],
  );

  return (
    <SectionLayout
      title="Certifications"
      items={
        value?.map((item, idx) => {
          const nameError =
            (touched?.[idx]?.name || isSubmitted) && errors?.[idx]?.name
              ? errors[idx]?.name
              : null;
          const dateError =
            (touched?.[idx]?.date || isSubmitted) && errors?.[idx]?.date
              ? errors[idx]?.date
              : null;
          const descriptionError =
            (touched?.[idx]?.description || isSubmitted) &&
            errors?.[idx]?.description
              ? errors[idx]?.description
              : null;

          return (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" gap={1} alignItems="start">
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={item.name || ''}
                  required
                  onChange={(e) => onEditField('name', idx, e.target.value)}
                  onBlur={() => onTouchField(`certifications[${idx}].name`)}
                  disabled={disabled}
                  helperText={nameError || undefined}
                  error={!!nameError}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#FFF',
                    },
                  }}
                />
                <DatePicker
                  label="Date"
                  openTo="year"
                  views={['year']}
                  value={item.date ? dayjs(item.date) : null}
                  onChange={(newValue) => {
                    onEditField('date', idx, newValue?.format('YYYY') || '');
                  }}
                  disabled={disabled}
                  slotProps={{
                    textField: {
                      helperText: dateError,
                      error: !!dateError,
                      sx: {
                        '& .MuiInputBase-root': {
                          backgroundColor: '#FFF',
                        },
                      },
                    },
                  }}
                />
                <IconButton
                  onClick={() => onDeleteClick(idx)}
                  sx={{ marginTop: '8px' }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  value={item.description || ''}
                  onChange={(e) =>
                    onEditField('description', idx, e.target.value)
                  }
                  onBlur={() =>
                    onTouchField(`certifications[${idx}].description`)
                  }
                  disabled={disabled}
                  helperText={descriptionError || undefined}
                  error={!!descriptionError}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#FFF',
                    },
                  }}
                />
              </Box>
            </Box>
          );
        }) || []
      }
      addLabel="Add certification"
      onAddClick={onAddClick}
    />
  );
};
