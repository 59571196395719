import { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';

import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { DatePicker } from '@mui/x-date-pickers';
import { TextButton, AutocompleteTextfield } from '@components';
import { SkillsField } from './customFields/SkillsField';
import { TILinkField } from './customFields/TILinkField';

import { optionsSelectors, requestCandidateFieldsFetch } from '@redux/options';

import {
  AutocompleteSingleselectCandidateFields,
  AutocompltereMultiselectCandidateFields,
  CandidateFieldsLabels,
  CheckboxCandidateFields,
  DateCandidateFields,
  NumberfieldCandidateFields,
  TextareaCandidateFields,
  TextfieldCandidateFields,
} from '@constants';
import { CandidateFields } from '@types';

export const CandidateField: React.FC<{
  field: keyof CandidateFields;
  name?: string;
  value: any;
  required?: boolean;
  touched?: boolean;
  disabled?: boolean;
  isProtected?: boolean;
  error?: string;
  endAdornment?: ReactNode;
  componentProps?: {
    candidateId?: string | null;
  };
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({
  field,
  name,
  value,
  required,
  touched,
  disabled,
  isProtected,
  error,
  endAdornment,
  componentProps,
  onChange,
  onBlur,
}) => {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector(optionsSelectors.getCandidateFieldsOptions);

  const [isEditable, setIsEditable] = useState(!isProtected);

  useEffect(() => {
    if (
      [
        ...AutocompleteSingleselectCandidateFields,
        ...AutocompltereMultiselectCandidateFields,
      ].includes(field)
    ) {
      dispatch(requestCandidateFieldsFetch());
    }
  }, []);

  useEffect(() => {
    const isPipelineField = [
      'Hot_Pipeline_End_Date',
      'Hot_Pipeline_Start_Date',
      'Date_of_Submission_Reply',
    ].includes(field);
    const today = dayjs(new Date());

    if (isPipelineField && !value) {
      onChange(today.format('YYYY-MM-DD'));
    }
  }, []);

  const label = CandidateFieldsLabels[field];
  const options = data?.[field] || [];

  if (field === 'Skill_Set') {
    return (
      <SkillsField
        name={name || (field as unknown as string)}
        options={options}
        value={value}
        error={error}
        touched={touched}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }

  if (field === 'tiLink') {
    return (
      <TILinkField
        name={name || (field as unknown as string)}
        label={label}
        value={value}
        required={required}
        touched={touched}
        error={error}
        disabled={disabled || !isEditable}
        candidateId={componentProps?.candidateId}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }

  if (TextfieldCandidateFields.includes(field)) {
    return (
      <TextField
        name={name || (field as unknown as string)}
        label={label}
        variant="outlined"
        fullWidth
        value={value || ''}
        required={required}
        onChange={(e) => onChange(e.target.value || null)}
        onBlur={onBlur}
        helperText={touched ? error : undefined}
        error={touched && !!error}
        disabled={disabled || !isEditable}
        InputProps={{
          endAdornment: endAdornment ? (
            endAdornment
          ) : isProtected && !isEditable ? (
            <InputAdornment position="end">
              <ModeEditIcon
                onClick={() => setIsEditable(true)}
                sx={{ cursor: 'pointer', color: 'text.link' }}
              />
            </InputAdornment>
          ) : null,
        }}
      />
    );
  }

  if (TextareaCandidateFields.includes(field)) {
    return (
      <TextField
        name={name || (field as unknown as string)}
        label={label}
        variant="outlined"
        fullWidth
        value={value || ''}
        required={required}
        onChange={(e) => onChange(e.target.value || null)}
        onBlur={onBlur}
        helperText={touched ? error : undefined}
        error={touched && !!error}
        disabled={disabled}
        multiline
        minRows={3}
      />
    );
  }

  if (NumberfieldCandidateFields.includes(field)) {
    return (
      <TextField
        name={name || (field as unknown as string)}
        label={label}
        variant="outlined"
        type="number"
        fullWidth
        required={required}
        value={isNumber(value) ? value : ''}
        onChange={(e) =>
          onChange(e.target.value ? Number(e.target.value) : null)
        }
        onBlur={onBlur}
        helperText={touched ? error : undefined}
        error={touched && !!error}
        disabled={disabled}
      />
    );
  }

  if (AutocompleteSingleselectCandidateFields.includes(field)) {
    return (
      <AutocompleteTextfield
        name={name || (field as unknown as string)}
        label={label}
        options={options}
        value={value}
        required={required}
        onChange={(_, val) => onChange(val || null)}
        onBlur={onBlur}
        fullWidth
        error={touched && !!error}
        helperText={touched ? error : undefined}
        disabled={disabled}
      />
    );
  }

  if (AutocompltereMultiselectCandidateFields.includes(field)) {
    return (
      <AutocompleteTextfield
        name={name || (field as unknown as string)}
        label={label}
        options={options}
        value={value || []}
        required={required}
        onChange={(_, val) => onChange(val.length ? val : null)}
        onBlur={onBlur}
        fullWidth
        multiple
        filterSelectedOptions
        error={touched && !!error}
        helperText={touched ? error : undefined}
        disabled={disabled}
      />
    );
  }

  if (DateCandidateFields.includes(field)) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" gap={2}>
          <DatePicker
            label={
              <>
                {label} {!!required && <sup>*</sup>}
              </>
            }
            value={value ? dayjs(value) : null}
            sx={{ width: '100%' }}
            onChange={(newValue) => {
              onChange(newValue?.format('YYYY-MM-DD') || null);
            }}
            disabled={disabled}
          />

          <TextButton
            onClick={() => onChange(format(new Date(), 'yyyy-MM-dd'))}
            variant="primary"
            underlined
            sx={{ fontSize: '14px' }}
            disabled={disabled}
          >
            Today
          </TextButton>
        </Box>
        {touched && !!error && (
          <Typography
            variant="body2"
            color="text.danger"
            sx={{ marginLeft: '14px', marginTop: '3px' }}
          >
            {error}
          </Typography>
        )}
      </Box>
    );
  }

  if (CheckboxCandidateFields.includes(field)) {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={value} onChange={(_, val) => onChange(val)} />
        }
        label={label}
        disabled={disabled}
      />
    );
  }

  return null;
};
