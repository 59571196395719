import { useCallback } from 'react';
import dayjs from 'dayjs';

import { SectionLayout } from '../SectionLayout';
import {
  Box,
  createFilterOptions,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { AEResumeFormFields, IAEResumeEmploymentHistory } from '@types';
import { AutocompleteTextfield, TextButton } from '@components';
import { isTruthy } from '@utils';
import { isArray, isString } from 'lodash';

export const AEResumeEmploymentHistorySection: React.FC<{
  value: AEResumeFormFields['employmentHistory'];
  disabled: boolean;
  touched: any;
  errors: any;
  isSubmitted: boolean;
  allSkills: string[];
  onChange: (value: AEResumeFormFields['employmentHistory']) => void;
  onTouchField: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean,
  ) => void;
}> = ({
  value,
  disabled,
  touched,
  errors,
  isSubmitted,
  allSkills,
  onChange,
  onTouchField,
}) => {
  const onAddClick = useCallback(
    () =>
      onChange([
        ...(value || []),
        {
          from: null,
          to: null,
          company: null,
          position: null,
          summary: null,
          responsibilities: null,
          technologies: null,
        },
      ]),
    [value],
  );

  const onDeleteClick = useCallback(
    (removeIdx: number) =>
      onChange(value?.filter((_, idx) => idx !== removeIdx) || null),
    [value],
  );

  const onEditField = useCallback(
    (
      field: keyof IAEResumeEmploymentHistory,
      editIdx: number,
      editedValue: string | null | (string | null)[],
    ) =>
      onChange(
        value?.map((e, idx) =>
          idx === editIdx
            ? { ...e, [field]: editedValue?.length ? editedValue : null }
            : e,
        ) || null,
      ),
    [value],
  );

  return (
    <SectionLayout
      title="Employment history"
      items={
        value?.map((item, idx) => {
          const companyError =
            (touched?.[idx]?.company || isSubmitted) && errors?.[idx]?.company
              ? errors[idx]?.company
              : null;
          const fromDateError =
            (touched?.[idx]?.from || isSubmitted) && errors?.[idx]?.from
              ? errors[idx]?.from
              : null;
          const toDateError =
            (touched?.[idx]?.to || isSubmitted) && errors?.[idx]?.to
              ? errors[idx]?.to
              : null;
          const positionError =
            (touched?.[idx]?.position || isSubmitted) && errors?.[idx]?.position
              ? errors[idx]?.position
              : null;
          const summaryError =
            (touched?.[idx]?.summary || isSubmitted) && errors?.[idx]?.summary
              ? errors[idx]?.summary
              : null;
          const responsibilityError =
            (touched?.[idx]?.responsibilities || isSubmitted) &&
            errors?.[idx]?.responsibilities
              ? errors[idx]?.responsibilities
              : null;
          const technologyError =
            (touched?.[idx]?.technologies || isSubmitted) &&
            errors?.[idx]?.technologies
              ? isArray(errors[idx]?.technologies)
                ? errors[idx]?.technologies[0]
                : errors[idx]?.technologies
              : null;

          return (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" gap={1} alignItems="start">
                <TextField
                  label="Company name"
                  variant="outlined"
                  fullWidth
                  value={item.company || ''}
                  required
                  onChange={(e) => onEditField('company', idx, e.target.value)}
                  onBlur={() =>
                    onTouchField(`employmentHistory[${idx}].company`)
                  }
                  disabled={disabled}
                  helperText={companyError || undefined}
                  error={!!companyError}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#FFF',
                    },
                  }}
                />
                <DatePicker
                  label="From"
                  openTo="year"
                  views={['year']}
                  value={item.from ? dayjs(item.from) : null}
                  onChange={(newValue) => {
                    onEditField('from', idx, newValue?.format('YYYY') || '');
                  }}
                  disabled={disabled}
                  slotProps={{
                    textField: {
                      required: true,
                      helperText: fromDateError,
                      error: !!fromDateError,
                      sx: {
                        '& .MuiInputBase-root': {
                          backgroundColor: '#FFF',
                        },
                      },
                    },
                  }}
                />
                <DatePicker
                  label="To"
                  openTo="year"
                  views={['year']}
                  value={item.to ? dayjs(item.to) : null}
                  onChange={(newValue) => {
                    onEditField('to', idx, newValue?.format('YYYY') || '');
                  }}
                  disabled={disabled}
                  slotProps={{
                    textField: {
                      helperText: toDateError,
                      error: !!toDateError,
                      sx: {
                        '& .MuiInputBase-root': {
                          backgroundColor: '#FFF',
                        },
                      },
                    },
                  }}
                />
                <IconButton
                  onClick={() => onDeleteClick(idx)}
                  sx={{ marginTop: '8px' }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Box>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                value={item.position || ''}
                required
                onChange={(e) => onEditField('position', idx, e.target.value)}
                onBlur={() =>
                  onTouchField(`employmentHistory[${idx}].position`)
                }
                disabled={disabled}
                helperText={positionError || undefined}
                error={!!positionError}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#FFF',
                  },
                }}
              />
              <TextField
                label="Summary"
                variant="outlined"
                fullWidth
                value={item.summary || ''}
                onChange={(e) => onEditField('summary', idx, e.target.value)}
                onBlur={() => onTouchField(`employmentHistory[${idx}].summary`)}
                disabled={disabled}
                multiline
                minRows={3}
                helperText={summaryError || undefined}
                error={!!summaryError}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#FFF',
                  },
                }}
              />
              <Box>
                <Typography variant="subtitle1">
                  Activities and responsibilities
                </Typography>
                {isString(responsibilityError) && (
                  <Typography variant="body2" color="text.danger">
                    {responsibilityError}
                  </Typography>
                )}
                {item.responsibilities?.map((responsibility, itemIdx) => (
                  <Box display="flex" gap={1} key={itemIdx} alignItems="start">
                    <TextField
                      variant="standard"
                      fullWidth
                      value={responsibility || ''}
                      required
                      multiline
                      onChange={(e) =>
                        onEditField(
                          'responsibilities',
                          idx,
                          item.responsibilities?.map((r, rIdx) =>
                            rIdx === itemIdx ? e.target.value || null : r,
                          ) || null,
                        )
                      }
                      onBlur={() =>
                        onTouchField(
                          `employmentHistory[${idx}].responsibilities[${itemIdx}]`,
                        )
                      }
                      disabled={disabled}
                      helperText={
                        isArray(responsibilityError) &&
                        responsibilityError[itemIdx]
                          ? responsibilityError[itemIdx] || undefined
                          : undefined
                      }
                      error={
                        !!isArray(responsibilityError) &&
                        !!responsibilityError[itemIdx]
                      }
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: '#FFF',
                        },
                      }}
                    />
                    <IconButton
                      onClick={() =>
                        onEditField(
                          'responsibilities',
                          idx,
                          item.responsibilities?.filter(
                            (_, rIdx) => rIdx !== itemIdx,
                          ) || null,
                        )
                      }
                      size="small"
                    >
                      <ClearOutlinedIcon />
                    </IconButton>
                  </Box>
                ))}
                <TextButton
                  variant="secondary"
                  onClick={() =>
                    onEditField('responsibilities', idx, [
                      ...(item.responsibilities || []),
                      null,
                    ])
                  }
                  startIcon={<AddOutlinedIcon fontSize="small" />}
                  sx={{ fontSize: '12px' }}
                >
                  Add activity/responsibility
                </TextButton>
              </Box>
              <AutocompleteTextfield
                label="Technologies"
                options={allSkills}
                value={item.technologies || []}
                fullWidth
                multiple
                freeSolo
                filterSelectedOptions
                clearOnBlur
                selectOnFocus
                disabled={disabled}
                filterOptions={(options, params) => {
                  const filtered = createFilterOptions<string>()(
                    options,
                    params,
                  );

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option,
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push(`Add "${inputValue}"`);
                  }

                  return filtered;
                }}
                onChange={(_, newValue: string[] | null) => {
                  const newSkillNameValue =
                    newValue
                      ?.map((value) =>
                        value?.includes('Add "')
                          ? /Add "(.*)"/gi.exec(value)?.[1]
                          : value,
                      )
                      .filter(isTruthy) || null;

                  onEditField('technologies', idx, newSkillNameValue || null);
                }}
                helperText={technologyError || undefined}
                error={!!technologyError}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#FFF',
                  },
                }}
              />
            </Box>
          );
        }) || []
      }
      addLabel="Add employment record"
      onAddClick={onAddClick}
    />
  );
};
