import { useCallback, useMemo, useState } from 'react';
import { mapValues, values } from 'lodash';
import _ from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { RowLayout, CandidateField } from '@components';

import { transormCandidateStatus, getStatusFormData } from '@utils';
import {
  CandidateAutoupdatedFields,
  CandidateStatusFormik,
  IZohoCandidate,
} from '@types';
import {
  CandidateFieldsLabels,
  CandidateStatus,
  CandidateStatusStage,
  RequiredCandidateStatusFieldsByStatus,
} from '@constants';

export const FormLayout: React.FC<{
  stage: CandidateStatusStage;
  status: CandidateStatus;
  formik: CandidateStatusFormik;
  candidateDetails: IZohoCandidate;
}> = ({ stage, status, formik, candidateDetails }) => {
  const { formFieldsNames, autoupdatedFields } = getStatusFormData(
    status,
    stage,
    formik.values,
    candidateDetails,
  );

  const hasContent = !!formFieldsNames || !!autoupdatedFields;

  const [isExpanded, setIsExpanded] = useState(hasContent);

  const isExcluded = !!formik.values.excludedTransitions.find(
    (transition) => transition.stage === stage && transition.status === status,
  );

  const onAddRemoveSection = useCallback(
    (e: any) => {
      e.stopPropagation();
      formik.setFieldValue(
        'excludedTransitions',
        !isExcluded
          ? [...formik.values.excludedTransitions, { stage, status }]
          : formik.values.excludedTransitions.filter(
              (transition) =>
                !(transition.stage === stage && transition.status === status),
            ),
      );
    },
    [formik.values.excludedTransitions, isExcluded, status, stage],
  );

  const showError = useMemo(
    () =>
      !!formFieldsNames &&
      !!formik.submitCount &&
      formFieldsNames.some((e) => formik.errors[e]),
    [formik.errors, formik.submitCount, formFieldsNames],
  );

  return (
    <Box my={1} width="100%">
      <Accordion
        sx={{ width: '100%' }}
        expanded={isExpanded && !isExcluded}
        onChange={(_, newVal) =>
          !isExcluded && hasContent && setIsExpanded(newVal)
        }
      >
        <AccordionSummary
          expandIcon={
            <ExpandLessIcon
              sx={{
                color: isExcluded ? 'text.secondary' : 'text.link',
                visibility: hasContent ? 'visible' : 'hidden',
              }}
            />
          }
        >
          <Box
            display="flex"
            gap={2}
            pr={2}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex" gap={1} alignItems="center">
              {showError && <ReportProblemIcon sx={{ color: 'text.danger' }} />}
              <Typography
                variant="h3"
                color={
                  showError
                    ? 'text.danger'
                    : isExcluded
                    ? 'text.secondary'
                    : 'text.primary'
                }
              >
                {stage}: {transormCandidateStatus(status)}
              </Typography>
            </Box>

            <Button
              variant={isExcluded ? 'contained' : 'outlined'}
              onClick={onAddRemoveSection}
              color="primary"
              size="small"
            >
              {isExcluded ? (
                <>
                  <AddCircleOutlineIcon
                    sx={{ marginRight: 1 }}
                    fontSize="small"
                  />{' '}
                  Include
                </>
              ) : (
                <>
                  <RemoveCircleOutlineIcon
                    sx={{ marginRight: 1 }}
                    fontSize="small"
                  />{' '}
                  Exclude
                </>
              )}
            </Button>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Box display="flex" flexDirection="column" gap={2}>
            {!!formFieldsNames &&
              formFieldsNames.map((fieldName) => {
                const isRequired =
                  RequiredCandidateStatusFieldsByStatus[fieldName]?.some(
                    (transition) =>
                      transition.stage === stage &&
                      transition.status === status,
                  ) || false;

                return (
                  <CandidateField
                    key={fieldName}
                    field={fieldName}
                    value={formik.values[fieldName]}
                    required={isRequired}
                    touched={formik.touched[fieldName]}
                    error={formik.errors[fieldName]}
                    onChange={(value) => formik.setFieldValue(fieldName, value)}
                    onBlur={() => formik.setFieldTouched(fieldName)}
                    componentProps={{ candidateId: candidateDetails.id }}
                  />
                );
              })}

            {!!autoupdatedFields && (
              <Accordion
                sx={{
                  width: '100%',
                  boxShadow: 'none',
                  '&:before': { height: 0 },
                  '&.Mui-expanded': { marginTop: 0 },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandLessIcon sx={{ color: 'text.link' }} />}
                  sx={(theme) => ({
                    background: theme.palette.highlight.section,
                    '&.Mui-expanded': { minHeight: 'auto' },
                    '.MuiAccordionSummary-content.Mui-expanded': {
                      margin: '12px 0',
                    },
                  })}
                >
                  <Typography variant="subtitle1" color="text.link">
                    Autoupdated fields
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column">
                    {values(
                      mapValues(
                        autoupdatedFields,
                        (
                          value: string | string[],
                          key: keyof CandidateAutoupdatedFields,
                        ) => {
                          return (
                            <RowLayout
                              key={key}
                              spaceBetween
                              label={CandidateFieldsLabels[key] || ''}
                              Component={
                                <Typography variant="body1">
                                  {_.isBoolean(value)
                                    ? value
                                      ? 'true'
                                      : 'false'
                                    : _.isEmpty(value)
                                    ? 'EMPTY'
                                    : _.isString(value)
                                    ? value
                                    : _.isArray(value)
                                    ? value.join(', ')
                                    : ''}
                                </Typography>
                              }
                            />
                          );
                        },
                      ),
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
