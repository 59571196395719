import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useLocation } from 'react-router-dom';

import {
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import { ReactComponent as PostNoteIcon } from '@assets/post-note.svg';
import { CandidateNote } from './components/CandidateNote';
import { DeleteNoteConfirmationModal } from './components/DeleteNoteConfirmationModal';
import { LoaderSkeleton, ForbiddenView, CustomButton } from '@components';

import {
  candidateDetailsSelectors,
  fetchCandidateNotes,
} from '@redux/candidateDetails';
import {
  createNote,
  deleteNote,
  updateNote,
  notesSelectors,
} from '@redux/notes';
import { setSnackbar } from '@redux/snackbar';

import {
  AppRoutes,
  CandidateDetailsTabs,
  NoteObjectType,
  SnackbarType,
} from '@constants';
import { isStatusForbidden, isStatusIdle, isStatusLoading } from '@utils';

export const CandidateNotes: React.FC = () => {
  const { hash } = useLocation();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const commentRefs = useRef<Record<string, HTMLLIElement | null>>({});

  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const { candidateNotesAPIStatus, candidateNotes } = useAppSelector(
    candidateDetailsSelectors.getCandidateNotes,
  );
  const noteIsPerformingAction = useAppSelector(
    notesSelectors.getNoteIsPerformingAction,
  );

  const [note, setNote] = useState<string>('');
  const [deleteNoteId, setDeleteNoteId] = useState<string | null>(null);

  useEffect(() => {
    if (hash && !isStatusLoading(candidateNotesAPIStatus)) {
      const target = commentRefs.current[hash.replace('#', '')];

      if (target) {
        target.scrollIntoView({ behavior: 'smooth', block: 'center' });

        target.classList.add('highlight');

        setTimeout(() => {
          target.classList.remove('highlight');
        }, 3000);
      }
    }
  }, [candidateNotesAPIStatus]);

  useEffect(() => {
    if (isStatusIdle(candidateNotesAPIStatus) && candidateDetails?.id) {
      handleFetchCandidateNotes(candidateDetails.id);
    }
  }, [candidateNotesAPIStatus, candidateDetails?.id]);

  const handleFetchCandidateNotes = (candidateId: string) => {
    dispatch(fetchCandidateNotes(candidateId));
  };

  const handlePostNoteClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleNoteCreate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!candidateDetails?.id) return;

    const res = await dispatch(
      createNote({
        payload: {
          objectId: candidateDetails.id,
          objectType: NoteObjectType.CandidateNote,
          note,
        },
      }),
    );
    if (createNote.fulfilled.match(res)) {
      handleFetchCandidateNotes(candidateDetails?.id);
      setNote('');
    }
  };

  const handleDeleteNoteClick = useCallback(
    (noteId: string) => {
      setDeleteNoteId(noteId);
    },
    [candidateDetails],
  );

  const handleDeleteNote = useCallback(async () => {
    if (!deleteNoteId || !candidateDetails?.id) return;

    const res = await dispatch(
      deleteNote({
        noteId: deleteNoteId,
      }),
    );
    if (deleteNote.fulfilled.match(res)) {
      handleFetchCandidateNotes(candidateDetails.id);
      setDeleteNoteId(null);
    }
  }, [candidateDetails, deleteNoteId]);

  const handleEditNoteClick = useCallback(
    async (noteId: string, note: string) => {
      if (!candidateDetails?.id) return;

      const res = await dispatch(
        updateNote({
          noteId: noteId,
          payload: {
            note,
          },
        }),
      );

      if (updateNote.fulfilled.match(res)) {
        handleFetchCandidateNotes(candidateDetails.id);
      }
    },
    [candidateDetails],
  );

  const handleCopyLink = useCallback(
    (noteId: string) => {
      const link = `${window.location.origin}/${AppRoutes.CANDIDATES}/${candidateDetails?.id}/${CandidateDetailsTabs.NOTES}#${noteId}`;

      navigator.clipboard
        .writeText(link)
        .then(() => {
          dispatch(
            setSnackbar({
              type: SnackbarType.Info,
              message: 'Link copied to clipboard',
            }),
          );
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              type: SnackbarType.Error,
              message: 'Failed to copy link. Please try again.',
            }),
          );
        });
    },
    [candidateDetails],
  );

  if (isStatusLoading(candidateNotesAPIStatus)) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(candidateNotesAPIStatus)) {
    return <ForbiddenView />;
  }

  return (
    <>
      <DeleteNoteConfirmationModal
        isLoading={noteIsPerformingAction}
        isOpen={Boolean(deleteNoteId)}
        deleteNote={handleDeleteNote}
        onClose={() => setDeleteNoteId(null)}
      />
      <Stack my={'0.25rem'} gap={'1rem'}>
        <Stack
          gap={'0.5rem'}
          flexDirection={'row'}
          sx={{
            mx: {
              xs: '1rem',
              sm: '0.25rem',
            },
          }}
        >
          <Typography variant="h3" flex={1} my={'auto'}>
            {candidateNotes.length} Notes
          </Typography>
          <CustomButton
            color="secondary"
            label="Post a note"
            onClick={handlePostNoteClick}
            size="small"
            startIcon={<NoteOutlinedIcon />}
          />
        </Stack>
        <Stack
          sx={{
            backgroundColor: {
              xs: 'none',
              sm: 'background.backgroundPrimary',
            },
            borderRadius: '0.5rem',
            padding: {
              xs: '0',
              sm: '1rem',
            },
            mx: {
              xs: '0',
              sm: '-1rem',
            },
          }}
        >
          <List disablePadding sx={{ listStyleType: 'none' }}>
            {candidateNotes.map((note) => (
              <CandidateNote
                ref={(el) => (commentRefs.current[note.id] = el)}
                key={note.id}
                note={note}
                isLoading={noteIsPerformingAction}
                handleEditNoteClick={handleEditNoteClick}
                handleDeleteNoteClick={handleDeleteNoteClick}
                handleCopyLink={handleCopyLink}
              />
            ))}
          </List>
          {!candidateNotes.length && (
            <Typography textAlign="center" variant="h3" marginBottom={'1rem'}>
              No data
            </Typography>
          )}
          <form onSubmit={handleNoteCreate}>
            <TextField
              fullWidth
              required
              inputRef={inputRef}
              disabled={noteIsPerformingAction}
              name="note"
              multiline
              maxRows={4}
              variant="outlined"
              placeholder="Post a note..."
              value={note}
              onChange={(e) => setNote(e.target.value)}
              sx={(theme) => ({
                '& .MuiOutlinedInput-root': {
                  background: theme.palette.common.white,
                  '&.Mui-focused .MuiInputAdornment-root .MuiButtonBase-root': {
                    color: theme.palette.background.link,
                  },
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {noteIsPerformingAction ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton
                        type="submit"
                        aria-label={'post a note'}
                        edge="end"
                      >
                        <SvgIcon component={PostNoteIcon} color="inherit" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>
      </Stack>
    </>
  );
};
