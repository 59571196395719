import { ReactNode, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { NavDrawer } from '@components/NavDrawer/NavDrawer';

export const SideDrawerLayout = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
      })}
    >
      <NavDrawer toggleDrawer={toggleNavDrawer} isOpen={isOpen} />
      <Box component={'main'} sx={{ flex: '1 1 auto' }}>
        {children}
      </Box>
    </Box>
  );
};
