import { get, isArray, isEqual, set } from 'lodash';

import {
  EntityReportStep,
  EntityReportStepsOrdered,
  EntityReportType,
} from '@constants';
import {
  CreateEntityReportPayload,
  EntityReportFilter,
  EntityReportFormValues,
  IEntityReport,
  IEntityReportColumn,
  TransferListOption,
  UpdateEntityReportPayload,
} from '@types';

export const isEntityReportStepEqualOrHigherThan = (
  stepA: EntityReportStep,
  stepB: EntityReportStep,
): boolean =>
  EntityReportStepsOrdered.findIndex((e) => e === stepA) >=
  EntityReportStepsOrdered.findIndex((e) => e === stepB);

export const generateInitialValues = (entityReport: IEntityReport | null) => ({
  step: EntityReportStep.Type,
  name: entityReport?.name || null,
  folder: entityReport?.folder || null,
  description: entityReport?.description || null,
  type: entityReport?.type || null,
  source: entityReport?.source || null,
  groupBy: entityReport?.groupBy || null,
  sortBy: entityReport?.sortBy || null,
  columns: entityReport?.columns || null,
  filters: entityReport?.filters || null,
  shareWith: entityReport?.shareWith?.map((e) => e.email) || null,
});

export const getEntityReportsSteps = (type: EntityReportType | null) =>
  type === EntityReportType.Grouped
    ? EntityReportStepsOrdered
    : EntityReportStepsOrdered.filter((e) => e !== EntityReportStep.Grouping);

export const generateCreateEntityReportPayload = (
  values: EntityReportFormValues,
): CreateEntityReportPayload => ({
  folder: values.folder!,
  name: values.name!,
  description: values.description,
  type: values.type!,
  source: values.source!,
  groupBy: values.groupBy,
  sortBy: values.sortBy,
  columns: values.columns!,
  filters: values.filters!,
  shareWith: values.shareWith,
});

export const generateUpdateEntityReportPayload = (
  values: EntityReportFormValues,
  entityReport: IEntityReport,
): UpdateEntityReportPayload => {
  const initialValues = generateInitialValues(entityReport);
  const resultObject: Partial<EntityReportFormValues> = {};

  Object.entries(initialValues)?.map((entry) => {
    const [key, oldVal] = entry;
    const newVal = get(values, key);

    if (key === 'filters') {
      const newFilters = (newVal as EntityReportFilter[]).map(
        (e) => `${e.field}-${e.operator}-${JSON.stringify(e.value)}`,
      );
      const oldFilters = (oldVal as EntityReportFilter[]).map(
        (e) => `${e.field}-${e.operator}-${JSON.stringify(e.value)}`,
      );

      !isEqual([...newFilters].sort(), [...oldFilters].sort()) &&
        set(resultObject, key, newVal);
    } else if (isArray(newVal) && isArray(oldVal)) {
      !isEqual(newVal, oldVal) && set(resultObject, key, newVal);
    } else if (key !== 'step') {
      newVal !== oldVal && set(resultObject, key, newVal);
    }
  });

  return resultObject as UpdateEntityReportPayload;
};

export const transformToTransferListOption = (
  data: IEntityReportColumn[],
): TransferListOption[] =>
  data.map((e) => ({
    label: e.label,
    value: e.dwhField,
    group: e.group,
  }));
