import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { notesStoreKey } from './notes.const';
import { INotesSliceState } from './notes.types';
import { ApiStatus } from '@constants';
import { createNote, deleteNote, updateNote } from './notes.thunks';

const initialState: INotesSliceState = {
  apiStatus: ApiStatus.IDLE,

  isPerformingAction: false,
};

export const notesSlice = createSlice({
  name: notesStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(createNote.pending, updateNote.pending, deleteNote.pending),
        (state) => {
          state.isPerformingAction = true;
        },
      )
      .addMatcher(
        isAnyOf(
          createNote.fulfilled,
          updateNote.fulfilled,
          deleteNote.fulfilled,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      )
      .addMatcher(
        isAnyOf(createNote.rejected, updateNote.rejected, deleteNote.rejected),
        (state) => {
          state.isPerformingAction = false;
        },
      );
  },
});

export const notesSliceReducer = notesSlice.reducer;
