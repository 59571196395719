import * as yup from 'yup';

const MaxLengthErrorMessage = (maxLength: number) =>
  `Max length is ${maxLength} symbols`;

export const aeResumeGenerationValidationSchema = yup.object({
  name: yup
    .string()
    .max(255, MaxLengthErrorMessage(255))
    .required('Candidate name is required'),
  position: yup
    .string()
    .max(255, MaxLengthErrorMessage(255))
    .required('Candidate seniority and role is required'),
  summary: yup
    .string()
    .max(5000, MaxLengthErrorMessage(255))
    .required('Candidate professional summary is required'),
  educations: yup
    .array(
      yup.object({
        from: yup
          .string()
          .max(4, MaxLengthErrorMessage(4))
          .required('Required'),
        to: yup.string().max(4, MaxLengthErrorMessage(4)).optional().nullable(),
        institution: yup
          .string()
          .max(255, MaxLengthErrorMessage(255))
          .required('Institution is required'),
        degree: yup
          .string()
          .max(255, MaxLengthErrorMessage(255))
          .required('Degree is required'),
      }),
    )
    .optional()
    .nullable(),
  certifications: yup
    .array(
      yup.object({
        name: yup
          .string()
          .max(255, MaxLengthErrorMessage(255))
          .required('Certification name is required'),
        date: yup
          .string()
          .max(4, MaxLengthErrorMessage(4))
          .optional()
          .nullable(),
        description: yup
          .string()
          .max(255, MaxLengthErrorMessage(255))
          .optional()
          .nullable(),
      }),
    )
    .optional()
    .nullable(),
  employmentHistory: yup
    .array(
      yup.object({
        from: yup
          .string()
          .max(4, MaxLengthErrorMessage(4))
          .required('Required'),
        to: yup.string().max(4, MaxLengthErrorMessage(4)).optional().nullable(),
        company: yup
          .string()
          .max(255, MaxLengthErrorMessage(255))
          .required('Company is required'),
        summary: yup
          .string()
          .max(5000, MaxLengthErrorMessage(5000))
          .optional()
          .nullable(),
        position: yup
          .string()
          .max(255, MaxLengthErrorMessage(255))
          .required('Title is required'),
        responsibilities: yup
          .array(
            yup
              .string()
              .max(255, MaxLengthErrorMessage(255))
              .required("Field can't be empty"),
          )
          .required('Please specify at least 1 activity/responsibility')
          .min(1, 'Please specify at least 1 activity/responsibility'),
        technologies: yup
          .array(
            yup
              .string()
              .max(255, `Max length of each technology is 255 symbols`)
              .required("Field can't be empty"),
          )
          .optional()
          .nullable(),
      }),
    )
    .optional()
    .nullable(),
});
