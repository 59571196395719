import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { actionReportSelectors, fetchActionReport } from '@redux/action-report';
import { isEmpty, values } from 'lodash';

export const useFetchActionReport = () => {
  const dispatch = useAppDispatch();

  const options = useAppSelector(actionReportSelectors.getActionReportOptions);

  useEffect(() => {
    if (values(options).every(isEmpty)) return;

    dispatch(fetchActionReport(options));
  }, [options]);
};
