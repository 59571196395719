import { AEResumeKeywordsByCategory, AEResumeSkillCategory } from '@constants';
import {
  AEResumeAPIPayload,
  AEResumeFormFields,
  IAEResume,
  IZohoCandidate,
} from '@types';
import { findKey } from 'lodash';

export const getInitialAEResumeFormValues = (
  aeResume: IAEResume | null,
  candidate: IZohoCandidate | null,
): AEResumeFormFields => {
  if (!aeResume)
    return {
      name: null,
      position: null,
      summary: null,
      skills: null,
      educations: null,
      certifications: null,
      employmentHistory: null,
    };

  const isAEResumeGenerated = !!aeResume.link;

  const parsedSkills = aeResume.parsedCV?.skills?.flatMap((resumeSkills) =>
    resumeSkills.skills.map((skill) => ({
      category:
        (findKey(AEResumeKeywordsByCategory, (keywords) =>
          keywords.some((keyword) =>
            resumeSkills.category.toLowerCase().includes(keyword),
          ),
        ) as AEResumeSkillCategory | undefined) || AEResumeSkillCategory.Other,
      skill,
    })),
  );
  const parsedEducations = aeResume.parsedCV?.education?.map((education) => {
    const dates = education.years.match(/\d+/g)?.sort() || [];

    return {
      from: dates[0]?.toString() || null,
      to: dates[1]?.toString() || null,
      degree: education.degree || null,
      institution: education.institution || null,
    };
  });
  const parsedCertifications = aeResume.parsedCV?.certifications?.map(
    (certification) => ({
      date:
        certification.completion_date.match(/\d+/g)?.[0]?.toString() || null,
      name: certification.certification || null,
      description: certification.issuer || null,
    }),
  );
  const parsedEmploymentHistory = aeResume.parsedCV?.employment_history?.map(
    (history) => {
      const dates = history.years.match(/\d+/g)?.sort() || [];

      return {
        from: dates[0]?.toString() || null,
        to: dates[1]?.toString() || null,
        company: history.company || null,
        summary:
          history.summary?.candidate_summary ||
          history.summary?.company_profile ||
          null,
        position: history.position || null,
        responsibilities: history.description || null,
        technologies: history.technologies || null,
      };
    },
  );

  return {
    name: isAEResumeGenerated
      ? aeResume.name
      : aeResume.parsedCV?.name ||
        `${candidate?.First_Name} ${candidate?.Last_Name}` ||
        null,
    position: isAEResumeGenerated
      ? aeResume.position
      : aeResume.parsedCV?.role ||
        `${candidate?.Seniority_Level} ${candidate?.['Dev_-_QA-AQA']}`,
    summary: isAEResumeGenerated
      ? aeResume.summary
      : aeResume.parsedCV?.professional_summary || null,
    skills: isAEResumeGenerated ? aeResume.skills : parsedSkills || null,
    educations: isAEResumeGenerated
      ? aeResume.educations
      : parsedEducations || null,
    certifications: isAEResumeGenerated
      ? aeResume.certifications
      : parsedCertifications || null,
    employmentHistory: isAEResumeGenerated
      ? aeResume.employmentHistory
      : parsedEmploymentHistory || null,
  };
};

export const generateAEResumePayload = (
  candidateId: string,
  values: AEResumeFormFields,
): AEResumeAPIPayload => {
  return {
    candidateId,
    name: values.name,
    position: values.position,
    summary: values.summary,
    skills: values.skills,
    educations: values.educations,
    certifications: values.certifications,
    employmentHistory: values.employmentHistory,
  } as unknown as AEResumeAPIPayload;
};
