import { useCallback } from 'react';

import { Box, Typography } from '@mui/material';
import { CollapseSection, AttachmentButton } from '@components';

import { toUpperFirstWithSpace } from '@utils';
import { InternalTestTask } from '@types';
import { apiEndpoints } from '@constants';

export const InternalTestTaskPreview: React.FC<{
  data: InternalTestTask;
}> = ({ data }) => {
  const downloadAttachment = useCallback((filename: string) => {
    const url = apiEndpoints.internalTestTaskAttachmentFileUrl(filename);
    window.open(url, '_blank', 'noreferrer');
  }, []);

  if (!data) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box display="flex" justifyContent="space-between" gap={2}>
        <Box>
          <Typography variant="h2">{data.name}</Typography>
          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            {data.specialization}
          </Typography>
        </Box>
      </Box>

      {data.description && (
        <Box
          className="content"
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        />
      )}

      {data.instruction && (
        <CollapseSection textLength={data.instruction.length}>
          <Box
            className="content"
            dangerouslySetInnerHTML={{
              __html: data.instruction,
            }}
          />
        </CollapseSection>
      )}

      {data.attachments?.length ? (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="body1" fontWeight="600">
            Check attachments
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {data?.attachments?.map((attachment, index) => (
              <AttachmentButton
                key={index}
                variant="secondary"
                onClick={() => downloadAttachment(attachment)}
              >
                {toUpperFirstWithSpace(attachment)}
              </AttachmentButton>
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
