import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { candidatesSelectors, fetchCandidates } from '@redux/candidates';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

export const useFetchCandidates = () => {
  const dispatch = useAppDispatch();

  const {
    search,
    sortBy,
    order,
    stage,
    country,
    seniority,
    payRate,
    recruiter,
    addedBy,
    techFlow,
    state,
    typeOfOutboundChannel,
    offset,
    limit,
    skill,
  } = useAppSelector(candidatesSelectors.getCandidatesOptions);

  useEffect(() => {
    if (
      [
        offset,
        search,
        sortBy,
        order,
        stage,
        country,
        seniority,
        payRate,
        recruiter,
        addedBy,
        techFlow,
        state,
        typeOfOutboundChannel,
        skill,
      ].every(isEmpty)
    )
      return;

    dispatch(
      fetchCandidates({
        offset,
        limit,
        search,
        sortBy,
        order,
        stage,
        country,
        seniority,
        payRate,
        recruiter,
        addedBy,
        techFlow,
        state,
        typeOfOutboundChannel,
        skill,
      }),
    );
  }, [
    offset,
    limit,
    search,
    sortBy,
    order,
    stage,
    country,
    seniority,
    payRate,
    recruiter,
    addedBy,
    techFlow,
    state,
    typeOfOutboundChannel,
    skill,
  ]);
};
