import { JobOpeningFormValues } from '@types';
import { JobOpeningStatus } from './jobOpenings';

export enum JobOpeningFormMode {
  Create = 'create',
  Duplicate = 'duplicate',
  Edit = 'edit',
}

export enum PositionType {
  BILLABLE = 'Billable',
  INTERNAL = 'Internal',
}

export enum SpecializationType {
  TECH = 'tech',
  NON_TECH = 'non-tech',
}

export const JobOpeningStatusToShow = [
  JobOpeningStatus.InProgress,
  JobOpeningStatus.PreOpen,
];

export const JobOpeningPreOpenPriority = 'Pre-open';

export const DefaultJobOpeningStatus = JobOpeningStatus.InProgress;
export const DefaultInterviewerLabel = 'Assign by Interviewers Club';
export const DefaultInterviewerValue = 'None';

export const ZohoNeedUpdateFieldByFormField: Partial<
  Record<keyof JobOpeningFormValues, string>
> = {
  Job_Opening_Name: 'Posting title',
  Technical_Flow: 'Technical flow',
  Specialization: 'Specialization',
  Seniority_level: 'Seniority level',
  Location_Tier_Outreach: 'Location',
  Google_Drive_Folder: 'Job description',
  Must_Haves1: 'Must haves',
  Multi_Line_4: 'Main tasks',
  Multi_Line_5: 'Nice to haves',
};

export enum SeniorityLevels {
  Trainee = 'Trainee',
  Junior = 'Junior',
  Middle = 'Middle',
  Senior = 'Senior',
  Lead = 'Lead',
  Architect = 'Architect',
  'C-level' = 'C-level',
}

export const SeniorityOrder = [
  SeniorityLevels.Trainee,
  SeniorityLevels.Junior,
  SeniorityLevels.Middle,
  SeniorityLevels.Senior,
  SeniorityLevels.Lead,
  SeniorityLevels.Architect,
  SeniorityLevels['C-level'],
];

export const GroupedJobOpeningSeniorities: Record<
  (typeof SeniorityOrder)[number],
  Array<string>
> = {
  [SeniorityLevels.Trainee]: ['Trainee'],
  [SeniorityLevels.Junior]: ['Junior I', 'Junior II'],
  [SeniorityLevels.Middle]: ['Middle I', 'Middle II'],
  [SeniorityLevels.Senior]: ['Senior I', 'Senior II'],
  [SeniorityLevels.Lead]: ['Lead'],
  [SeniorityLevels.Architect]: ['Architect'],
  [SeniorityLevels['C-level']]: ['C-level'],
};

export const JobOpeningSourceOptions: {
  label: string;
  subLabel: string;
  positionType: PositionType;
  value: string;
}[] = [
  {
    label: 'Potential',
    subLabel: 'Billable',
    positionType: PositionType.BILLABLE,
    value: 'Potential',
  },
  {
    label: 'New client: regular team growth',
    subLabel: 'Billable',
    positionType: PositionType.BILLABLE,
    value: 'New client: regular team growth',
  },
  {
    label: 'New client: new workstream',
    subLabel: 'Billable',
    positionType: PositionType.BILLABLE,
    value: 'New client: new workstream',
  },
  {
    label: 'Existing client: regular team growth',
    subLabel: 'Billable',
    positionType: PositionType.BILLABLE,
    value: 'Existing client: regular team growth',
  },
  {
    label: 'Existing client: new workstream',
    subLabel: 'Billable',
    positionType: PositionType.BILLABLE,
    value: 'Existing client: new workstream',
  },
  {
    label: 'Replacement',
    subLabel: 'Billable',
    positionType: PositionType.BILLABLE,
    value: 'Replacement',
  },
  {
    label: 'Internal',
    subLabel: 'Non-Billable',
    positionType: PositionType.INTERNAL,
    value: 'Internal',
  },
];

export enum VISpecialization {
  DISABLED = 'Disabled',
}
