import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import { Flex, FileInput } from '@components';
import { Button, TextField } from '@mui/material';
import { useAppDispatch } from '@redux/hooks';
import { setSnackbar } from '@redux/snackbar';
import { SnackbarType } from '@constants';

const validationSchema = yup.object({
  issueDescription: yup.string().required('Feedback description is required'),
});

export const ReportIssueForm: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<{
    issueDescription: string;
    attachments: File[];
  }>({
    initialValues: {
      issueDescription: '',
      attachments: [],
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      const form = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value)) {
            for (const file of value) {
              form.append(key, file);
            }
          } else {
            form.append(key, value);
          }
        }
      });

      try {
        await axios.post(`/report-issue`, form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        dispatch(
          setSnackbar({
            type: SnackbarType.Info,
            message: 'Issue has been reported.',
          }),
        );
        resetForm();
        onClose();
      } catch (e: any) {
        dispatch(
          setSnackbar({
            type: SnackbarType.Error,
            message: `Issue hasn't been reported due to ${e.message}.`,
          }),
        );
      }

      setIsLoading(false);
    },
    enableReinitialize: true,
  });

  const issueDescriptionError =
    formik.touched.issueDescription && formik.errors.issueDescription;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        gap={2}
      >
        <TextField
          name="issueDescription"
          label="Feedback description"
          placeholder="Report a bug or suggest an improvement"
          value={formik.values.issueDescription}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          required
          multiline
          minRows={5}
          error={!!issueDescriptionError}
          helperText={
            issueDescriptionError
              ? issueDescriptionError
              : 'Dev team may email you to clarify details'
          }
        />
        <FileInput
          label="Attachments"
          name="attachments"
          multiple
          onChange={({ files }) => formik.setFieldValue('attachments', files)}
        />
      </Flex>
      <Flex gap={1} justifyContent="end">
        <Button
          variant="contained"
          onClick={onClose}
          sx={{
            height: '40px',
            backgroundColor: '#3F8CFF14',
            color: '#42A5F5',
            textTransform: 'none',
            display: 'flex',
            width: '80px',
          }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            formik.handleSubmit();
          }}
          sx={{
            height: '40px',
            backgroundColor: 'text.link',
            textTransform: 'none',
            display: 'flex',
            width: '80px',
          }}
          disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          Save
        </Button>
      </Flex>
    </>
  );
};
