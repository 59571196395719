import { useEffect, useRef, useState } from 'react';

interface IUseKeyboardListNavigationProps<T> {
  items: T[];
  onSelect?: (item: T) => void;
  initialFocusIndex?: number;
  isItemDisabled?: (item: T) => boolean;
  onFocusChange?: (index: number) => void;
  shouldFocusOnMount?: boolean;
  shouldFocusFirstItemAfterSelect?: boolean;
}

interface IUseKeyboardListNavigationReturn<T> {
  focusedIndex: number;
  listItemRefs: React.MutableRefObject<(HTMLElement | null)[]>;
  handleKeyDown: (event: React.KeyboardEvent) => void;
  setFocusedIndex: (index: number) => void;
  focusItem: (index: number) => void;
  handleItemClick: (item: T) => void;
}

export function useKeyboardListNavigation<T>({
  items,
  onSelect,
  initialFocusIndex = 0,
  isItemDisabled,
  onFocusChange,
  shouldFocusOnMount = true,
  shouldFocusFirstItemAfterSelect = true,
}: IUseKeyboardListNavigationProps<T>): IUseKeyboardListNavigationReturn<T> {
  const [focusedIndex, setFocusedIndex] = useState<number>(initialFocusIndex);
  const listItemRefs = useRef<(HTMLElement | null)[]>([]);

  const focusItem = (index: number) => {
    if (index >= 0 && index < items.length) {
      setFocusedIndex(index);
      listItemRefs.current[index]?.focus();
      onFocusChange?.(index);
    }
  };

  useEffect(() => {
    if (shouldFocusOnMount && items.length > 0) {
      focusItem(initialFocusIndex);
    }
  }, [items.length, shouldFocusOnMount, initialFocusIndex]);

  useEffect(() => {
    listItemRefs.current = listItemRefs.current.slice(0, items.length);
  }, [items]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (focusedIndex >= 0 && focusedIndex < items.length) {
        const selectedItem = items[focusedIndex];
        if (!isItemDisabled?.(selectedItem)) {
          onSelect?.(selectedItem);
          if (shouldFocusFirstItemAfterSelect) {
            // Focus first non-disabled item after selection
            const nextFocusIndex = items.findIndex(
              (item) => !isItemDisabled?.(item),
            );
            setTimeout(() => focusItem(nextFocusIndex), 0);
          }
        }
        event.preventDefault();
      }
    } else if (event.key === 'ArrowDown') {
      let nextIndex = focusedIndex;
      do {
        nextIndex = (nextIndex + 1) % items.length;
      } while (
        isItemDisabled?.(items[nextIndex]) &&
        nextIndex !== focusedIndex
      );

      if (!isItemDisabled?.(items[nextIndex])) {
        focusItem(nextIndex);
        event.preventDefault();
      }
    } else if (event.key === 'ArrowUp') {
      let nextIndex = focusedIndex;
      do {
        nextIndex = (nextIndex - 1 + items.length) % items.length;
      } while (
        isItemDisabled?.(items[nextIndex]) &&
        nextIndex !== focusedIndex
      );

      if (!isItemDisabled?.(items[nextIndex])) {
        focusItem(nextIndex);
        event.preventDefault();
      }
    }
  };

  const handleItemClick = (item: T) => {
    if (!isItemDisabled?.(item)) {
      onSelect?.(item);
      if (shouldFocusFirstItemAfterSelect) {
        const nextFocusIndex = items.findIndex(
          (item) => !isItemDisabled?.(item),
        );
        setTimeout(() => focusItem(nextFocusIndex), 0);
      }
    }
  };

  return {
    focusedIndex,
    listItemRefs,
    handleKeyDown,
    setFocusedIndex,
    focusItem,
    handleItemClick,
  };
}
