import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  AEResumeForm,
  AEResumeParsingInProgress,
  TextButton,
} from '@components';
import PostAddIcon from '@mui/icons-material/PostAdd';

import {
  candidateDetailsSelectors,
  clearCandidateAEResume,
  fetchCandidateAEResume,
  setCandidateAEResumePolling,
} from '@redux/candidateDetails';
import { authSelectors } from '@redux/auth';

import {
  checkUserCanGenerateAEResume,
  isStatusIdle,
  pollRequest,
} from '@utils';
import { IAEResume } from '@types';

const AE_RESUME_POLL_INTERVAL = 10;
const AE_RESUME_POLL_ATTEMPTS = 10;

export const AEResumeGenerate: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const aeResume = useAppSelector(
    candidateDetailsSelectors.getCandidateAEResume,
  );
  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const profile = useAppSelector(authSelectors.getProfileData);

  const canGenerateAEResume = checkUserCanGenerateAEResume(profile);

  const onModalClose = useCallback(() => {
    dispatch(clearCandidateAEResume());
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    if (isModalOpen && isStatusIdle(aeResume.apiStatus) && candidateId) {
      dispatch(fetchCandidateAEResume(candidateId));
    }
  }, [isModalOpen, aeResume.apiStatus, candidateId]);

  const getCandidateAEResume = useCallback(() => {
    return dispatch(fetchCandidateAEResume(candidateId!)).unwrap();
  }, [candidateId]);

  const pollCandidateAEResume = () => {
    pollRequest<IAEResume | null>({
      fn: getCandidateAEResume,
      validate: (value) => {
        return !!value?.parsedCV;
      },
      interval: AE_RESUME_POLL_INTERVAL * 1000,
      maxAttempts: AE_RESUME_POLL_ATTEMPTS,
      errorCallback: () => {
        dispatch(setCandidateAEResumePolling(false));
      },
      finalCallback: () => {
        dispatch(setCandidateAEResumePolling(false));
      },
    });
  };

  useEffect(() => {
    if (
      candidateId &&
      !!aeResume.data?.cvParserId &&
      !aeResume.data.parsedCV &&
      !aeResume.isPolling
    ) {
      dispatch(setCandidateAEResumePolling(true));
      pollCandidateAEResume();
    }
  }, [aeResume.data, aeResume.isPolling, candidateId]);

  const isCVParsing = !!aeResume.data?.cvParserId && !aeResume.data.parsedCV;

  return (
    <>
      <TextButton
        variant="secondary"
        startIcon={<PostAddIcon />}
        onClick={() => setIsModalOpen(true)}
        sx={{ fontSize: '13px', fontWeight: '500', wordWrap: 'normal' }}
        disabled={!canGenerateAEResume}
      >
        Generate
      </TextButton>
      <AEResumeParsingInProgress
        isOpen={isModalOpen && isCVParsing}
        onModalClose={onModalClose}
      />
      <AEResumeForm
        isOpen={isModalOpen && !isCVParsing}
        onModalClose={onModalClose}
      />
    </>
  );
};
