import { CandidateStatus, CandidateStatusStage } from './candidateStatus';

export enum UserVideoInterviewStatus {
  TODO = 'ToDo',
  STARTED = 'Started',
  SKIPPED = 'Skipped',
  FINISHED = 'Finished',
}

export enum ReviewLevels {
  POOR = 'poor',
  GOOD = 'good',
  GREAT = 'great',
}

export enum VIResolution {
  PASSED = 'passed',
  FAILED_SOFT_SKILLS = 'failed: soft skills',
  FAILED_WRONG_ANSWER = 'failed: wrong answer',
  FAILED_LOW_ENGLISH = 'failed: low english',
  FAILED_INCOMPLETE = 'failed: incomplete',
  PASSED_NSO_LOCATION = 'passed: NSO location',
  PASSED_NSO_SENIORITY = 'passed: NSO seniority',
  PASSED_NSO_CORE_STACK = 'passed: NSO:core stack',
  PASSED_NSO_NOT_OUR_CORE = 'passed: NSO:not our core stack',
}

export const CandidateStatusTransitionByVIResolution: Record<
  VIResolution,
  { status: CandidateStatus; stage: CandidateStatusStage }
> = {
  [VIResolution.PASSED]: {
    stage: CandidateStatusStage.TI,
    status: CandidateStatus.Setting_TI,
  },
  [VIResolution.FAILED_SOFT_SKILLS]: {
    stage: CandidateStatusStage.VideoInterview,
    status: CandidateStatus.VI_Bad_Soft_Skills,
  },
  [VIResolution.FAILED_WRONG_ANSWER]: {
    stage: CandidateStatusStage.VideoInterview,
    status: CandidateStatus.VI_Wrong_Answers,
  },
  [VIResolution.FAILED_LOW_ENGLISH]: {
    stage: CandidateStatusStage.VideoInterview,
    status: CandidateStatus.VI_Low_English,
  },
  [VIResolution.FAILED_INCOMPLETE]: {
    stage: CandidateStatusStage.VideoInterview,
    status: CandidateStatus.VI_Incomplete,
  },
  [VIResolution.PASSED_NSO_LOCATION]: {
    stage: CandidateStatusStage.VideoInterview,
    status: CandidateStatus.No_Suitable_Openings_Location,
  },
  [VIResolution.PASSED_NSO_SENIORITY]: {
    stage: CandidateStatusStage.VideoInterview,
    status: CandidateStatus.No_Suitable_Openings_Seniority,
  },
  [VIResolution.PASSED_NSO_CORE_STACK]: {
    stage: CandidateStatusStage.VideoInterview,
    status: CandidateStatus.No_Suitable_Openings_CoreStack,
  },
  [VIResolution.PASSED_NSO_NOT_OUR_CORE]: {
    stage: CandidateStatusStage.VideoInterview,
    status: CandidateStatus.No_Suitable_Openings_NotOurCoreStack,
  },
};

export const LevelsReviewOptions = [
  {
    label: 'Poor',
    value: 'poor',
  },
  {
    label: 'Good',
    value: 'good',
  },
  {
    label: 'Great',
    value: 'great',
  },
];

export const VIResolutionOptionsList = [
  {
    label: '✅ Passed',
    value: VIResolution.PASSED,
  },
  {
    label: '✅ Passed - NSO: location',
    value: VIResolution.PASSED_NSO_LOCATION,
  },
  {
    label: '✅ Passed - NSO: seniority',
    value: VIResolution.PASSED_NSO_SENIORITY,
  },
  {
    label: '✅ Passed - NSO: core stack',
    value: VIResolution.PASSED_NSO_CORE_STACK,
  },
  {
    label: '✅ Passed - NSO: not our core stack',
    value: VIResolution.PASSED_NSO_NOT_OUR_CORE,
  },
  {
    label: '❌ Failed: Soft skills',
    value: VIResolution.FAILED_SOFT_SKILLS,
  },
  {
    label: '❌ Failed: Incomplete',
    value: VIResolution.FAILED_INCOMPLETE,
  },
  {
    label: '❌ Failed: Wrong answers',
    value: VIResolution.FAILED_WRONG_ANSWER,
  },
  {
    label: '❌ Failed: Low English',
    value: VIResolution.FAILED_LOW_ENGLISH,
  },
];
