import upperFirst from 'lodash/upperFirst';

export const toUpperFirstWithSpace = (text: string) => {
  const formatted = text.replace(/_|-/g, ' ');
  return upperFirst(formatted);
};

export const pluralize = (singular: string, times = 1) => {
  if (times < 2) return singular;
  else return singular + 's';
};

export const getInitialsFromName = (fullName: string) => {
  if (!fullName || typeof fullName !== 'string') {
    return '';
  }

  return fullName
    .split(' ')
    .filter((word) => word.trim().length > 0)
    .map((word) => word[0].toUpperCase())
    .join('');
};
