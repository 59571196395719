import { JobOpeningStatus } from '@constants';
import { IDWHJobOpening, IDWHJobOpeningRaw } from '@types';

export const transformJobOpening = (
  data: IDWHJobOpeningRaw,
): IDWHJobOpening => ({
  ...data,
  LP_Specializations: JSON.parse(data.LP_Specializations || '[]'),
  Stop_outreach_channels: JSON.parse(data.Stop_outreach_channels || '[]'),
  Seniority_Level: JSON.parse(data.Seniority_Level || '[]'),
  Location_Area: JSON.parse(data.Location_Area || '[]'),
  Location_Tier_Outreach: JSON.parse(data.Location_Tier_Outreach || '[]'),
  Stack: JSON.parse(data.Stack || '[]'),
  Client_Recruiter: JSON.parse(data.Client_Recruiter || '[]'),
  City_Location: JSON.parse(data.City_Location || '[]'),
  Interview_Steps: JSON.parse(data.Interview_Steps || '[]'),
});

export const isJobPreopen = (job: IDWHJobOpening) => {
  return job.Job_Opening_Status === JobOpeningStatus.PreOpen;
};

export const formatJobOpeningName = (
  name?: string,
  id?: string,
  clientName?: string,
) => {
  return [id, clientName, name].filter(Boolean).join(' - ');
};

export const formatJobOpeningWithHash = (
  name?: string,
  id?: string,
  clientName?: string,
) => {
  return `#${id} ${clientName}: ${name}`;
};
