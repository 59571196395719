import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import { CustomButton } from '@components';

const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF4C4C',
  color: theme.palette.common.white,
  textTransform: 'none',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  ':hover': {
    backgroundColor: '#FF4C4C',
  },
}));

type DeleteNoteConfirmationModalProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  deleteNote: () => void;
};

export const DeleteNoteConfirmationModal: React.FC<
  DeleteNoteConfirmationModalProps
> = ({ isLoading, isOpen, onClose, deleteNote }) => {
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: '1rem 1.5rem' }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2">Deleting the note</Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          padding="1rem"
          flexDirection="row"
          bgcolor="background.backgroundPrimary"
          gap="0.5rem"
        >
          <MailOutlineRoundedIcon color="secondary" />
          <Typography variant="body2">
            Your note will be deleted without restoring.
            <br />
            Are you sure?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem' }}>
        <Stack justifyContent="end" flexDirection="row" gap="0.5rem">
          <CustomButton label="Cancel" color="secondary" onClick={onClose} />
          <StyledDangerButton onClick={deleteNote} disabled={isLoading}>
            Delete
          </StyledDangerButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
