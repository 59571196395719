import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { Box } from '@mui/material';
import {
  CandidateDetails,
  CandidateName,
  EntityDetailsLayout,
} from '@components';

import {
  candidateDetailsSelectors,
  setCandidateId,
} from '@redux/candidateDetails';

import {
  CandidateDetailsTabLabel,
  CandidateDetailsTabs,
  CandidateDetailsTabsOrder,
  EnineeringSpecializations,
} from '@constants';

export const CandidateDetailsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { candidateId, selectedTabName = CandidateDetailsTabs.PROFILE } =
    useParams();

  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );

  useEffect(() => {
    if (candidateId) {
      dispatch(setCandidateId(candidateId));
    }
  }, [candidateId]);

  let maxWidth: { xs: string; md?: string } | string = '43rem';
  if (selectedTabName === CandidateDetailsTabs.VIDEO_INTERVIEW) {
    maxWidth = { xs: '43rem', md: '54rem' };
  } else if (selectedTabName === CandidateDetailsTabs.TIMELINE) {
    maxWidth = { xs: '82rem' };
  } else if (selectedTabName === CandidateDetailsTabs.POSITIONS) {
    maxWidth = { xs: '82rem' };
  }

  // Header
  const handleBackButtonClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  // Tabs
  const selectedTab = {
    label: CandidateDetailsTabLabel[selectedTabName as CandidateDetailsTabs],
    value: selectedTabName,
  };
  const tabs = CandidateDetailsTabsOrder.filter(
    (tabValue) =>
      !(
        EnineeringSpecializations.includes(
          candidateDetails?.['Dev_-_QA-AQA'] as string,
        ) && tabValue === CandidateDetailsTabs.QUIZ
      ),
  ).map((tabValue) => ({
    label: CandidateDetailsTabLabel[tabValue],
    value: tabValue,
  }));
  const onTabChange = useCallback((tab: string) => {
    navigate(`/candidates/${candidateId}/${tab}`, {
      state: { from: location.state?.from },
    });
  }, []);

  // Search
  const onSearchChange = useCallback(
    (value: string | null) => navigate(`/candidates?search=${value}`),
    [],
  );

  return (
    <EntityDetailsLayout
      headerOptions={{
        HeaderComponent: <CandidateName sx={{ maxWidth: '14rem' }} />,
        onBackButtonClick: handleBackButtonClick,
      }}
      tabOptions={{
        selectedTab,
        tabs,
        onChange: onTabChange,
      }}
      searchOptions={{
        placeholder: 'Name, email, ID, etc...',
        onChange: onSearchChange,
      }}
    >
      <Box
        sx={{
          padding: {
            sm: '1.5rem 3rem',
            md: '2rem 3rem',
          },
        }}
      >
        <Box sx={{ maxWidth, margin: 'auto' }}>
          <CandidateDetails />
        </Box>
      </Box>
    </EntityDetailsLayout>
  );
};
