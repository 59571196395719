import React, { useCallback } from 'react';
import { format } from 'date-fns';

import { Box, Typography } from '@mui/material';

import { Accordion, AccordionSummary, AccordionDetails } from '@components';
import { TestTaskDetails } from './TestTaskDetails';

import { isTestTaskDone, isTestTaskFailed, isTestTaskPassed } from '@utils';
import {
  CandidateTestTask,
  IAvailableTestTask,
  InternalTestTask,
  TestTaskReviewPayload,
} from '@types';

export const TestTaskList: React.FC<{
  selectedTestTaskId: string | null;
  availableTestTasksData: IAvailableTestTask[];
  candidateTestTasks: CandidateTestTask[];
  handleChange: (newValue: string) => void;
  handleEditTestTask: () => void | null;
  handleSendTestTask: () => void | null;
  handleReviewTT: (
    payload: {
      userTestTaskId: string;
    } & TestTaskReviewPayload,
  ) => void;
}> = ({
  selectedTestTaskId,
  availableTestTasksData,
  candidateTestTasks,
  handleChange,
  handleEditTestTask,
  handleSendTestTask,
  handleReviewTT,
}) => {
  const getStatusText = useCallback((tt: CandidateTestTask) => {
    if (isTestTaskPassed(tt.status)) {
      return 'Passed';
    } else if (isTestTaskFailed(tt.status)) {
      return 'Failed';
    } else if (isTestTaskDone(tt.status)) {
      return 'Needs review';
    } else {
      return tt.status.at(-1) ?? '';
    }
  }, []);

  const getStatusColor = useCallback((tt: CandidateTestTask) => {
    if (isTestTaskPassed(tt.status)) {
      return 'text.success';
    } else if (isTestTaskFailed(tt.status)) {
      return 'text.danger';
    } else if (isTestTaskDone(tt.status)) {
      return 'text.primary';
    } else {
      return 'text.primary';
    }
  }, []);

  const getDate = useCallback((tt: CandidateTestTask) => {
    if (isTestTaskPassed(tt.status) || isTestTaskFailed(tt.status)) {
      return tt.resultDate;
    } else {
      return tt.sentDate;
    }
  }, []);

  const handleAccordionClick =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      handleChange(newExpanded ? panel : '');
    };

  return (
    <React.Fragment>
      {availableTestTasksData.map((tt) => {
        const ttDetails = tt.details as InternalTestTask;

        return (
          <Accordion
            key={tt.id}
            expanded={selectedTestTaskId === tt.id}
            onChange={handleAccordionClick(tt.id)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id={tt.id}
              sx={{
                '& .MuiAccordionSummary-content': {
                  gap: '0.5rem',
                  alignItems: 'center',
                },
              }}
            >
              <Typography variant="body1" flex={1}>
                {ttDetails.name}
              </Typography>
              <Typography variant="body1">Available test task</Typography>
              <Typography variant="body2" color={'text.secondary'}>
                {format(new Date(), 'yyyy-MM-dd')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TestTaskDetails
                selectedTTFromCandidateTT={undefined}
                selectedTTFromAvailableList={ttDetails}
                handleSendTestTask={handleSendTestTask}
                handleEditTestTask={handleEditTestTask}
                handleReviewTT={handleReviewTT}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      {candidateTestTasks.map((tt) => (
        <Accordion
          key={tt.id}
          expanded={selectedTestTaskId === tt.id}
          onChange={handleAccordionClick(tt.id)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id={tt.id}
            sx={{
              '& .MuiAccordionSummary-content': {
                gap: '0.5rem',
                alignItems: 'center',
              },
            }}
          >
            <Typography variant="body1" flex={1}>
              <Box component={'span'} color={'text.secondary'}>
                {tt?.score ? `${tt.score}% ` : ''}
              </Box>
              {tt.stack}
            </Typography>
            <Typography variant="body1" color={getStatusColor(tt)}>
              {getStatusText(tt)}
            </Typography>
            <Typography variant="body2" color={'text.secondary'}>
              {getDate(tt) ? format(new Date(getDate(tt)!), 'yyyy-MM-dd') : '—'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TestTaskDetails
              selectedTTFromCandidateTT={tt}
              selectedTTFromAvailableList={undefined}
              handleSendTestTask={handleSendTestTask}
              handleEditTestTask={handleEditTestTask}
              handleReviewTT={handleReviewTT}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </React.Fragment>
  );
};
