import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { LoaderSkeleton, ForbiddenView, Flex } from '@components';
import { VideoInterviewQuestions } from './components/VideoInterviewQuestions';
import { VideoInterviewFeedback } from './components/VideoInterviewFeedback/VideoInterviewFeedback';
import { VideoInterviewStatus } from './components/VideoInterviewStatus/VideoInterviewStatus';

import {
  candidateDetailsSelectors,
  fetchCandidateUserVideoInterview,
  generateClientVideoLink,
  postCandidateResetVideoInterview,
  postUserVideoInterviewReview,
  setCandidateStatusChangeModalProps,
} from '@redux/candidateDetails';
import {
  fetchJobOpeningVideoInterviews,
  optionsSelectors,
} from '@redux/options';

import { isStatusForbidden, isStatusIdle, isStatusLoading } from '@utils';
import { ReviewVideoInterviewPayload } from '@types';
import {
  CandidateStatusTransitionByVIResolution,
  SnackbarType,
  UserVideoInterviewStatus,
  VIResolution,
} from '@constants';
import { setSnackbar } from '@redux/snackbar';

export const CandidateVideoInterview = () => {
  const dispatch = useAppDispatch();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const videoInterviewAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewApiStatus,
  );

  const candidate = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const isPerformingVideoInterviewAction = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewActionPerforming,
  );
  const videoInterviewData = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewData,
  );
  const resetVideoInterviewApiStatus = useAppSelector(
    candidateDetailsSelectors.getResetVideoInterviewAPIStatus,
  );
  const {
    data: availableVideoInterview,
    apiStatus: availableVideoInterviewAPIStatus,
  } = useAppSelector(optionsSelectors.getJobOpeningsVIAPIData);

  const generateClientVideoLinkAPIStatus = useAppSelector(
    candidateDetailsSelectors.getGenerateClientVideoLinkApiStatus,
  );

  useEffect(() => {
    if (candidateId && isStatusIdle(videoInterviewAPIStatus)) {
      dispatch(fetchCandidateUserVideoInterview(candidateId));
    }
  }, [candidateId, videoInterviewAPIStatus]);

  useEffect(() => {
    if (isStatusIdle(availableVideoInterviewAPIStatus)) {
      dispatch(fetchJobOpeningVideoInterviews());
    }
  }, [availableVideoInterviewAPIStatus]);

  const currentVideoInterview = videoInterviewData[0] || null;

  const reviewVideoInterview = useCallback(
    async (payload: ReviewVideoInterviewPayload) => {
      if (!currentVideoInterview || !candidateId) return;

      await dispatch(
        postUserVideoInterviewReview({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
          ...payload,
        }),
      );

      const nextCandidateStatusTransitions =
        CandidateStatusTransitionByVIResolution[
          payload.resolution as VIResolution
        ];

      if (nextCandidateStatusTransitions && payload.showStatusTransition) {
        dispatch(
          setCandidateStatusChangeModalProps({
            isOpen: true,
            selectedJobOpeningId: null,
            preselectedStage: nextCandidateStatusTransitions.stage,
            preselectedStatus: nextCandidateStatusTransitions.status,
          }),
        );
      }
    },
    [candidateId, currentVideoInterview],
  );

  const resetVideoInterview = useCallback(() => {
    if (candidateId) {
      dispatch(
        postCandidateResetVideoInterview({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
        }),
      );
    }
  }, [candidateId, currentVideoInterview]);

  const handleGenerateClick = useCallback(() => {
    if (candidateId && currentVideoInterview) {
      dispatch(
        generateClientVideoLink({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
        }),
      );
    }
  }, [candidateId, currentVideoInterview]);

  const handleCopyLinkCandidateVIInvite = () => {
    navigator.clipboard.writeText(
      currentVideoInterview?.candidateVideoInviteLink ?? '',
    );
    dispatch(
      setSnackbar({
        type: SnackbarType.Info,
        message: 'Link copied to clipboard',
      }),
    );
  };

  if (
    isStatusLoading(videoInterviewAPIStatus) ||
    isStatusLoading(availableVideoInterviewAPIStatus)
  ) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(videoInterviewAPIStatus)) {
    return <ForbiddenView />;
  }

  return (
    <Flex
      flexDirection={
        currentVideoInterview &&
        currentVideoInterview.status === UserVideoInterviewStatus.FINISHED
          ? 'row'
          : 'column'
      }
      gap={currentVideoInterview ? 3 : 8}
      justifyContent="space-between"
      alignItems={
        currentVideoInterview &&
        currentVideoInterview.status === UserVideoInterviewStatus.FINISHED
          ? 'start'
          : 'center'
      }
    >
      <Flex
        flexDirection="column"
        gap={3}
        alignItems="start"
        justifyContent="start"
        width="50%"
      >
        <VideoInterviewQuestions
          videoInterviewData={currentVideoInterview}
          availableVideoInterview={availableVideoInterview}
          isLoadingVIShareWithClient={isStatusLoading(
            generateClientVideoLinkAPIStatus,
          )}
          generateVIShareClientLink={handleGenerateClick}
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="end"
        gap={2}
        width="50%"
      >
        <VideoInterviewStatus
          videoInterviewData={currentVideoInterview}
          resetVideoInterviewApiStatus={resetVideoInterviewApiStatus}
          resetVideoInterview={resetVideoInterview}
          handleCopyLinkCandidateVIInvite={handleCopyLinkCandidateVIInvite}
        />
        <VideoInterviewFeedback
          candidateFirstName={candidate?.First_Name || ''}
          isPerformingVideoInterviewAction={isPerformingVideoInterviewAction}
          videoInterviewData={currentVideoInterview}
          postVideoInterviewReview={reviewVideoInterview}
        />
      </Flex>
    </Flex>
  );
};
