import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';

import { CustomButton } from '@components';

import { authSelectors } from '@redux/auth';
import { useAppSelector } from '@redux/hooks';

import { checkUserCanGenerateTILink } from '@utils';

export const ScheduleEngineeringTIButton = ({
  zohoId,
}: {
  zohoId?: string | null;
}) => {
  const profile = useAppSelector(authSelectors.getProfileData);

  const url = zohoId
    ? `https://script.google.com/a/macros/agileengine.com/s/AKfycbyPXT0F8ADWavurAD3cWlHN7NhQn6tHuRo8fTLVH_VNfeMC3zF1dD-Wfj6uuOWd4TYU/exec?eventType=TI&longZohoId=Zrecruit_${zohoId}`
    : undefined;

  const isDisabled = !checkUserCanGenerateTILink(profile);

  if (!zohoId) return null;

  return (
    <CustomButton
      isDisabled={isDisabled}
      size="small"
      color="secondary"
      label={'Generate TI'}
      startIcon={<VideoCallOutlinedIcon fontSize="small" />}
      href={url}
    />
  );
};
