import { ReactNode } from 'react';

import { Flex } from '@components';
import { Box } from '@mui/material';

export const ColumnLayout: React.FC<{
  contentComponent: ReactNode;
  side: 'right' | 'left';
  headerComponent?: ReactNode;
}> = ({ contentComponent, side, headerComponent }) => {
  return (
    <Flex
      flexDirection="column"
      height="100%"
      flex={1}
      sx={{ maxHeight: '75vh', minHeight: '75vh' }}
    >
      <Box
        flex={1}
        width="100%"
        sx={(theme) => ({
          background: theme.palette.background.default,
          overflow: 'hidden',
          overflowY: 'auto',
          borderStyle: 'solid',
          borderColor: theme.palette.background.backgroundPrimary,
          borderWidth: side === 'right' ? '8px 8px 8px 0' : '8px 0 8px 8px',
        })}
      >
        {headerComponent}
        {contentComponent}
      </Box>
    </Flex>
  );
};
