import { IDWHCandidate } from '@types';
import { isBefore, isEqual } from 'date-fns';
import { getMondayOfWeek } from './getMondaysFromDate';
import { isCandidatePassedStage } from './getCandidatesByStage';
import { max } from 'lodash';
import { CandidateStage, CandidateStatus } from '@constants';

const checkIfCandidateIsInStage = (
  mondayOfWeek: Date,
  stageDate: string | null,
  isFirstWeek: boolean,
): boolean => {
  if (!stageDate) return false;

  const modayOfStage = getMondayOfWeek(stageDate);

  return (
    isEqual(mondayOfWeek, modayOfStage) ||
    (isFirstWeek && isBefore(modayOfStage, mondayOfWeek))
  );
};

export const getWeekPipeline = (
  candidates: IDWHCandidate[],
  mondayOfWeek: Date,
  isFirstWeek: boolean,
): Partial<Record<CandidateStage, IDWHCandidate[]>> => {
  return candidates.reduce(
    (acc, candidate: IDWHCandidate) => {
      const applyDate =
        max(
          [candidate.Date_Of_Last_Apply, candidate.Interested_Info_Date].filter(
            Boolean,
          ),
        ) || null;
      const ttPassedDate = isCandidatePassedStage(
        candidate,
        CandidateStage.TT_PASSED,
      )
        ? candidate.Test_Task_Date
        : null;
      const viDoneDate = isCandidatePassedStage(
        candidate,
        CandidateStage.VI_DONE,
      )
        ? candidate.VI_result
        : null;
      const viPassedDate = isCandidatePassedStage(
        candidate,
        CandidateStage.VI_PASSED,
      )
        ? candidate.VI_result
        : null;
      const tiPassedDate = isCandidatePassedStage(
        candidate,
        CandidateStage.TI_PASSED,
      )
        ? candidate.associationStatuses?.find((sc) =>
            [
              CandidateStatus.TI_Passed,
              CandidateStatus.TI_Passed_Lower_Seniority,
            ].includes(sc.status),
          )?.date || candidate.Date_Of_Tech_Interview_Planned
        : null;
      const submittedDate = isCandidatePassedStage(
        candidate,
        CandidateStage.SUBMITTED,
      )
        ? candidate.associationStatuses?.find((sc) =>
            [CandidateStatus.Submitted].includes(sc.status),
          )?.date || candidate.Date_Of_Submission
        : null;

      return {
        [CandidateStage.APPLIED]: checkIfCandidateIsInStage(
          mondayOfWeek,
          applyDate,
          isFirstWeek,
        )
          ? acc.applied.concat(candidate)
          : acc.applied,
        [CandidateStage.TT_PASSED]: checkIfCandidateIsInStage(
          mondayOfWeek,
          ttPassedDate,
          isFirstWeek,
        )
          ? acc.ttPassed.concat(candidate)
          : acc.ttPassed,
        [CandidateStage.VI_DONE]: checkIfCandidateIsInStage(
          mondayOfWeek,
          viDoneDate,
          isFirstWeek,
        )
          ? acc.viDone.concat(candidate)
          : acc.viDone,
        [CandidateStage.VI_PASSED]: checkIfCandidateIsInStage(
          mondayOfWeek,
          viPassedDate,
          isFirstWeek,
        )
          ? acc.viPassed.concat(candidate)
          : acc.viPassed,
        [CandidateStage.TI_PASSED]: checkIfCandidateIsInStage(
          mondayOfWeek,
          tiPassedDate,
          isFirstWeek,
        )
          ? acc.tiPassed.concat(candidate)
          : acc.tiPassed,
        [CandidateStage.SUBMITTED]: checkIfCandidateIsInStage(
          mondayOfWeek,
          submittedDate,
          isFirstWeek,
        )
          ? acc.submitted.concat(candidate)
          : acc.submitted,
      };
    },
    {
      [CandidateStage.APPLIED]: [] as IDWHCandidate[],
      [CandidateStage.TT_PASSED]: [] as IDWHCandidate[],
      [CandidateStage.VI_DONE]: [] as IDWHCandidate[],
      [CandidateStage.VI_PASSED]: [] as IDWHCandidate[],
      [CandidateStage.TI_PASSED]: [] as IDWHCandidate[],
      [CandidateStage.SUBMITTED]: [] as IDWHCandidate[],
    },
  );
};
