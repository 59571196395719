import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiStatus } from '@constants';
import { actionReportStoreKey } from './action-report.const';
import { fetchActionReport } from './action-report.thunks';
import { IActionReportSliceState } from './action-report.types';
import { IActionReportList, IActionReportOptions } from '@types';

export const ACTION_REPORT_DEFAULT_LIMIT = 30;
const initialState: IActionReportSliceState = {
  apiStatus: ApiStatus.IDLE,
  data: [],
  totalCount: 0,
  options: {
    search: null,
    sortBy: null,
    order: null,
    stage: null,
    state: null,
    country: null,
    seniority: null,
    payRate: null,
    recruiter: null,
    addedBy: null,
    techFlow: null,
    offset: 0,
    limit: ACTION_REPORT_DEFAULT_LIMIT,
    groupBy: null,
  },
};

const actionReportSlice = createSlice({
  name: actionReportStoreKey,
  initialState,
  reducers: {
    setActionReportOptionField: (
      state,
      action: PayloadAction<Partial<Record<keyof IActionReportOptions, any[]>>>,
    ) => {
      state.options = {
        sortBy: action.payload.sortBy?.[0] || null,
        order: action.payload.order?.[0] || null,
        groupBy: action.payload.groupBy?.[0] || null,
        search: action.payload.search?.[0] || null,
        stage: action.payload.stage?.[0] || null,
        state: action.payload.state?.[0] || null,
        country: action.payload.country || null,
        seniority: action.payload.seniority || null,
        payRate: action.payload.payRate || null,
        recruiter: action.payload.recruiter || null,
        addedBy: action.payload.addedBy || null,
        techFlow: action.payload.techFlow || null,
        offset: parseInt(action.payload.offset?.[0] || '0'),
        limit: ACTION_REPORT_DEFAULT_LIMIT,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActionReport.pending, (state) => {
        state.apiStatus = ApiStatus.LOADING;
      })
      .addCase(
        fetchActionReport.fulfilled,
        (state, action: PayloadAction<IActionReportList>) => {
          state.apiStatus = ApiStatus.COMPLETE;
          state.data = state.options.offset
            ? state.data.concat(action.payload.data)
            : action.payload.data;
          state.totalCount = action.payload.totalCount;
        },
      )
      .addCase(
        fetchActionReport.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      );
  },
});

export const { setActionReportOptionField } = actionReportSlice.actions;

export const actionReportSliceReducer = actionReportSlice.reducer;
