import React, { useMemo } from 'react';
import { capitalize, isEmpty, isNil, isNumber } from 'lodash';
import { useAppSelector } from '@redux/hooks';

import { Box, Link, Stack, SvgIcon, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import {
  RowLayout,
  CountryFlag,
  EditCandidateProfile,
  AEResumeGenerate,
} from '@components';

import { candidateDetailsSelectors } from '@redux/candidateDetails';

import {
  getUserExperienceInfo,
  getUserCompensationInfo,
  getUserTestTaskInfo,
} from '@utils';
import { CandidateFieldsLabels } from '@constants';

export const CandidateProfileData: React.FC = () => {
  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );

  const { yearsOfExperience } = useMemo(
    () => getUserExperienceInfo(candidateDetails),
    [candidateDetails],
  );

  const { compensation, compensationType } = useMemo(
    () => getUserCompensationInfo(candidateDetails),
    [candidateDetails],
  );

  const testTaskData = useMemo(
    () => getUserTestTaskInfo(candidateDetails),
    [candidateDetails],
  );

  return (
    <Stack
      gap="0.5rem"
      sx={{
        paddingX: {
          xs: '0.5rem',
          sm: '0.25rem',
        },
      }}
    >
      <Stack
        mx="0.25rem"
        alignItems="center"
        sx={{
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          gap: {
            xs: '0.25rem',
            sm: '1rem',
          },
        }}
      >
        <Stack direction="row" flex={1} width="100%">
          <Typography variant="body1" flex={1}>
            Candidate details
          </Typography>
          <Link
            href={`https://recruit.zoho.com/recruit/org30986285/EntityInfo.do?module=Candidates&id=${candidateDetails?.id}&submodule=Candidates`}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{
              cursor: 'pointer',
            }}
          >
            Candidate at Zoho ↗
          </Link>
        </Stack>
        <Stack
          sx={{
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
        >
          <EditCandidateProfile candidateDetails={candidateDetails} />
        </Stack>
      </Stack>
      <Stack>
        <RowLayout
          label="Long Candidate ID"
          Component={
            <Typography variant="body1">
              {`Zrecruit_${candidateDetails?.id}`}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Location_Country']}
          Component={
            <>
              <CountryFlag
                key={candidateDetails?.Location_Country}
                country={candidateDetails?.Location_Country ?? ''}
                sx={{ marginRight: '6px' }}
              />
              <Typography variant="body1">
                {candidateDetails?.Location_Country}
              </Typography>
            </>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Location_City']}
          Component={
            <Typography variant="body1">
              {candidateDetails?.Location_City}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['English_level']}
          Component={
            <Typography variant="body1">
              {candidateDetails?.English_level}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Dev_-_QA-AQA']}
          Component={
            <Typography variant="body1">
              {candidateDetails?.['Dev_-_QA-AQA']}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Technical_Flow']}
          Component={
            <Typography variant="body1">
              {candidateDetails?.Technical_Flow}
            </Typography>
          }
        />
        <RowLayout
          label="Skills"
          Component={
            <Stack direction="column">
              <Stack direction="row" gap={1} flexWrap={'wrap'}>
                {candidateDetails?.Primary_Skill_Set?.map((skill) => (
                  <Box
                    component={'span'}
                    sx={(theme) => ({
                      background: theme.palette.highlight.neutral,
                      padding: '0.25rem 0.5rem',
                      borderRadius: '4px',
                      color: 'text.primar',
                      fontSize: '0.813rem',
                    })}
                    key={skill}
                  >
                    {skill}
                  </Box>
                ))}
              </Stack>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ ml: '0.5rem', mt: '0.25rem' }}
              >
                {candidateDetails?.Secondary_Skill_Set?.join(', ') || '—'}
              </Typography>
            </Stack>
          }
        />
        <RowLayout
          label="Test task"
          isEmpty={!testTaskData}
          Component={
            <Typography variant="body1">
              {testTaskData ? (
                <React.Fragment>
                  Status:{' '}
                  <Box component={'span'} color={testTaskData.color}>
                    {testTaskData.testStatus || '—'}
                  </Box>
                  {' ∙ '}
                  Technology: {testTaskData.testTechnology || '—'}
                  {' ∙ '}
                  {!isNil(testTaskData.testScore) && (
                    <React.Fragment>
                      Score:{' '}
                      <Box component={'span'} color={testTaskData.color}>
                        {testTaskData.testScore}%
                      </Box>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                ''
              )}
            </Typography>
          }
        />
        <RowLayout
          label="Experience"
          Component={
            <Typography variant="body1">{yearsOfExperience}</Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Seniority_Level']}
          isEmpty={!candidateDetails?.Seniority_Level}
          Component={
            <Typography variant="body1">
              {candidateDetails?.Seniority_Level}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['TI_Suggested_Seniority']}
          isEmpty={!candidateDetails?.TI_Suggested_Seniority}
          Component={
            <Typography variant="body1">
              {candidateDetails?.TI_Suggested_Seniority}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Hourly_Rate_Current']}
          isEmpty={!isNumber(candidateDetails?.Hourly_Rate_Current)}
          Component={
            <Typography variant="body1">
              {isNumber(candidateDetails?.Hourly_Rate_Current) ? (
                <>
                  <Box component={'span'} color="text.secondary">
                    $
                  </Box>{' '}
                  {candidateDetails?.Hourly_Rate_Current}{' '}
                  <Box component={'span'} color="text.secondary">
                    hourly
                  </Box>
                </>
              ) : (
                ''
              )}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Hourly_Rate_Expected']}
          isEmpty={!isNumber(candidateDetails?.Hourly_Rate_Expected)}
          Component={
            <Typography variant="body1">
              {isNumber(candidateDetails?.Hourly_Rate_Expected) ? (
                <>
                  <Box component={'span'} color="text.secondary">
                    $
                  </Box>{' '}
                  {compensation}{' '}
                  <Box component={'span'} color="text.secondary">
                    {compensationType}
                  </Box>
                </>
              ) : (
                ''
              )}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Compensation_Expected']}
          isEmpty={!isNumber(candidateDetails?.Compensation_Expected)}
          Component={
            <Typography variant="body1">
              <Box component={'span'} color="text.secondary">
                $
              </Box>{' '}
              {candidateDetails?.Compensation_Expected}{' '}
              <Box component={'span'} color="text.secondary">
                monthly
              </Box>
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Do_not_touch_Russia']}
          Component={
            <Typography variant="body1">
              {candidateDetails?.Do_not_touch_Russia ? 'Yes' : 'No'}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Notice_period_from_Offer']}
          isEmpty={!candidateDetails?.Notice_period_from_Offer}
          Component={
            <Typography variant="body1">
              {capitalize(candidateDetails?.Notice_period_from_Offer || '—')}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Confirmed_Crucial_Requirements']}
          isEmpty={isEmpty(candidateDetails?.Confirmed_Crucial_Requirements)}
          Component={
            <Stack direction="row" gap={1} flexWrap={'wrap'}>
              {candidateDetails?.Confirmed_Crucial_Requirements?.map((ccr) => (
                <Box
                  component={'span'}
                  sx={(theme) => ({
                    background: theme.palette.highlight.neutral,
                    padding: '0.25rem 0.5rem',
                    borderRadius: '4px',
                    color: 'text.primar',
                    fontSize: '0.813rem',
                  })}
                  key={ccr}
                >
                  {ccr}
                </Box>
              ))}
            </Stack>
          }
        />
        <RowLayout
          label="Resume"
          Component={
            <>
              <SvgIcon
                component={AttachFileIcon}
                color="action"
                sx={{ ml: -1 }}
                fontSize="small"
              />
              <Link
                href={candidateDetails?.LP_CV_link ?? '#'}
                color="text.link"
                target="_blank"
                sx={{ cursor: 'pointer' }}
              >
                {candidateDetails?.CV_File_Name}
              </Link>
            </>
          }
        />
        <RowLayout
          label="AE Formatted CV Link"
          Component={
            <Box
              display="flex"
              gap={2}
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              {!!candidateDetails?.AE_Formatted_CV_Link && (
                <Box display="flex" gap={1} alignItems="center">
                  <SvgIcon
                    component={AttachFileIcon}
                    color="action"
                    sx={{ ml: -1 }}
                    fontSize="small"
                  />
                  <Link
                    href={candidateDetails?.AE_Formatted_CV_Link ?? '#'}
                    color="text.link"
                    target="_blank"
                    sx={{ cursor: 'pointer' }}
                  >
                    {candidateDetails?.AE_Formatted_CV_Link}
                  </Link>
                </Box>
              )}
              <Box>
                <AEResumeGenerate />
              </Box>
            </Box>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['LinkedIn_URL']}
          isEmpty={!candidateDetails?.LinkedIn_URL}
          Component={
            <>
              {candidateDetails?.LinkedIn_URL ? (
                <Link
                  href={candidateDetails?.LinkedIn_URL ?? '#'}
                  component={'a'}
                  color="text.link"
                  sx={{ cursor: 'pointer' }}
                  fontFamily="inherit"
                  target="_blank"
                >
                  {candidateDetails?.LinkedIn_URL}
                </Link>
              ) : (
                ''
              )}
            </>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Phone']}
          isEmpty={!candidateDetails?.Phone}
          Component={
            <Typography variant="body1">
              {capitalize(candidateDetails?.Phone || '—')}
            </Typography>
          }
        />
        <RowLayout
          label={CandidateFieldsLabels['Telegram']}
          isEmpty={!candidateDetails?.Telegram}
          Component={
            <Typography variant="body1">
              {capitalize(candidateDetails?.Telegram || '—')}
            </Typography>
          }
        />
      </Stack>
    </Stack>
  );
};
