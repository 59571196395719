import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { notesStoreKey } from './notes.const';
import { SnackbarType, apiEndpoints } from '@constants';
import { setSnackbar } from '@redux/snackbar';
import {
  INote,
  CreateNotePayload,
  UpdateNotePayload,
  DeleteNotePayload,
} from '@types';

export const createNote = createAsyncThunk(
  `${notesStoreKey}/createNote`,
  async ({ payload }: CreateNotePayload, { rejectWithValue }) => {
    try {
      const response = await axios.post<INote>(
        apiEndpoints.createNotesAPIPath(),
        payload,
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const updateNote = createAsyncThunk(
  `${notesStoreKey}/updateNote`,
  async (
    { noteId, payload }: UpdateNotePayload,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await axios.put<INote>(
        apiEndpoints.updateNotesAPIPath(noteId),
        payload,
      );

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Note updated    `,
        }),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteNote = createAsyncThunk(
  `${notesStoreKey}/deleteNote`,
  async ({ noteId }: DeleteNotePayload, { rejectWithValue, dispatch }) => {
    try {
      await axios.delete<INote>(apiEndpoints.deleteNoteAPIPath(noteId));

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Note deleted    `,
        }),
      );
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
