import { useState } from 'react';
import { Flex } from '@components';
import { ProvidedFeedbackState } from './components/ProvidedFeedbackState';
import { VideoInterviewReview } from './components/VideoInterviewReview';
import { CandidateVideoInterview, ReviewVideoInterviewPayload } from '@types';
import { Typography } from '@mui/material';
import { UserVideoInterviewStatus } from '@constants';

export const VideoInterviewFeedback: React.FC<{
  candidateFirstName: string;
  isPerformingVideoInterviewAction: boolean;
  videoInterviewData: CandidateVideoInterview | null;
  postVideoInterviewReview: (data: ReviewVideoInterviewPayload) => void;
}> = ({
  candidateFirstName,
  isPerformingVideoInterviewAction,
  videoInterviewData,
  postVideoInterviewReview,
}) => {
  const [isEditingFeedback, setIsEditingFeedback] = useState(false);

  const shouldShowFeedbackForm =
    !videoInterviewData?.reviewer || isEditingFeedback;
  const hasCompletedInterview =
    videoInterviewData?.status === UserVideoInterviewStatus.FINISHED;

  if (!hasCompletedInterview) {
    return null;
  }

  const handleSubmitFeedback = (data: ReviewVideoInterviewPayload) => {
    postVideoInterviewReview(data);
    setIsEditingFeedback(false);
  };

  const handleEditFeedback = () => setIsEditingFeedback(true);
  const handleCancelEdit = () => setIsEditingFeedback(false);

  return (
    <Flex flexDirection="column" alignItems="start" gap={2} width="100%">
      <Typography variant="h5">Feedback</Typography>

      {!shouldShowFeedbackForm ? (
        <ProvidedFeedbackState
          videoInterviewData={videoInterviewData}
          onEditFeedbackClick={handleEditFeedback}
        />
      ) : (
        <VideoInterviewReview
          candidateFirstName={candidateFirstName}
          videoInterviewData={videoInterviewData}
          isLoading={isPerformingVideoInterviewAction}
          handleSubmit={handleSubmitFeedback}
          closeEditFeedback={handleCancelEdit}
          isShowFeedbackWarning={!isEditingFeedback}
        />
      )}
    </Flex>
  );
};
