import { useEffect, useState } from 'react';
import { keyBy } from 'lodash';

import { Box, Button, Typography } from '@mui/material';
import { Flex } from '@components';
import ReactPlayer from 'react-player';
import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { CandidateVideoInterview, IJobOpeningVideoInterview } from '@types';
import { apiEndpoints, UserVideoInterviewStatus } from '@constants';
import { STATIC_FILES_BASE_ENDPOINT } from 'constants/apiEndpoints';
import { VideoInterviewSendForm } from './VideoInterviewSendForm/VideoInterviewSendForm';
import { VideoInterviewShareWithClient } from './VideoInterviewShareWithClient';

export const VideoInterviewQuestions: React.FC<{
  videoInterviewData: CandidateVideoInterview | null;
  availableVideoInterview: IJobOpeningVideoInterview[];
  isLoadingVIShareWithClient: boolean;
  generateVIShareClientLink: () => void;
}> = ({
  videoInterviewData,
  availableVideoInterview,
  isLoadingVIShareWithClient,
  generateVIShareClientLink,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);
  const [showVIQuestions, setShowVIQuestions] = useState<boolean>(false);

  const questions = videoInterviewData?.videoInterview?.questions;

  const recordByQuestionNumber = keyBy(
    videoInterviewData?.records,
    'questionNumber',
  );

  const selectedRecordUrl = selectedQuestion
    ? recordByQuestionNumber[selectedQuestion]?.fileName || null
    : null;

  useEffect(() => {
    if (videoInterviewData?.status === UserVideoInterviewStatus.FINISHED) {
      setShowVIQuestions(true);
    }
  }, [showVIQuestions]);

  const isFinished =
    videoInterviewData?.status === UserVideoInterviewStatus.FINISHED;

  useEffect(() => {
    if (questions?.length && isFinished) {
      setSelectedQuestion(questions[0].questionNumber);
    }
  }, []);

  return (
    <Flex flexDirection="column" gap={2} alignItems="start" width="100%">
      <Typography variant="h5">Candidate video intro</Typography>
      <Box
        sx={{
          borderRadius: '6px',
          width: '100%',
          minHeight: '220px',
          background: selectedRecordUrl
            ? `linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)), url(${STATIC_FILES_BASE_ENDPOINT}/projects-images/static-noise.gif)`
            : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${STATIC_FILES_BASE_ENDPOINT}/projects-images/static-noise.gif)`,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& .react-player': {
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}
      >
        {selectedRecordUrl ? (
          <ReactPlayer
            controls
            playing
            className="react-player"
            width="100%"
            height="100%"
            url={
              selectedRecordUrl
                ? apiEndpoints.videoInterviewFileUrl(selectedRecordUrl)
                : undefined
            }
          />
        ) : (
          <Box
            sx={{
              background: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '6px',
              padding: '8px 16px',
            }}
          >
            <Typography variant="body2" color="text.invertedSecondary">
              {!videoInterviewData ||
              videoInterviewData.status === UserVideoInterviewStatus.TODO
                ? 'Candidate hasn’t done VI yet'
                : videoInterviewData && !selectedQuestion
                ? 'Please select a question to play'
                : "Candidate didn't answer this question"}
            </Typography>
          </Box>
        )}
      </Box>

      {!isFinished && (
        <VideoInterviewSendForm
          availableVideoInterview={availableVideoInterview}
          currentVideoInterview={videoInterviewData}
        />
      )}

      {videoInterviewData && !isFinished && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            showVIQuestions ? (
              <PhotoSizeSelectSmallOutlinedIcon fontSize="small" />
            ) : (
              <FormatListBulletedIcon />
            )
          }
          onClick={() => setShowVIQuestions(!showVIQuestions)}
          disabled={!questions}
        >
          {showVIQuestions ? 'Hide' : 'View'} questions
        </Button>
      )}

      {questions?.length && showVIQuestions && (
        <Flex gap={1} flexDirection="column" width="100%">
          {questions.map((question) => {
            const hasRecord = !!recordByQuestionNumber[question.questionNumber];

            return (
              <Box
                key={question.questionNumber}
                onClick={() => setSelectedQuestion(question.questionNumber)}
                sx={(theme) => ({
                  width: '100%',
                  padding: '0.5rem 1rem 1rem 1rem',
                  cursor: 'pointer',
                  borderRadius: '0.25rem',
                  background:
                    selectedQuestion === question.questionNumber
                      ? theme.palette.highlight.accent
                      : theme.palette.highlight.actionable,
                })}
              >
                <Typography variant="body2" color="text.secondary">
                  Question {question.questionNumber}
                  {hasRecord ? (
                    ''
                  ) : (
                    <Typography component="span" color="text.danger">
                      {' '}
                      (not recorded)
                    </Typography>
                  )}
                </Typography>
                <Typography variant="body1">{question.title}</Typography>
              </Box>
            );
          })}
        </Flex>
      )}
      {isFinished && (
        <VideoInterviewSendForm
          availableVideoInterview={availableVideoInterview}
          currentVideoInterview={videoInterviewData}
        />
      )}

      <VideoInterviewShareWithClient
        isLoading={isLoadingVIShareWithClient}
        currentVideoInterview={videoInterviewData}
        handleGenerateClick={generateVIShareClientLink}
      />
    </Flex>
  );
};
