import React, { useCallback } from 'react';

import { Typography } from '@mui/material';
import {
  CandidateAddedByFilter,
  CandidateLocationFilter,
  CandidatePayRateSliderFilter,
  CandidateRecruiterFilter,
  CandidateSeniorityFilter,
  CandidateSkillsFilter,
  CandidateStackFilter,
  CandidateStageFilter,
  CandidateStateFilter,
} from '@components';

import { CandidateStage, CandidateState } from '@constants';
import { ICandidatesOptions } from '@types';

export const CandidatesListFilters: React.FC<{
  isLoading: boolean;
  value: Partial<ICandidatesOptions>;
  handleChange: (payload: Partial<ICandidatesOptions>) => void;
  stage?: CandidateStage;
}> = ({ stage, isLoading, value, handleChange }) => {
  const onStateFilterChange = useCallback(
    (value: CandidateState | null) => handleChange({ state: value }),
    [],
  );
  const onLocationFilterChange = useCallback(
    (value: string[] | null) => handleChange({ country: value }),
    [],
  );
  const onSeniorityFilterChange = useCallback(
    (value: string[] | null) => handleChange({ seniority: value }),
    [],
  );
  const onStackFilterChange = useCallback(
    (value: string[] | null) => handleChange({ techFlow: value }),
    [],
  );
  const onPayRateFilterChange = useCallback(
    (value: number[] | null) => handleChange({ payRate: value }),
    [],
  );
  const onStageFilterChange = useCallback(
    (value: CandidateStage | null) => handleChange({ stage: value }),
    [],
  );
  const onRecruiterFilterChange = useCallback(
    (value: string[] | null) => handleChange({ recruiter: value }),
    [],
  );
  const onAddedByFilterChange = useCallback(
    (value: string[] | null) => handleChange({ addedBy: value }),
    [],
  );
  const onSkillFilterChange = useCallback(
    (value: string[] | null) => handleChange({ skill: value }),
    [],
  );

  return (
    <>
      <Typography variant="subtitle1" mt="2.5rem" mb="1rem">
        Profile
      </Typography>
      <CandidateStateFilter
        isLoading={isLoading}
        value={value.state!}
        onChange={onStateFilterChange}
      />
      <CandidateLocationFilter
        isLoading={isLoading}
        value={value.country!}
        onChange={onLocationFilterChange}
      />
      <CandidateSeniorityFilter
        isLoading={isLoading}
        value={value.seniority!}
        onChange={onSeniorityFilterChange}
      />
      <CandidateStackFilter
        isLoading={isLoading}
        value={value.techFlow!}
        onChange={onStackFilterChange}
      />
      <CandidateSkillsFilter
        isLoading={isLoading}
        value={value.skill!}
        onChange={onSkillFilterChange}
      />
      <Typography
        id="pay-rate-slider"
        fontSize="0.75rem"
        color="text.secondary"
      >
        Pay rate
      </Typography>
      <CandidatePayRateSliderFilter
        isLoading={isLoading}
        value={value.payRate!}
        onChange={onPayRateFilterChange}
      />
      <Typography variant="subtitle1" my="1rem">
        Application
      </Typography>
      <CandidateStageFilter
        disabled={!!stage}
        isLoading={isLoading}
        value={value.stage!}
        onChange={onStageFilterChange}
      />
      <CandidateRecruiterFilter
        isLoading={isLoading}
        value={value.recruiter!}
        onChange={onRecruiterFilterChange}
      />
      <CandidateAddedByFilter
        isLoading={isLoading}
        value={value.addedBy!}
        onChange={onAddedByFilterChange}
      />
    </>
  );
};
